import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/facturas';
import {api } from '../../actions/_request';
import swal from 'sweetalert2'
import ReactTable from "react-table";
import "react-table/react-table.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent, TabPane, Nav, NavItem, NavLink, CardText,Label

  
} from "reactstrap";
import classnames from 'classnames';
import Select from 'react-select';
import ModalTablas from '../../components/catalogos/modal_tablas';
import ModalProductos from './modalproductos';
import moment from 'moment';


const filterCaseInsensitive = (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (row[id] !== null){
                return (
                    row[id] !== undefined ?
                        String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase())
                        :
                        true
                );
            }
        };
  const monedas = [
    {label: 'MXN', value: 'MXN'},
    {label: 'USD', value:'USD'},
];
const documentos = [
    {label: 'Factura', value: 1},
    {label: 'Remision', value: 2},
];

const impuestos = [
    {label: 'Almacen', value: 1},
    {label: 'Cliente', value: 2},
    {label: 'Producto', value: 3},
];



  
class Proveedor extends Component {
  constructor(props){
    super(props);
    this.state = {
      articulo:{
        
       tipo_documento:1,
       folio:'',
       moneda:'MXN',
       tc:1,
       almacen_id:1,
       
       nota:'',
       cliente_id:0,
       almacen_id:0,
       metodo:'PUE',
       forma:'01',
       uso:'G03',
       importe:0,
       descuentos:0,
       subtotal:0,
       impuestos:0,
       retenciones:0,
       total:0,
       tipo_impuestos:3,
       importada:1,
       timbrado:1
      },
      cliente:[],
      productos:[],
      action : 'save',
      submit:false,
      activeTab: '1',
      modal:false,
      formas : [],
      metodos : [],
      usos : [],
      modal_productos:false,
      almacen:[],
      impuestos_productos:{
        iva:0,
        ieps:0,
        retencion_iva:0,
        retencion_isr:0,
      }

      
    }
     this.handleSubmit = this.handleSubmit.bind(this);
     this.handeInputChange = this.handeInputChange.bind(this);
  }

  handleSelectImpuestosChange=(select, name)=> {
        const value = select === null ? null : select.value;
        console.log('select--->',select)
        let {articulo,impuestos_productos,cliente,almacen} = this.state;
        console.log('handleSelectImpuestosChange')
        if(parseInt(value)==1){

          /*tomamos el iva del inventario  checamos si  ya se asigno  un almacen*/
          if(parseInt(articulo.almacen_id)){
            impuestos_productos.iva=almacen.iva;
            impuestos_productos.ieps='';
            impuestos_productos.retencion_isr='';
            impuestos_productos.retencion_iva='';
          }
        }else if (parseInt(value)==2) {
          /*tomamos los impuestos del cliente*/
          if(parseInt(articulo.cliente_id)){
            impuestos_productos.iva=cliente.iva;
            impuestos_productos.ieps=cliente.ieps;
            impuestos_productos.retencion_isr=cliente.retencion_isr;
            impuestos_productos.retencion_iva=cliente.retencion_iva;
          }
        }



        console.log('impuestos_productos--->',impuestos_productos);
        this.setState({
            articulo: {
                ...this.state.articulo,
                [name]: value
            },
            impuestos_productos:impuestos_productos,
        });
    }

  retornoProducto=(obj)=>{
       let {productos} = this.state;
       var indiceproducto= this.state.indiceproducto;
       console.log('retorno producto---->',obj);
       let bandera=true;



       // console.log('indice--->',indiceproducto)
       if(indiceproducto==null){
        for (var i = productos.length - 1; i >= 0; i--) {
        if(obj.articulo_id==productos[i].articulo_id)
            {
             bandera=false;
             swal.fire("Oops..", "Producto ya ingresado");
             break;
            }
       }

       if(bandera)
        productos.push(obj);
      else
        return


       }else{
        productos[indiceproducto]=obj;
       }

       this.setState({
            modal_productos : !this.state.modal_productos,       
            indiceproducto : null,  
            productos:productos,
        });
       this.calculos();


  }
  toogle_productos=(evt,indice=null)=>{  
    let {articulo}=this.state;
    if(parseInt(articulo.cliente_id)>0){
      if(parseInt(articulo.almacen_id)>0){
          this.setState({
              modal_productos : !this.state.modal_productos,       
              indiceproducto : indice,  
          });
      }else{
        swal.fire("Oops..", "Selecciona un Almacen");
      }
    }else{
        swal.fire("Oops..", "Selecciona un Cliente");
      }    
  }

  handleSelectChange=(select, name)=> {
        const value = select === null ? null : select.value;
        console.log('select--->',select)
        this.setState({
            articulo: {
                ...this.state.articulo,
                [name]: value
            }
        });
    }

  ChangeCode=(event)=> {
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
        this.setState({
            [name] : value,
        });
    }

  handleClose =(event,tabla,campo)=> {

        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
        let self =this;
        if(value!=""){
            api().get(`${tabla}/${value}`)
            .then(function(response)
            {                  
                if(response.status === 200)
                { 
                    let vaciar=0;
                     if(Object.keys(response.data).length > 0){
                       
                       
                     }else{
                         vaciar=1
                         swal.fire("Oops..", "No Existe el Codigo");
                     }
                     self.retorno(response.data,campo,vaciar,value);        
                   
                }

            });
        }else{
            self.retorno([],campo,1,value); 
        }
   }

  retorno=(objeto,campo,vacio=0,valor="")=>{
        let label="";
        let codigo="";
        let id="";
        let {articulo,cliente,almacen,impuestos_productos} = this.state;
        let self =this;
        console.log('retorno--->',objeto);

        if(campo=='cliente_id'){
            if(vacio==0){
              id=objeto.id;
              cliente=objeto;
              if(parseInt(articulo.tipo_impuestos)==2){
                impuestos_productos.iva=cliente.iva;
                impuestos_productos.ieps=cliente.ieps;
                impuestos_productos.retencion_isr=cliente.retencion_isr;
                impuestos_productos.retencion_iva=cliente.retencion_iva;
              }
            }else{
              cliente=[];
              id='';
            }

              /*verificamos que el tipo de moneda sea diferente al que tenemos actualmente para mostrar el mensaje
               de que se eliminaran los productos por que se cambiara la moneda del documento*/
               if(this.state.productos.length>0){

                if(articulo.moneda!=objeto.factura_moneda){
                    swal.queue([{
                      title: 'Favor de Confirmar ',
                      text: " Se Detecto un cambio de moneda en el documento esto hara que se eliminen los productos desea continuar:",
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Confirmar!',
                      cancelButtonText: 'Cancelar!',
                      showLoaderOnConfirm: true,
                      preConfirm: () => {
                       
                             self.setState({
                          articulo: {
                              ...this.state.articulo,
                              [campo]: id,
                              moneda:objeto.factura_moneda,
                              forma:objeto.factura_forma,
                              uso:objeto.factura_uso,
                              metodo:objeto.factura_metodo,
                              facturar_a:objeto.razon_social,
                              rfc:objeto.rfc,
                              registro_fiscal:objeto.factura_registro_fiscal,
                              recidencia_fiscal:objeto.factura_pais,
                              direccion:objeto.domicilio,
                              ciudad:objeto.ciudad,
                          },         
                          productos:[],
                          cliente:cliente,
                          [campo]: objeto.codigo,
                          modal:false,
                          impuestos_productos:impuestos_productos        
                      });
                      this.calculos();

                      }
                    }])
                }else{
                    self.setState({
                          articulo: {
                              ...this.state.articulo,
                              [campo]: id,
                              moneda:objeto.factura_moneda,
                              forma:objeto.factura_forma,
                              uso:objeto.factura_uso,
                              metodo:objeto.factura_metodo,
                              facturar_a:objeto.razon_social,
                              rfc:objeto.rfc,
                              registro_fiscal:objeto.factura_registro_fiscal,
                              recidencia_fiscal:objeto.factura_pais,
                              direccion:objeto.domicilio,
                              ciudad:objeto.ciudad,

                          },         
                          cliente:cliente,
                         [campo]: objeto.codigo,
                          modal:false,  
                           impuestos_productos:impuestos_productos        
                      });
                    }


               }else{
                  self.setState({
                      articulo: {
                          ...this.state.articulo,
                          [campo]: id,
                          moneda:objeto.factura_moneda,
                          forma:objeto.factura_forma,
                          uso:objeto.factura_uso,
                          metodo:objeto.factura_metodo,
                          facturar_a:objeto.razon_social,
                              rfc:objeto.rfc,
                              registro_fiscal:objeto.factura_registro_fiscal,
                              recidencia_fiscal:objeto.factura_pais,
                              direccion:objeto.domicilio,
                              ciudad:objeto.ciudad,
                      },         
                      cliente:cliente,
                      [campo]: objeto.codigo,
                      modal:false,  
                       impuestos_productos:impuestos_productos        
                  });
               }
        }else{
           if(vacio==0){
              label=objeto.label;
              codigo=objeto.codigo;
              id=objeto.id;
            
              if(campo=='almacen_id'){
                almacen=objeto;
                // impuestos_productos
                if(parseInt(articulo.tipo_impuestos)==1){
                  impuestos_productos.iva=almacen.iva;
                  impuestos_productos.ieps='';
                  impuestos_productos.retencion_isr='';
                  impuestos_productos.retencion_iva='';
                }

              }
            }else{
              if(campo=='almacen_id'){
                almacen={};

              }
            }

            self.setState({
                articulo: {
                    ...this.state.articulo,
                    [campo]: id
                },         
                ['label_'+campo]:label,
                ['codigo_'+campo]:codigo,
                modal:false,   
                almacen:almacen,      
            });
        }
  }

  toggle_modal=(index=0,catalogo="",campo="",tipo=0,nivel=0)=>{  
    // console.log('algooooo')
        this.setState({
            modal : !this.state.modal,
            titulo:catalogo,
            tabla_modal:index,
            campo_modal:campo,
            tipo_modal:tipo,
            nivel_modal:nivel,         
        })
  } 

  componentDidMount(){
    let _self = this;
    let {articulo,productos} = this.state;
    let {params} = this.props.match;
    let self = this;

    let folio_id=this.props.auth.user.data_folio.id;
    api().get(`/formulario_factura/${folio_id}`)
        .then(function(response)
        {
            self.setState({
                metodos : response.data.metodos,
                formas : response.data.formas,
                usos : response.data.usos,
                 articulo: {
                ...self.state.articulo,
                tc: response.data.cambio,
                folio_id:folio_id,
            },
            codigo_folio_id:response.data.folio.folio,
                
            });
           
        })
    if(params.id) {
      api().get("/getfactura/"+params.id)
      .then(function(response){
        if(response.status === 200){
          articulo = response.data.factura;
          productos = response.data.productos;
          // var d = new Date(articulo.fecha_timbrado);
          // console.log('date-->',d)
          let nueva_fecha= moment(articulo.fecha_timbrado).format('Y-MM-DD');


          articulo.fecha_timbrado=nueva_fecha;

          _self.setState({
            articulo : articulo,
           
            cliente_id:response.data.cliente.codigo,
            productos:productos,
            cliente:response.data.cliente,
         
            
            action : 'update'
          })
         

        }

      });
    }

  }


  impuestos_inicio=()=> {
        
        let {articulo,impuestos_productos,cliente,almacen} = this.state;

        if(parseInt(articulo.tipo_impuestos)==1){

          /*tomamos el iva del inventario  checamos si  ya se asigno  un almacen*/
          if(parseInt(articulo.almacen_id)){
            impuestos_productos.iva=almacen.iva;
            impuestos_productos.ieps='';
            impuestos_productos.retencion_isr='';
            impuestos_productos.retencion_iva='';
          }
        }else if(parseInt(articulo.tipo_impuestos)==2) {
          /*tomamos los impuestos del cliente*/
          if(parseInt(articulo.cliente_id)){
            impuestos_productos.iva=cliente.iva;
            impuestos_productos.ieps=cliente.ieps;
            impuestos_productos.retencion_isr=cliente.retencion_isr;
            impuestos_productos.retencion_iva=cliente.retencion_iva;
          }
        }



        console.log('impuestos_productos--->',impuestos_productos);
        this.setState({
           
            impuestos_productos:impuestos_productos,
        });
    }


  handeInputChange(event){
    let {articulo} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    articulo[name] = value;
// console.log('articulo handeInputChange---->',articulo)
    this.setState({
        articulo : articulo
    });
  }

  handleSubmit(evt){
    evt.preventDefault();
    let _self = this;
    let {articulo,productos,action} = this.state;
    let save = true;

    articulo.productos=productos;
    _self.setState({
            submit : true
          })

    if(articulo.uso.length==0){
      swal.fire({
                type: 'error',
                title: 'Agregar un Uso del CFDI',
              })
      _self.setState({
            submit : false
          });
      return
    }
    if(articulo.forma.length==0){
      swal.fire({
                type: 'error',
                title: 'Agregar una Forma de pago',
              })
      _self.setState({
            submit : false
                      });return
    }
    if(articulo.metodo.length==0){
      swal.fire({
                type: 'error',
                title: 'Agregar un metodo de pago',
              })
      _self.setState({
            submit : false
          });return
    }

    api().post(`/save_update_facturas_importadas`, articulo)
            .then(function(response)
            {  
                if(response.status){
                  if(response.data.estado){
                    // console.log('actualizar')
                    swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success").then((result) => {
                        _self.props.history.push('/app/facturas');
                        });
                     _self.setState({
                      articulo : response.data.factura,
                      action : 'update',
                      submit : false
                    })
                    

                  }else{
                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    _self.setState({
                        submit : false
                      });
                  }
                }else{
                  console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
                }
              });



  }

  regresar=(evt)=>
  {
    evt.preventDefault();
    this.props.history.push('/app/facturas');
  }
   delete = (evt,index)=>{
      let {productos} = this.state;   
      
      let submit=true;
        /*peticion para agregar*/
        let self = this;

         swal.queue([{
        title: 'Favor de Confirmar ',
        text: " Se eliminar El producto: ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
                productos.splice(index,1);
        self.setState({
          
           productos:productos,
          });

        this.calculos();
        }
      }])  
    }

    calculos_inicio=()=>{
      let {productos} = this.state;


      for (var i = productos.length - 1; i >= 0; i--) {
       let articulo= productos[i];

         /*hacemos los calculos de los impuestos*/
      articulo.importe=parseFloat(articulo.cantidad)*parseFloat(articulo.precio);
      articulo.descuento_monto=(parseFloat(articulo.descuento)/100)*articulo.importe;
      articulo.subtotal=articulo.importe-articulo.descuento_monto;
      /*varificamos  que tipo de ieps es (tasa o cuota)
           tasa es porcentaje en base al subtotal
           cuota = (cantidad_fiscal*ieps)*cantidad
      */

      let ieps_calculo=articulo.ieps!=null?articulo.ieps:0;
      if(articulo.cuota_fija_ieps==1){
        articulo.ieps_monto=( parseFloat(articulo.cantidad_fiscal)*parseFloat(ieps_calculo) )*parseFloat(articulo.cantidad);
      }else{
        articulo.ieps_monto=(parseFloat(ieps_calculo)/100)*articulo.subtotal;
      }
      articulo.iva_monto=0;
      /*validamos si el articulo esta exento de iva*/
      let iva_calculo=articulo.iva!=null?articulo.iva:0;

      if(articulo.excento_iva==0)
        articulo.iva_monto=(parseFloat(iva_calculo)/100)*articulo.subtotal;
      articulo.impuesto_monto=0;
      /*validamos que el impuesto 3 sea cuota o tasa*/
      // if(articulo.cuota_fija_otro==1){
      //   articulo.impuesto_monto=( parseFloat(articulo.cantidad_fiscal)*parseFloat(articulo.impuesto_otro) )*parseFloat(articulo.cantidad);
      // }else{
      //   articulo.impuesto_monto=(parseFloat(articulo.impuesto_otro)/100)*articulo.subtotal;
      // }
      let retencion_isr_calculo=articulo.retencion_isr!=null?articulo.retencion_isr:0;
      let retencion_iva_calculo=articulo.retencion_iva!=null?articulo.retencion_iva:0;
      articulo.retencion_isr=articulo.retencion_isr!=null?articulo.retencion_isr:'';
      articulo.retencion_iva=articulo.retencion_iva!=null?articulo.retencion_iva:'';
      articulo.iva=articulo.iva!=null?articulo.iva:'';
      articulo.ieps=articulo.ieps!=null?articulo.ieps:'';

      articulo.retencion_isr_monto=(parseFloat(retencion_isr_calculo)/100)*articulo.subtotal;
      articulo.retencion_iva_monto=(parseFloat(retencion_iva_calculo)/100)*articulo.subtotal;
      articulo.retenciones=articulo.retencion_isr_monto+articulo.retencion_iva_monto;
      articulo.total=articulo.subtotal+articulo.ieps_monto+articulo.iva_monto+articulo.impuesto_monto-articulo.retenciones;

      console.log('articulo--->',articulo);
      console.log('importe--->',articulo.importe);
      console.log('descuento_monto--->',articulo.descuento_monto);
      console.log('subtotal--->',articulo.subtotal);
      console.log('iva_monto--->',articulo.iva_monto);
      console.log('ieps_monto--->',articulo.ieps_monto);
      console.log('impuesto_monto--->',articulo.impuesto_monto);
      console.log('retencion_iva_monto--->',articulo.retencion_iva_monto);
      console.log('retencion_isr_monto--->',articulo.retencion_isr_monto);
      console.log('total--->',articulo.total);

      productos[i]=articulo;

      }

      this.setState({
        productos : productos
      });
    }

    calculos = ()=>{
      let {productos,articulo} = this.state;   
      articulo.total=0;
      articulo.subtotal=0;
      articulo.importe =0;
      articulo.descuentos =0;
      articulo.impuestos =0;
      articulo.retenciones=0;
      
      for (var i = 0; i < productos.length; i++) {
        // console.log('descuento_monto producto-->',parseFloat( productos[i].descuento_monto))
        articulo.importe     += parseFloat( productos[i].importe);
        articulo.descuentos   += parseFloat( productos[i].descuento_monto);
        articulo.subtotal    += parseFloat( productos[i].subtotal);
        articulo.impuestos   += parseFloat( productos[i].iva_monto)+parseFloat( productos[i].ieps_monto)+parseFloat( productos[i].impuesto_monto);
        articulo.retenciones += parseFloat( productos[i].retencion_iva_monto)+parseFloat( productos[i].retencion_isr_monto);
        articulo.total       += parseFloat( productos[i].total);
      }


      this.setState({
        articulo : articulo
      });
      console.log('factura calculos---->',articulo)

        
          
    }

  render() {
     let {submit,articulo,cliente} = this.state;
     console.log('factura---->',this.state)
  
    return (
      
        <div className="content">
          <Row>
            <Col md="12">
            <Form onSubmit={this.handleSubmit}>
              <Card className="card-user">
                <CardHeader>
                 <Row>

                    <Col md='7'></Col>
                      <Col md="5" className="update  text-right ">
                        
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          disabled={submit}
                        >
                          Guardar
                        </Button>
                        <Button
                          className="btn-round"
                          color="warning"
                          onClick={this.regresar}>
                          Cancelar
                        </Button>
                      </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                <Row>
                <Col md="12">
                <Row>
                  <div className="form-group col-12 col-md-2">
                    <label  className=" col-form-label">Cliente : </label>
                    <input type="text" className="form-control"   name="cliente_id" required  value={this.state.cliente_id} onChange={this.ChangeCode}  onKeyPress={event => { if (event.key === 'Enter') { event.preventDefault(); this.handleClose(event,"/getclientes/codigo","cliente_id") } }} />
                  </div>
                  <Col sm='2' md="1" className='pt-2'>
                    <Button style={{bottom: '18px','position':'absolute'}}  className="btn btn_catalogos" color="secondary" onClick={ (button) => { this.toggle_modal(24,"Clientes","cliente_id") } }> + </Button>
                  </Col>
                  <div className="col-md-3"> 
                    <div className="form-group">
                      <label className='col-form-label'>Tipo:</label>
                      <Select placeholder="" type="text" name="tipo_documento" options={documentos} value={documentos.filter(option => option.value == articulo.tipo_documento)} onChange={ (select) => { this.handleSelectChange(select, 'tipo_documento') } }  clearable={false} />
                    </div>
                  </div>
                  <div className="col-md-3"> 
                    <div className="form-group">
                      <label className='col-form-label'>Moneda:</label>
                      <Select  placeholder="" type="text"  name="moneda" options={monedas}  value={monedas.filter(option => option.value == articulo.moneda)} isDisabled={true}  onChange={ (select) => { this.handleSelectChange(select, 'moneda') } } clearable={false} />
                    </div>
                  </div>
                  <div className="form-group col-12 col-md-2 ">
                    <label  className=" col-form-label">TC : </label>
                      <input type="number" className="form-control" 
                        name="tc"
                        step={'0.01'}
                        required
                        value={articulo.tc}
                        onChange={this.handeInputChange}
                       />
                  </div>
                  <div className="col-12 div_titulo mt-2">
                            <h1><span>Datos Cliente</span></h1>
                            <div className="row">
                              <div className="col-6">
                                  <label><b>Facturar a:</b> {articulo.facturar_a} </label><br/>
                                  <label><b>Direccion:</b> {articulo.direccion} </label><br/>
                              </div>
                              <div className="col-3">
                                  <label><b>RFC:</b> {articulo.rfc} </label><br/>
                                  <label><b>Ciudad:</b> {articulo.ciudad} </label><br/>
                              </div>
                              <div className="col-3">
                                  <label><b>Residencia F.:</b> {articulo.recidencia_fiscal} </label><br/>
                                  <label><b>Registro de I. Fiscal:</b> {articulo.registro_fiscal} </label><br/>
                              </div>
                              
                            </div>
                  </div>
                  <div className="col-12 div_titulo mt-2">
                            <h1><span>Datos Fiscales</span></h1>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="form-group">
                                    <label>Fecha:</label>
                                    <Input
                                      required
                                      placeholder=""
                                      type="date"
                                      name="fecha_timbrado"
                                      value={articulo.fecha_timbrado}
                                      onChange={this.handeInputChange}
                                    />
                                  </div>
                              </div>

                              <div className="col-md-3"> 
                                <div className="form-group">
                                  <label className>Referencia:</label>
                                  <input
                                      placeholder=""
                                      type="text"
                                      name="folio"
                                      value={articulo.folio}
                                      onChange={this.handeInputChange}
                                      className="form-control"
                                    />
                                </div>
                              </div>
                              
                            <div className="col-md-2"> 
                                <div className="form-group">
                                    <label className>Total de la Factura:</label>
                                    <input
                                      placeholder=""
                                      type="number"
                                      step='0.01'
                                      name="total"
                                      value={articulo.total}
                                      onChange={this.handeInputChange}
                                      className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-md-2"> 
                                <div className="form-group">
                                    <label className>Saldo de la Factura:</label>
                                    <input
                                      placeholder=""
                                      type="number"
                                      step='0.01'
                                      name="saldo"
                                      value={articulo.saldo}
                                      onChange={this.handeInputChange}
                                      className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-md-7"> 
                                <div className="form-group">
                                    <label className>UUID:</label>
                                    <input
                                      placeholder=""
                                      type="text"
                                      name="uuid"
                                      value={articulo.uuid}
                                      onChange={this.handeInputChange}
                                      className="form-control"
                                    />
                                </div>
                            </div>

                            </div>
                            <div className="row">

                            <div className="col-md-3"> 
                                <div className="form-group">
                                    <label className>Forma de Pago:</label>
                                    <Select
                                        placeholder=""
                                        type="text"
                                        name="forma"
                                        options={this.state.formas}
                                        value={this.state.formas.filter(option => option.value == articulo.forma)}
                                       
                                       onChange={ (select) => { this.handleSelectChange(select, 'forma') } }
                                        clearable={false}

                                    />
                                </div>
                            </div>
                            <div className="col-md-3"> 
                                <div className="form-group">
                                    <label className>Metodo de Pago:</label>
                                    <Select
                                        placeholder=""
                                        type="text"
                                        name="metodo"
                                        options={this.state.metodos}
                                        value={this.state.metodos.filter(option => option.value == articulo.metodo)}
                                        
                                        onChange={ (select) => { this.handleSelectChange(select, 'metodo') } }
                                        clearable={false}

                                    />
                                </div>
                            </div>
                        
                        <div className="col-md-5"> 
                                <div className="form-group">
                                    <label className>Uso del CFDI:</label>
                                    <Select
                                        placeholder=""
                                        type="text"
                                        name="uso"
                                        options={this.state.usos}
                                        value={this.state.usos.filter(option => option.value == articulo.uso)}
                                        onChange={ (select) => { this.handleSelectChange(select, 'uso') } }
                                        clearable={false}
                                    />
                                </div>
                            </div>
                            
                              
                            </div>
                  </div>
                  <div className="col-12 div_titulo mt-2">
                            <h1><span>Datos Generales</span></h1>
                            <div className="row">
                            
                          
                           
                            
                            <div className="form-group col-12  ">
                    <label  className=" col-form-label">Nota : </label>
                      <input type="text" className="form-control" 
                        name="nota"
                        required
                        value={articulo.nota}
                        onChange={this.handeInputChange}
                       />
                  </div>
                           
                            
                              
                            </div>
                  </div>
                </Row> 
   
                    


                                
                </Col>
                </Row>
      
                </CardBody>
              </Card>
              </Form>
            </Col>
          </Row>
          {
            this.state.modal&&
            <ModalTablas
                  open={this.state.modal}
                  toggle={this.toggle_modal}                  
                  titulo={this.state.titulo}
                  catalogo={this.state.tabla_modal}
                  retorno={this.retorno}
                  campo={this.state.campo_modal}
                  nivel={this.state.nivel_modal}
                  tipo={this.state.tipo_modal}
                  factura={1}
                  
              />
          }

           { this.state.modal_productos&&
                <ModalProductos
                    open={this.state.modal_productos}
                    toggle={this.toogle_productos}  
                    renglon={this.state.indiceproducto!=null?this.state.productos[this.state.indiceproducto]:[]} 
                    retorno={this.retornoProducto}
                    indice={this.state.indiceproducto}
                    moneda={this.state.articulo.moneda}
                    almacen_id={this.state.articulo.almacen_id}
                    tipo_impuestos={articulo.tipo_impuestos}
                    impuestos={this.state.impuestos_productos}
                    cliente={this.state.cliente}
                    tc={this.state.articulo.tc}
                />
            }
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        auth : state.auth,
        facturas:state.facturas
    }
};

export default connect(mapStateToProps, actions)(Proveedor)

