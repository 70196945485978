import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Label,FormGroup, Input,InputGroup, InputGroupAddon
} from 'reactstrap';
// reactstrap components
import {
  
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
 
  Form,
  
  Row,
  Col,
  CardText
} from "reactstrap";
import classnames from 'classnames';
import _ from "lodash";
import Spinner from 'react-spinkit';

import swal from 'sweetalert2';

import * as actions from '../../actions/categorias';

import Select from 'react-select';

import {api, API_URL} from '../../actions/_request';

import ReactTable from "react-table";
import "react-table/react-table.css";
import Folios_modal from './modal_folios';



const requestData = (pageSize, page, sorted, filtered,tipo,nivel) => {    
    return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        let filteredData ;
        var label="";
        var siglas_facturas="";
        var n_facturas="";
        var folio="";
        var take= pageSize;
        var skip=pageSize * page;
        var total=1;  
        for (var i = filtered.length - 1; i >= 0; i--) {
          switch(filtered[i].id)
          {
            case "label":
              label=filtered[i].value;
              break;
            case "siglas_facturas":
              siglas_facturas=filtered[i].value;
              break;            
            case "folio":
              folio=filtered[i].value;
              break;
            case "n_facturas":
              n_facturas=filtered[i].value;
              break;             
          }
        }
              
              api().get(`/folios/busqueda?folio=${folio}&label=${label}&siglas_facturas=${siglas_facturas}&n_facturas=${n_facturas}&take=${take}&skip=${skip}`)
              .then(function(response)
              {
                if(response.status === 200)
                {
                  filteredData= response.data.datos;
                  total= response.data.total; 
                  // You can also use the sorting in your request, but again, you are responsible for applying it.
                  const sortedData = _.orderBy(
                    filteredData,
                    sorted.map(sort => {
                      return row => {
                        if (row[sort.id] === null || row[sort.id] === undefined) {
                          return -Infinity;
                        }
                        return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                      };
                    }),
                    sorted.map(d => (d.desc ? "desc" : "asc"))
                  );
                  const res = {
                    rows: sortedData,
                    pages: Math.ceil(total / pageSize)
                  };
                  // Here we'll simulate a server response with 500ms of delay.
                  //setTimeout(() => resolve(res), 1000);
                  //console.log(res);
                  resolve(res)
                }
              });   
        
    });
};


class ModalCategorias extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
            tipo:{label: 'Productos', value: 1},
            nivel:{label: '1', value: 1},
            action:'save',
            categoria:{
                tipo:1,
                nivel:1,
                nombre:'',
                id:'',
            },
            submit:false,
            pages: null,
            loading: true,
		
        };
        this.handleSelectChange= this.handleSelectChange.bind(this);
        this.regresar= this.regresar.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.editar= this.editar.bind(this);
        this.handeInputChange= this.handeInputChange.bind(this);
        this.handleSubmit= this.handleSubmit.bind(this);
        this.table = React.createRef();
	}


cambio_estado=(evt, folio, estado )=>
    {
        let {modal_folios} = this.state;

        let titulo=  ` Se Desactivara El Folio: [${folio.folio}] ${folio.nombre}`;
        if(estado==0)
        titulo = `Se Activara El Folio: [${folio.folio}] ${folio.nombre}`;

       let self=this;
      swal.queue([{
        title: titulo,
        text: "Estas seguro ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return api().get(`/folio/estado/${folio.id}/${estado}`)
            .then(function(response)
            {    
                // console.log('response-->',response)    
                if(response.status === 200)
                {
                  if(response.data.estado==200){
                      swal.insertQueueStep({
                        type: 'success',
                        title:  'Se Cambio el estado Correctamente',
                      })
                      self.fetchData(self.table.current.state);
                  }else{
                    swal.insertQueueStep({
                      type: 'warning',
                      title:  response.data.mensaje,
                    })
                  }
                }
                  
            })
            .catch((error) => {
              console.log('error---->',error)
              swal.insertQueueStep({
                type: 'error',
                title: 'Solicitar ayuda a un Administrador',
              })
            })
        }
      }])
    }




  recargar_tabla=()=>{
    this.fetchData(this.table.current.state);
  }

  toggle_modal=(evt, folio_id = null, title = "Agregar Folio")=>
    {
        let {modal_folios} = this.state;

        this.setState({
            modal_folios       : !modal_folios,
            folio_id : folio_id,
            title       : title,
            
        });
    }

    handeInputChange(event){
    let {categoria} = this.state;

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    categoria[name] = value;
// console.log('categoria handeInputChange---->',categoria)
    this.setState({
        categoria : categoria
    });
  }


     fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered,
      this.state.tipo.value,
      this.state.nivel.value,
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
      // console.log('datos--->',res.rows);
       // this.props.get(res.rows);
    });
  }

    regresar() {
             this.setState({
                action:'save',
                categoria: {
                    ...this.state.categoria,
                     nombre:'',
                     id:'',
                }
            }); 
    }

    editar(row){

        console.log('Row---->',row)
        this.setState({
                action:'update',
                categoria: row,
            });

    }


    handleSelectChange(select, name) {

        
        const value = select === null ? null : select.value;
        console.log('value---->',value)
        console.log('name---->',name)
             this.setState({
                [name]: select,
                categoria: {
                    ...this.state.categoria,
                    [name]: value
                }
            }, () => {
           this.fetchData(this.table.current.state)
        });
        
    }

     handleSubmit(evt){
    evt.preventDefault();
    let _self = this;
    let {categoria,action} = this.state;
    let save = true;
    _self.setState({
            submit : true
          })

    this.props.saveupdate(categoria, (response) => {
                if(response.status){
                  if(response.data.estado){
                    // console.log('actualizar')
                    swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success");
                    
                     _self.setState({
                      categoria: {
                            ..._self.state.categoria,
                             nombre:'',
                             id:'',
                        },
                      action : 'save',
                      submit : false
                    }, () => {
                           _self.fetchData(_self.table.current.state)
                        });
                    

                  }else{
                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    _self.setState({
                        submit : false
                      });
                  }
                }else{
                  console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
                }
              });



  }


	render()
	{

        console.log('estate Modal----->',this.state);
        let {submit,categoria,pages, loading}= this.state;
		
		return(
                <div className="content">

              <Row className='justify-content-end '>
                <div className='col-1 mr-2 '>
                <Button color="primary" onClick={(evt)=>this.toggle_modal()}>
                                Nuevo
                            </Button>
                </div>
                </Row>
                <Row>
                
                <div className='col-12'>
                <ReactTable
                             getTrProps={(state, rowInfo, column) => {
                                 
                                  if(rowInfo === undefined){
                                         
                                          return{

                                          }
                                        }else{
                                           return{
                                            onDoubleClick: (e) => {
                                                    this.toggle_modal(e, rowInfo.original.id, 'Editar Folio'); 
                                            }
                                        } 
                                  
                                    }
                              }}
                              
                              
                              className = "-striped -highlight"
                              columns   = {[
                               
                                {Header: 'Folio', accessor: 'folio',maxWidth:150 },
                                {Header: 'Nombre', accessor: 'nombre'},
                                {Header: 'Siglas Facturas', accessor: 'siglas_facturas',maxWidth:150},
                                {Header: '# Facturas', accessor: 'n_facturas',maxWidth:150},
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                    maxWidth:230,

                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-right ">
                                                <Button
                                                 color="success" 
                                                 className="btn-sm mx-2" 
                                                 onClick={(evt)=>this.toggle_modal(evt, row.original.id, 'Editar Folio')}>
                                                   Editar
                                                </Button>
                                                {
                                                 parseInt(row.original.inactivo)==1?<Button
                                                 color="success" 
                                                 className="btn-sm" 
                                                 onClick={(evt)=>this.cambio_estado(evt,row.original, 0)}>
                                                   Activar
                                                </Button>:
                                                <Button
                                                 color="danger" 
                                                 className="btn-sm mx-2" 
                                                 onClick={(evt)=>this.cambio_estado(evt,row.original, 1)}>
                                                   Desactivar 
                                                </Button>
                                                }
                                                
                                                 
                                            </div>
                                        )
                                    }
                                }
                              
                              ]}
                              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                              data      = {this.state.data}
                              pages={pages} // Display the total number of pages
                              loading={loading} // Display the loading overlay when we need it
                              onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                              filterable
                              ref={this.table}
                              defaultPageSize={5} 
                            />
                    
                </div>
                </Row>
                {
                   this.state.modal_folios&& 
                   <Folios_modal 
                   open={this.state.modal_folios} 
                   toggle={this.toggle_modal} 
                   title={this.state.title} 
                   prospectoid={this.state.folio_id}
                  recargar_tabla={this.recargar_tabla}
                />
                }
                </div>    
                
		);
	}
}

function mapStateToProps(state, ownProps) {
    return {
        ticket : state.ticket,
    }
};

export default connect(mapStateToProps, actions)(ModalCategorias)