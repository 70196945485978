import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle , Button} from 'reactstrap';

import ReactTable from "react-table";
import "react-table/react-table.css";


import * as actions from '../../actions/user';
import {api, API_URL} from '../../actions/_request';

const filterCaseInsensitive = (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (row[id] !== null){
                return (
                    row[id] !== undefined ?
                        String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase())
                        :
                        true
                );
            }
        };
class Impuestos extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            modal       : false,
            prospectoid : null,
            title       : "",
            data:[],
        };
      
        this.seleccion = this.seleccion.bind(this);
    }
    seleccion(renglon){
       let retorno =renglon;
       // retorno.id=renglon.id;
       // retorno.label=renglon.descripcion;
       // retorno.codigo=renglon.codigo;
       this.props.retorno(retorno);

    }
    componentDidMount() {
      let self = this;
      api().get(`/getseriealmacen/${this.props.origen}/${this.props.articulo}`)
      .then(function(response)
      {
        if(response.status === 200)
        {
          self.setState({
            data : response.data
          });
        }
      });
    }

    render() {

        return(
            <div className="view">            
                    <Card>
                        <CardBody>                        
                            <ReactTable
                               getTrProps={(state, rowInfo, column) => {                                  
                                  if(rowInfo === undefined){                                          
                                    return{}
                                  }else{
                                    return{onDoubleClick: (evt)=>this.seleccion(rowInfo.original)}                                   
                                  }
                                }
                              }
                              data      = {this.state.data}
                              className = "-striped -highlight"
                              columns   = {[
                               
                               
                                {Header: 'Serie', accessor: 'serie' },   
                                                 
                              
                              ]}
                              filterable 
                             pageSizeOptions= {[5]}  defaultPageSize={5} 
                              
                               defaultFilterMethod={filterCaseInsensitive}
                             
                              
                             
                            />
                        </CardBody>
                    </Card>
               
               
                 

            </div>
        );
    }

};

function mapStateToProps(state, ownProps) {
    return {
        user : state.user,
    }
};

export default connect(mapStateToProps, actions)(Impuestos)