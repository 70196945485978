import React from 'react';

import {api,api_formdata} from '../../actions/_request';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,Badge, Table 
} from "reactstrap";

import Moment from 'moment';
import swal from 'sweetalert2';

import ReactTable from "react-table";
import "react-table/react-table.css";

import Spinner from 'react-spinkit';

const SubmitLoad = () => (
    <div className="p-2">
        <Spinner name="circle" />
    </div>
);

const filterCaseInsensitive = (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (row[id] !== null){
                return (
                    row[id] !== undefined ?
                        String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase())
                        :
                        true
                );
            }
        };

export default class Subidas extends React.Component{

	constructor(props){
		super(props);

		this.state={
			semana : {
				id : null,
				fecha_pago : '',
				files_xml : [],
			},
			action : 'save',
			subiendo : false,
		}
	}

	componentDidMount(){
    
	    let _self = this;
	    let {semana} = this.state;
	    let {params} = this.props.match;

	    if(params.id) {
	     	api().get("/semana/"+params.id)
	      	.then(function(response){
	          	_self.setState({
	            	semana : response.data,
	            	action : 'update'
	          	})
	     	});
	    }

	}

	add=(evt)=>{
		this.props.history.push('/app/uploads');
	}

	handleInputChange = (event) =>{
		let {semana} = this.state;
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
	    const name = target.name;

	    semana[name] = value.toUpperCase();
	    
	    this.setState({
	      semana : semana
	    });
	}

	handleSubmit=(event)=>{
		event.preventDefault();
		let {semana,action,subiendo} = this.state;

		let form = document.getElementById('mamadas');
		var formData = new FormData(form);

		let _self = this;

		this.setState({subiendo : true})
		
		if(action == 'save'){
			api().post('/subidas',formData)
				.then((res)=>{
					if(res.data.error){
						swal.fire("Error!", 'Semana ya existente', "warning");
						_self.setState({subiendo : false})
					}else{
						//_self.props.history.push('/app/uploads');
						_self.setState({subiendo : false, action : 'update', semana : res.data.datos})
						swal.fire("Correcto!", 'Subida exitosa!', "success");
					}
				})
				.catch((err)=>{
					console.log(err)
				})
		}else{
			api().post('/re_subidas',formData)
				.then((res)=>{
					if(res.data.error){
						swal.fire("Error!", 'Semana ya existente', "warning");
						_self.setState({subiendo : false})
					}else{
						_self.setState({subiendo : false, action : 'update', semana : res.data.datos})
						swal.fire("Correcto!", 'Subida exitosa!', "success");
					}
				})
				.catch((err)=>{
					console.log(err)
				})
		}

		console.log('submiting');
	}

	addXml = (e) =>{
		let {semana} = this.state;
		let files = e.target.files;
		
		semana.files_xml = files;

		this.setState({semana : semana})
	}

	render(){

		let {semana,subiendo,action} = this.state;

		return(
			<div className="content">
				<Card>
					<CardHeader>
						<Row>
							<Col xs="12" className="text-right">
								<Button onClick={this.add}>regresar</Button>
							</Col>
						</Row>
					</CardHeader>
					<CardBody>
						<Row>
							<Col xs="12" sm="4">
								<Form onSubmit={this.handleSubmit} id='mamadas'>
									<Row>
										<Col xs="12">
											<label>Semana</label>
											<Input
												name="fecha_pago"
												id="fecha"
												type="text"
												onChange={this.handleInputChange}
												value={semana.fecha_pago}
												placeholder="Semana-Año"
												
											/>
										</Col>
										<Col xs="12" style={{'display':'none'}}>
											<label>id</label>
											<Input
												name="id"
												id="id"
												type="text"
												value={semana.id}
											/>
										</Col>
										{
											action =='update'&&
												<Col xs="12">
													<label><b>Archivos Subidos:</b> {semana.no_files}</label>
												</Col>
										}
									</Row>
									<Row className="mt-5">
										<Col xs="12">
											<input type="file"
										       id="xmls" name="xmls[]"
										       accept="text/xml"
										       multiple
										       
										    />
										</Col>
									</Row>
									{
										!subiendo?
											<Button type="submit">Guardar</Button>
										:
											<SubmitLoad />
									}
								</Form>
								
							</Col>
						</Row>
					</CardBody>
				</Card>
				{
					action =='update'&&
						<Card>
							<CardHeader>
								<Row>
									<Col xs="12" className="text-center">
										
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
							<Row>
									<Col xs="2" className="text-center">
										
									</Col>
									<Col xs="8" className="text-center">
										<ReactTable
			                              className = "-striped -highlight"
			                              columns   = {[
			                                {Header: 'Archivo', accessor: 'path',style: { textAlign: 'center'}},
			                              ]}
			                              data      = {this.state.semana.files}
			                              filterable
			                              defaultFilterMethod={filterCaseInsensitive}
			                            />
									</Col>
									<Col xs="2" className="text-center">
										
									</Col>
								</Row>
							</CardBody>
						</Card>
				}
			</div>
		);
	}
}