import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle , Button} from 'reactstrap';

import ReactTable from "react-table";
import "react-table/react-table.css";


import * as actions from '../../actions/user';
import {api, API_URL} from '../../actions/_request';

const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null){
        return (
            row[id] !== undefined ?
                String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }
};
class Departamentos extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
          modal       : false,
          prospectoid : null,
          title       : "",
          data:[],
      };
  }

  seleccion(renglon){
       renglon.codigo=renglon.folio;
       this.props.retorno(renglon);
  }

  componentDidMount() {
    let self = this;
    api().get('/folios/busqueda')
    .then(function(response)
    {
      if(response.status === 200)
      {
        self.setState({
          data : response.data.datos
        });
      }
    });
  }

    render() {

        return(
            <div className="view">            
                    <Card>
                        <CardBody>                        
                            <ReactTable
                              getTrProps={(state, rowInfo, column) => {                                  
                                  if(rowInfo === undefined){                                          
                                    return{}
                                  }else{
                                    return{onDoubleClick: (evt)=>this.seleccion(rowInfo.original)}                                   
                                  }
                                }
                              }
                              data      = {this.state.data}
                              className = "-striped -highlight"
                              columns   = {[
                               
                                {Header: 'Codigo', accessor: 'folio',maxWidth:100,minWidth:100},
                                {Header: 'Nombre', accessor: 'nombre' },                      
                              
                              ]}
                              filterable 
                              defaultPageSize={5} 
                              pageSizeOptions= {[5]}
                              defaultFilterMethod={filterCaseInsensitive}
                             
                              
                             
                            />
                        </CardBody>
                    </Card>
            </div>
        );
    }

};

function mapStateToProps(state, ownProps) {
    return {
        user : state.user,
    }
};

export default connect(mapStateToProps, actions)(Departamentos)