import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Label,FormGroup, Input,InputGroup, InputGroupAddon
} from 'reactstrap';



import swal from 'sweetalert2';
import * as actions from '../../actions/auth';
import {api} from '../../actions/_request';

import ReactTable from "react-table";
import "react-table/react-table.css";

const filterCaseInsensitive = (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (row[id] !== null){
                return (
                    row[id] !== undefined ?
                        String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase())
                        :
                        true
                );
            }
        };




class TicketModal extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
              cc:[],
              ubicacion:{
                    almacen:'',
                    zona:'',
                    pasillo:'',
                    anaquel:'',
                    repisa:'',
              },
              action:'save',
              submit:false,
            
            
        };

 
        this.handleInputChange = this.handleInputChange.bind(this);
        this.delete = this.delete.bind(this);
        this.push = this.push.bind(this);
        this.regresar = this.regresar.bind(this);
	}
    push(){
        let {cc,ubicacion} = this.state;
        let articulo_id=this.props.articulo_id;
        let self = this;
        /*peticion para agregar*/
         this.setState({
           submit:true,
        });
        console.log('data---->',ubicacion)

          api().post(`/save_update_ubicaciones/${articulo_id}`,ubicacion)
          .then(function(response)
          {  
             cc = response.data.ubicaciones;
             if(response.data.estado==false)
             swal.fire("Alerta!", response.data.mensaje, "warning");
         
            self.setState({
                cc : cc,
                ubicacion:{
                        articulo_id : self.props.articulo_id,
                        almacen:'',
                        zona:'',
                        pasillo:'',
                        anaquel:'',
                        repisa:'',
                  },
                action:'save',
                submit:false,
            });
           

          });
        
    }
    delete(evt,id){
         

       
        

        // console.log('Factura------>',factura)
      let self = this;
      swal.queue([{
        title: 'Favor de Confirmar ',
        text: " Se eliminar la Ubicacion con el codigo: "+id,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return api().get(`/delete_ubicacion/${id}`)
        .then(function(response)
        {
          if(response.status === 200)
          {
               swal.insertQueueStep({
                        type: 'success',
                        title: 'Se Elimino Correctamente la Ubicacion'
                      });
                self.setState({
                cc : response.data,
                
            });

           
          }
        }).catch(() => {
              swal.insertQueueStep({
                type: 'error',
                title: 'Solicitar ayuda a un Administrador',
              })
            })
        }
      }])


        
    
    }

    editar(evt,renglon){
        let {ubicacion} = this.state;  

      console.log('renglon--->',renglon)
        this.setState({
          ubicacion : renglon,
          action:'update'
        });
    }

    regresar( ){
         

    
        this.setState({
          ubicacion:{
                        articulo_id : this.props.articulo_id,
                        almacen:'',
                        zona:'',
                        pasillo:'',
                        anaquel:'',
                        repisa:'',
                  },
          action:'save'
        });
    }
    handleInputChange(event) {
        let {ubicacion} = this.state;
        const target    = event.target;
        let value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;

        this.setState({
           ubicacion: {
                ...this.state.ubicacion,
                [name] : value,
            }, 
           
        });
    }

    componentDidMount()
    {
        let cliente=this.props.cliente;
        let self=this;


        api().get(`/get_ubicaciones/${this.props.articulo_id}`)
          .then(function(response)
          {  
            let cc = response.data;
            self.setState({
                cc : cc,
                 ubicacion: {
                    ...self.state.ubicacion,
                    articulo_id : self.props.articulo_id,
                    }, 
                    action:'save'
            });
           

          });


    }



	render()
	{
     
		
		return(

			<Modal isOpen={this.props.open} toggle={this.props.toggle} style={{marginTop: '35px'}} className="default modal-lg">
				<form >
                <ModalHeader toggle={this.props.toggle}>Ubicaciones</ModalHeader>
                <ModalBody>
                    <div className="row">
                    <div className="col-md-1"> 
                        <div className="form-group">
                            <Label>Codigo *</Label>
                            <Input
                            className='texto_normal'
                            readOnly
                            type="text"
                            name="id"
                          
                            value={this.state.ubicacion.id?this.state.ubicacion.id:''}
                           
                            />
                        </div>
                      </div>
                    <div className="col-md-3"> 
                        <div className="form-group">
                            <Label>Almacen *</Label>
                            <Input
                            className='texto_normal'
                            placeholder=""
                            type="text"
                            name="almacen"
                            maxLength={70}
                            value={this.state.ubicacion.almacen}
                            onChange={ this.handleInputChange}
                            />
                        </div>
                      </div>
                            <div className="col-md-3"> 
                                <div className="form-group">
                                    <label >Zona*:</label>
                                    <Input
                                    className='texto_normal'
                                    placeholder=""
                                    type="text"
                                    name="zona"
                                    maxLength={70}
                                    value={this.state.ubicacion.zona}
                                    onChange={ this.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3"> 
                                <div className="form-group">
                                    <label >Pasillo*:</label>
                                    <Input
                                    className='texto_normal'
                                    placeholder=""
                                    type="text"
                                    name="pasillo"
                                    maxLength={70}
                                    value={this.state.ubicacion.pasillo}
                                    onChange={ this.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3"> 
                                <div className="form-group">
                                    <label >Anaquel*:</label>
                                    <Input
                                    className='texto_normal'
                                    placeholder=""
                                    type="text"
                                    name="anaquel"
                                    maxLength={70}
                                    value={this.state.ubicacion.anaquel}
                                    onChange={ this.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3"> 
                                <div className="form-group">
                                    <label >Repisa*:</label>
                                    <Input
                                    className='texto_normal'
                                    placeholder=""
                                    type="text"
                                    name="repisa"
                                    maxLength={70}
                                    value={this.state.ubicacion.repisa}
                                    onChange={ this.handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 text-right">
                                <div className="form-group">
                                    <Button color="primary" className="" 
                                    disabled={this.state.submit}
                                     onClick={this.push}>
                                        Guardar
                                    </Button>
                                    {this.state.action =='update'&&
                                    <Button
                                      
                                      color="warning"
                                      onClick={this.regresar}>
                                      Cancelar
                                    </Button>
                                 }
                                </div>
                                
                            </div>

                    </div>
                    <div className="row">
                        <div className="col-md-12"> 
                            <ReactTable
                              data      = {this.state.cc}
                              className = "-striped -highlight"
                              columns   = {[
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                    maxWidth:250,minWidth:250,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-left">
                                                <Button
                                                 color="danger" 
                                                 className='m-0 p-2 px-3'
                                                 onClick={(evt)=>this.delete(evt, row.original.id)}>
                                                    Eliminar
                                                </Button> 
                                                <Button
                                                 color="success" 
                                                 className='m-0 ml-3 p-2 px-3'
                                                 onClick={(evt)=>this.editar(evt, row.original)}>
                                                    Editar
                                                </Button> 
                                            </div>
                                        )
                                    }
                                },
                                {Header: 'codigo', accessor: 'id',maxWidth:100,minWidth:100},
                                {Header: 'almacen', accessor: 'almacen',minWidth:250},
                                {Header: 'zona', accessor: 'zona',minWidth:250},
                                {Header: 'pasillo', accessor: 'pasillo',minWidth:250},
                                {Header: 'anaquel', accessor: 'anaquel',minWidth:250},
                                {Header: 'repisa', accessor: 'repisa',minWidth:250},
                                                     
                              
                              ]}
                              filterable 
                              pageSizeOptions= {[5]}  defaultPageSize={5} 
                              defaultFilterMethod={filterCaseInsensitive}
                            />        
                        </div>
                    </div>
                    
                </ModalBody>
                <ModalFooter>
                    
                    	<div>
                    	
                    		<Button color="secondary" onClick={this.props.toggle}>
		                    	Cerrar
		                    </Button>
                    	</div>
                   
                </ModalFooter>
                </form>
            </Modal>
		);
	}
}

function mapStateToProps(state, ownProps) {
    return {
         auth : state.auth,
    }
};

export default connect(mapStateToProps, actions)(TicketModal)