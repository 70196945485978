import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import "react-table/react-table.css";

import {API_URL} from '../../actions/_request';
import {api} from '../../actions/_request';
import _ from "lodash";

import * as actions from '../../actions/user';
import swal from 'sweetalert2';
import moment from 'moment';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,Badge, Table 
} from "reactstrap";

class Users2 extends Component {
  render() {
    return (
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="card-user">
                <CardBody>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    );
  }
}

const requestData = (pageSize, page, sorted, filtered) => {    
    return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        let filteredData ;
       
        var folio='';
        var fecha='';
        var id='';
        var cliente='';
        var tipo='';
        var take= pageSize;
        var skip=pageSize * page;
        var total=1; 
        var patt = new RegExp("(si|s)", "i"); 
        for (var i = filtered.length - 1; i >= 0; i--) {
          switch(filtered[i].id)
          {
                       
            case "folio":
              folio=filtered[i].value;
              console.log('folio--->',filtered[i].value);
              break;
            case "fecha_timbrado":
              fecha=filtered[i].value;
              console.log('folio--->',filtered[i].value);
              break;
            case "facturar_a":
              cliente=filtered[i].value;
              console.log('folio--->',filtered[i].value);
              break;
            case "documento":
              tipo=filtered[i].value;
              console.log('folio--->',filtered[i].value);
              break;
            case "id":
              id=filtered[i].value;
              console.log('id--->',filtered[i].value);
              break; 
          }
        }
              
              api().get(`/getfacturas?folio=${folio}&tipo=${tipo}&fecha=${fecha}&cliente=${cliente}&id=${id}&take=${take}&skip=${skip}`)
              .then(function(response)
              {
                if(response.status === 200)
                {
                  filteredData= response.data.datos;
                  total= response.data.total; 
                  // You can also use the sorting in your request, but again, you are responsible for applying it.
                  const sortedData = _.orderBy(
                    filteredData,
                    sorted.map(sort => {
                      return row => {
                        if (row[sort.id] === null || row[sort.id] === undefined) {
                          return -Infinity;
                        }
                        return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                      };
                    }),
                    sorted.map(d => (d.desc ? "desc" : "asc"))
                  );
                  const res = {
                    rows: sortedData,
                    pages: Math.ceil(total / pageSize)
                  };
                  // Here we'll simulate a server response with 500ms of delay.
                  //setTimeout(() => resolve(res), 1000);
                  //console.log(res);
                  resolve(res)
                }
              });   
        
    });
};



class Articulos extends Component {

  constructor(props) {

        super(props);

        this.state = {
             pages: null,
             loading: true,
        };

        this.fetchData = this.fetchData.bind(this);
        this.table = React.createRef();
    }

    abrir_pdf=(id)=>{
       window.open(`${API_URL}/pdf_documento/${id}`)
    }
    abrir_factura=(id)=>{
       window.open(`${API_URL}/pdf_timbre/${id}/I`)
    }
    verificar=(id)=>{
let self=this;
      swal.queue([{
        title: 'Timbrar Factura ',
        text: "Estas seguro ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return api().get('/timbrar_factura/'+id)
            .then(function(response)
            {    
                // console.log('response-->',response)    
                if(response.status === 200)
                {
                  if(response.data.estado==200){
                      swal.insertQueueStep({
                        type: 'success',
                        title:  'Factura Timbrada',
                      })
                      self.fetchData(self.table.current.state);
                  }else{
                    swal.insertQueueStep({
                      type: 'warning',
                      title:  response.data.mensaje,
                    })
                  }
                }
                  
            })
            .catch((error) => {
              console.log('error---->',error)
              swal.insertQueueStep({
                type: 'error',
                title: 'Solicitar ayuda a un Administrador',
              })
            })
        }
      }])

    }

      cancelar_factura=(id)=>{
let self=this;
      swal.queue([{
        title: 'Se Cancelara la Factura ',
        text: "Estas seguro ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return api().get('/cancelar_factura/'+id)
            .then(function(response)
            {    
                // console.log('response-->',response)    
                if(response.status === 200)
                {
                  if(response.data.estado==200){
                      swal.insertQueueStep({
                        type: 'success',
                        title:  'Factura Cancelada',
                      })
                      self.fetchData(self.table.current.state);
                  }else{
                    swal.insertQueueStep({
                      type: 'warning',
                      title:  response.data.mensaje,
                    })
                  }
                }
                  
            })
            .catch((error) => {
              console.log('error---->',error)
              swal.insertQueueStep({
                type: 'error',
                title: 'Solicitar ayuda a un Administrador',
              })
            })
        }
      }])

    }


     fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
      // console.log('datos--->',res.rows);
       // this.props.get(res.rows);
    });
  }


  render() {
    const {  pages, loading} = this.state;
    return (
      <div className="content">
        <Row>
          <Col >
            <Card>
              <CardHeader>
              <Row>
              <Col xs={6}>
            
              </Col>
              <Col className='text-right' xs={6}>
              <Link to="/app/facturas_imp">
                <Button color="warning" className="mb-2" >
                    Importar Factura
                </Button>
              </Link>
              <Link to="/app/facturas/nuevo">
                <Button color="primary" className="mb-2" >
                    Agregar Factura
                </Button>
              </Link>
              </Col>
              </Row>
              </CardHeader>
              <CardBody>
              <ReactTable
                             getTrProps={(state, rowInfo, column) => {
                                 
                                  if(rowInfo === undefined){
                                         
                                          return{

                                          }
                                        }else{
                                           return{
                                            onDoubleClick: (e) => {
                                                  if(parseInt(rowInfo.original.salida)==0&&parseInt(rowInfo.original.timbrado)==0&&parseInt(rowInfo.original.cancelado)==0)
                                                  this.props.history.push('/app/facturas/editar/'+rowInfo.original.id);
                                             
                                              
                                            }
                                        } 
                                  
                                    }
                              }}
                              
                              
                              className = "-striped -highlight"
                              columns   = {[
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                     maxWidth:250,minWidth:250,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-center">

                                        
                                                {
                                                  (parseInt(row.original.salida)==0&&parseInt(row.original.timbrado)==0&&parseInt(row.original.cancelado)==0)&&<Button
                                                 color="success" 
                                                 className="btn-sm" onClick={(evt)=>{this.props.history.push('/app/facturas/editar/'+row.original.id); }}>
                                                    Editar
                                                </Button>
                                              }

                                              {
                                                  (parseInt(row.original.importada)==1&&parseInt(row.original.cancelado)==0)&&<Button
                                                 color="success" 
                                                 className="btn-sm" onClick={(evt)=>{this.props.history.push('/app/facturas_imp/editar/'+row.original.id); }}>
                                                    Editar Imp
                                                </Button>
                                              }
                                                {
                                                  (parseInt(row.original.timbrado)==0 && parseInt(row.original.tipo_documento )==1 )  &&
                                                 <Button
                                                 color="info" 
                                                 className="btn-sm"  onClick={(evt)=>{this.verificar(row.original.id) }}>
                                                    Timbrar
                                                </Button>
                                              }
                                              {
                                                  (parseInt(row.original.cancelado)==0&&parseInt(row.original.timbrado)==1 && parseInt(row.original.tipo_documento )==1 )  &&
                                                 <Button
                                                 color="danger" 
                                                 className="btn-sm"  onClick={(evt)=>{this.cancelar_factura(row.original.id) }}>
                                                    Cancelar
                                                </Button>
                                              }
                                              {
                                                  (parseInt(row.original.timbrado)==1 && parseInt(row.original.tipo_documento )==1 && parseInt(row.original.importada)==0 )  &&
                                                 <Button
                                                 color="info" 
                                                 className="btn-sm"  onClick={(evt)=>{this.abrir_factura(row.original.id) }}>
                                                    PDF Timbrado
                                                </Button>
                                              }
                                               {
                                                  (parseInt(row.original.timbrado)==0 && parseInt(row.original.importada)==0)  &&
                                                 <Button
                                                 color="info" 
                                                 className="btn-sm"  onClick={(evt)=>{this.abrir_pdf(row.original.id) }}>
                                                    PDF Documento
                                                </Button>
                                              }
                                                
                                            </div>
                                        )
                                    }
                                },
                                {Header: 'Fecha', accessor: 'fecha_timbrado',maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Tipo', accessor: 'documento',maxWidth:150,minWidth:150,style: { textAlign: 'center'},
                               Filter: ({ filter, onChange }) =>
                                    <select onChange={event => onChange(event.target.value)}   value={filter ? filter.value : "0"}>
                                      <option value="">Todos</option>
                                      <option value="1">Facturas</option>
                                      <option value="2">Remisiones</option>
                                      
                                    </select>},

                                {Header: 'Folio', accessor: 'folio',maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Cliente', accessor: 'facturar_a'},
                                {Header: 'Total', accessor: 'total_numero',filterable: false, sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Saldo', accessor: 'saldo_numero',filterable: false,  sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},

                              
                               
                               
                                
                              ]}
                              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                              data      = {this.state.data}
                              pages={pages} // Display the total number of pages
                              loading={loading} // Display the loading overlay when we need it
                              onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                              filterable
                              ref={this.table}
                             
                              defaultPageSize={25} 
                              
                              
                             
    
                            />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
    return {
        user : state.user,
        auth:state.auth,
    }
};

export default connect(mapStateToProps, actions)(Articulos)
