import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/almacenes';
import {api } from '../../actions/_request';
import swal from 'sweetalert2';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent, TabPane, Nav, NavItem, NavLink, CardText
} from "reactstrap";
import classnames from 'classnames';
import ModalTablas from '../../components/catalogos/modal_tablas';
  
class Proveedor extends Component {
  constructor(props){
    super(props);
    this.state = {
      articulo:{
        
        nombre:'',
        direccion:'',
        'iva':'',
        encargado_id:'',
        

      },
      action : 'save',
      submit:false,
      activeTab: '1',
      
    }
     this.handleSubmit = this.handleSubmit.bind(this);
    
     this.handeInputChange = this.handeInputChange.bind(this);
     
  }

  retorno=(objeto,campo,vacio=0,valor="")=>{
        let label="";
        let codigo="";
        let id="";
        let {articulo} = this.state;
        let self =this;
        if(vacio==0){
          
          codigo=objeto.name;
          id=objeto.id;
        }
        self.setState({
            articulo: {
                ...this.state.articulo,
                [campo]: id
            },
            ['codigo_'+campo]:codigo,
            modal:false,         
        })
    }
  toggle_modal=(index=0,catalogo="",campo="")=>{  

        this.setState({
            modal : !this.state.modal,
            titulo:catalogo,
            tabla_modal:index,
            campo_modal:campo,         
        })
    }

  componentDidMount(){
    

    let _self = this;
    let {articulo} = this.state;
    let {params} = this.props.match;
    if(params.id) {
      api().get("/getalmacen/"+params.id)
      .then(function(response){
        if(response.status === 200){
          articulo = response.data;
          _self.setState({
            articulo : articulo,
            action : 'update',
            codigo_encargado_id:articulo.user,
          })
        }

      });
    }

  }


  handeInputChange(event){
    let {articulo} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    articulo[name] = value;
// console.log('articulo handeInputChange---->',articulo)
    this.setState({
        articulo : articulo
    });
  }

  handleSubmit(evt){
    evt.preventDefault();
    let _self = this;
    let {articulo,action} = this.state;
    let save = true;
    _self.setState({
            submit : true
          })

    this.props.save(articulo, (response) => {
                if(response.status){
                  if(response.data.estado){
                    // console.log('actualizar')
                    swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success");
                    
                     _self.setState({
                      articulo : response.data.proveedor,
                      action : 'update',
                      submit : false
                    })
                    

                  }else{
                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    _self.setState({
                        submit : false
                      });
                  }
                }else{
                  console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
                }
              });



  }

  regresar=(evt)=>
  {
    evt.preventDefault();
    
    this.props.history.push('/app/almacenes');
  }

  render() {
     let {submit,articulo} = this.state;
     console.log('Proveedor---->',articulo)
    return (
      
        <div className="content">
          <Row>
          
            <Col md="12">
            <Form onSubmit={this.handleSubmit}>
              <Card className="card-user">
                <CardHeader>
                 <Row>
                      <Col className="update  text-right">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          disabled={submit}
                        >
                          Guardar
                        </Button>
                        <Button
                          className="btn-round"
                          color="warning"
                          onClick={this.regresar}>
                          Cancelar
                        </Button>
                      </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                 <Row>
                <Col className="pr-1" md="2">
                        <FormGroup>
                          <label>Codigo</label>
                          <Input
                           
                            type="text"
                            name="id"
                            required
                            value={articulo.id}
                            onChange={this.handeInputChange}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="3">
                        <FormGroup>
                          <label>IVA</label>
                          <Input
                            placeholder=""
                            type="text"
                            name="iva"
                            required
                            value={articulo.iva}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                      </Row>
                <Row>
                <Col className="pr-1" md="10">
                        <FormGroup>
                          <label>Nombre</label>
                          <Input
                            placeholder="Nombre del Almacen"
                            type="text"
                            name="nombre"
                            required
                            value={articulo.nombre}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <label>Direccion</label>
                          <Input
                            placeholder="Direccion"
                            type="text"
                            name="direccion"
                            required
                            value={articulo.direccion}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <div className="col-md-12"> 
                            <div className="form-group">
                                <label className="d-block">Encargado </label>
                                <Input
                                required
                                placeholder=""
                                type="text"
                                name="codigo_encargado_id"
                                value={this.state.codigo_encargado_id}
                                onChange={ this.ChangeCode}
                                onBlur={this.handleClose}
                                className="input_button_sm"
                                readOnly
                              
                                />
                                <Button className='m-0' onClick={ (button) => { this.toggle_modal(27,"Usuarios","encargado_id") } }>...</Button>
                            </div>
                        </div>

                </Row>
                </CardBody>
              </Card>
              </Form>
            </Col>
          </Row>
          {this.state.modal&&
            <ModalTablas
                    open={this.state.modal}
                    toggle={this.toggle_modal}                  
                    titulo={this.state.titulo}
                    catalogo={this.state.tabla_modal}
                    retorno={this.retorno}
                    campo={this.state.campo_modal}
                />
          }
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        auth : state.auth,
        almacenes:state.almacenes
    }
};

export default connect(mapStateToProps, actions)(Proveedor)

