import React from 'react';

import {Link} from 'react-router-dom';

import {api} from '../../actions/_request';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,Badge, Table 
} from "reactstrap";

import ReactTable from "react-table";
import "react-table/react-table.css";

export default class Subidas extends React.Component{

	constructor(props){
		super(props);

		this.state={
			data : [],
		}
	}

	componentDidMount(){

		let _self = this;

		api().get('/semanas')
			.then((res)=>{
				_self.setState({data : res.data});
			})
			.catch((err)=>{console.log(err)})

	}

	render(){

		let cols =[
			{Header: 'Semana', accessor: 'fecha_pago',style: { textAlign: 'center'}},
	        {Header: '# de archivos', accessor: 'no_files'},
	        {
                Header: 'Controles',
                filterable: false,
                sortable: false,
                 maxWidth:110,
                Cell: (row) =>
                {
                    return(
                        <div className="text-center">
                            <Button
                             color="success" 
                             className="btn-sm" onClick={(evt)=>{this.props.history.push('/app/uploads/edit/'+row.original.id); }}>
                                Gestionar
                            </Button>
                        </div>
                    )
                }
            }
		];


		return(
			<div className="content">
				<Card>
					<CardHeader>
						<Row>
							<Col xs="12" className="text-right">
								<Link to="/app/uploads/add">
	              					<button className="btn btn-primary" type="button">
									<i className="fa fa-plus-square"></i>&nbsp;Agregar</button>
								</Link>
							</Col>
						</Row>
					</CardHeader>
					<CardBody>
						<Row>
							<Col>
								<ReactTable
		                            getTrProps={(state, rowInfo, column) => {
		                                 
		                                if(rowInfo === undefined){      
		                                    return{}
		                                }else{
		                                       return{
		                                        onDoubleClick: (e) => {
		                                            this.props.history.push('/app/uploads/edit'+rowInfo.original.id); 
		                                        }
		                                    } 
		                              
		                                }
		                            }}
		                            className = "-striped -highlight"
		                            data = {this.state.data}
		                            defaultPageSize={25} 
		                            columns={cols}
		                           />
							</Col>
						</Row>
					</CardBody>
				</Card>
			</div>
		);
	}
}