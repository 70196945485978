import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/ca_proveedor';
import {api } from '../../actions/_request';
import swal from 'sweetalert2'
import ReactTable from "react-table";
import "react-table/react-table.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent, TabPane, Nav, NavItem, NavLink, CardText,Label
} from "reactstrap";
import classnames from 'classnames';
import Select from 'react-select';
import ModalTablas from '../../components/catalogos/modal_tablas';
import moment from 'moment';

  const monedas = [
    {label: 'MXN', value: 'MXN'},
    {label: 'USD', value:'USD'},
];
const tipos = [
    {label: 'Abono', value: 1},
    {label: 'Cargo', value: 2},
    
];

const conceptos = [
    {label: 'Compra', value: 'Compra'},
    {label: 'Gasto', value: 'Gasto'},
    
];

  
class Proveedor extends Component {
  constructor(props){
    super(props);
   
    this.state = {
      movimiento:{
        id:'',
        proveedor_id:'',
        documento_id:0,
        tipo:1,
        concepto:'',
        fecha:  moment().format('Y-MM-DD'),
        forma_pago:'01',
        anexo:'',
        total:'',
        moneda:'',
        tc:0,
        cancelado:0,
        verificado:0,
        fecha_vencimiento: moment().format('Y-MM-DD')

      },
      action : 'save',
      submit:false,
      activeTab: '1',
      cc:[],
      cc_text:'',
      modal:false,
      formas : [],
      metodos : [],
      usos : [],
      codigo_pais:'MEX',
      
      
    }
     this.handleSubmit = this.handleSubmit.bind(this);
     this.handeInputChange = this.handeInputChange.bind(this);
     this.toggle_modal = this.toggle_modal.bind(this);
     this.ChangeCode= this.ChangeCode.bind(this);
     this.handleClose= this.handleClose.bind(this);
     this.retorno= this.retorno.bind(this);
   
     this.handleSelectChange= this.handleSelectChange.bind(this);
  }
  handleSelectChange(select, name) {
        const value = select === null ? null : select.value;
        console.log('select--->',select)
        console.log('name--->',name)
        if(name=='tipo'){

          var concepto='';
          if(parseInt(value)==2){
            concepto='Compra';
          }



           this.setState({
            movimiento: {
                ...this.state.movimiento,
                [name]: value,
                documento_id:0,
                concepto:concepto
                
            },
            codigo_documento_id:'',
            label_documento_id:'',
        });

        }else{


           this.setState({
            movimiento: {
                ...this.state.movimiento,
                [name]: value
            }
        });




        }
       
    }

    handleClose (event,tabla,campo) {

        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
        let self =this;
        if(value!=""){
            api().get(`${tabla}/${value}`)
            .then(function(response)
            {                  
                if(response.status === 200)
                { 
                    let vaciar=0;
                     if(Object.keys(response.data).length > 0){
                       
                       
                     }  else{
                         vaciar=1
                         swal.fire("Oops..", "No Existe el Codigo");
                     }
                     self.retorno(response.data,campo,vaciar,value);        
                   
                }

            });
        }else{
            self.retorno([],campo,1,value); 
        }
            

   }

   retorno(objeto,campo,vacio=0,valor=""){
        let label="";
        let codigo="";
        let id="";
        let {movimiento} = this.state;
        let self =this;
        if(vacio==0){
          label=objeto.label;
          codigo=objeto.codigo;
          id=objeto.id;
        }




         if(campo=='proveedor_id'){
          var new_date = moment(movimiento.fecha).add( 'days',parseInt(objeto.dias_credito)).format('Y-MM-DD');
          console.log('fecha_vencimiento--->',new_date)
          self.setState({
            movimiento: {
                ...this.state.movimiento,
                [campo]: id,
                moneda:objeto.moneda,
                fecha_vencimiento:new_date
            },         
            ['label_'+campo]:label,
            ['codigo_'+campo]:codigo,
            modal:false,         
        })
        }else{
          self.setState({
            movimiento: {
                ...this.state.movimiento,
                [campo]: id
            },         
            ['label_'+campo]:label,
            ['codigo_'+campo]:codigo,
            modal:false,         
        })
        }






    }

 

    ChangeCode(event) {
     
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
     
        this.setState({
            [name] : value
        });
    }
  toggle_modal(index=0,catalogo="",campo=""){  

        this.setState({
            modal : !this.state.modal,
            titulo:catalogo,
            tabla_modal:index,
            campo_modal:campo,         
        })
    }



  componentDidMount(){
    let _self = this;
    let {movimiento} = this.state;
    let {params} = this.props.match;
      let self = this;
          let folio_id=this.props.auth.user.data_folio.id;
    api().get(`/formulario_factura/${folio_id}`)
        .then(function(response)
        {
            self.setState({
                metodos : response.data.metodos,
                formas : response.data.formas,
                usos : response.data.usos,
                movimiento: {
                ...self.state.movimiento,
                tc: response.data.cambio,
               
            },
                
            });
           
        })




    if(params.id) {
      api().get("/getca_proveedores/"+params.id)
      .then(function(response){
        if(response.status === 200){
          let data = response.data;
          _self.setState({
            codigo_proveedor_id:data.codigo_proveedor_id,
            label_proveedor_id:data.label_proveedor_id,
            codigo_documento_id:data.codigo_documento_id,
            label_documento_id:data.label_documento_id,
            movimiento : data,
            action : 'update'
          })
        }
      });
    }
  }


  handeInputChange(event){
    let {movimiento} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    movimiento[name] = value;
    this.setState({
      movimiento : movimiento
    });
  }

  handleSubmit(evt){
    evt.preventDefault();
    let _self = this;
    let {movimiento} = this.state;
    let save = true;
    // _self.setState({
    //   submit : true
    // });
    console.log('movimiento--->',movimiento)
    var patt = new RegExp("^[0-9]+([.][0-9]+)?", "i");
    // if(parseInt(movimiento.documento_id)>0){  
    if(parseInt(movimiento.proveedor_id)>0){    
     /*validamos el total y el proveedor*/
    if(movimiento.total.search(patt)==0){
      console.log('movimiento----->',movimiento)


    /*validamos el rfc*/
    this.props.save(movimiento, (response) => {
      if(response.status){
        if(response.data.estado){
        // console.log('actualizar')
        swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success").then((result) => {
                        _self.props.history.push('/app/a_proveedores');
                        });
        
        }else{
          swal.fire("Alerta!", response.data.mensaje, "warning");
          _self.setState({
            submit : false
          });
        }
      }else{
        console.log('error')
        swal.fire({
          type: 'error',
          title: 'Solicitar ayuda a un Administrador',
        })
        _self.setState({
          submit : false
        });
      }
    });

    }else{
      swal.fire("Oops..", "Favor de revisar el Total", "warning");
    }
    }else{
      swal.fire("Oops..", "Favor de Asignar un proveedor", "warning");
    }
    // }else{
    //   swal.fire("Oops..", "Favor de Asignar una Compra", "warning");
    // }


  }

  regresar=(evt)=>
  {
    evt.preventDefault();
    this.props.history.push('/app/a_proveedores');
  }

  render() {
     let {submit,movimiento} = this.state;
     console.log('Proveedor---->',this.state)
    return (
      
        <div className="content">
          <Row>
          
            <Col md="11">
            <Form onSubmit={this.handleSubmit}>
              <Card className="card-user">
                <CardHeader>
                 <Row>
                      <Col className="update  text-right">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          disabled={submit}
                        >
                          Guardar
                        </Button>
                        <Button
                          className="btn-round"
                          color="warning"
                          onClick={this.regresar}>
                          Cancelar
                        </Button>
                      </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                <Row>
                  <div className="col-md-3"> 
                                <div className="form-group">
                                    <label className='col-form-label'>Tipo:</label>
                                    <Select
                                        placeholder=""
                                        type="text"
                                        name="tipo"
                                        options={tipos}
                                        value={tipos.filter(option => option.value == movimiento.tipo)}
                                        isDisabled={this.state.action=='save'?false:true}
                                        onChange={ (select) => { this.handleSelectChange(select, 'tipo') } }
                                        clearable={false}

                                    />
                                </div>
                            </div>
                    <div className="col-12 col-md-4 col-lg-3"> 
                    <div className="form-group ">
                    <label  className=" col-form-label">Fecha : </label>
                      <input type="date" className="form-control" 
                        name="fecha"
                        required
                        value={movimiento.fecha}
                        onChange={this.handeInputChange}
                       />
                  </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3"> 
                    <div className="form-group ">
                    <label  className=" col-form-label">Tipo Cambio : </label>
                      <input type="number" className="form-control" 
                        name="tc"
                        required
                        value={movimiento.tc}
                        onChange={this.handeInputChange}
                       />
                  </div>
                  </div>
                  <div className="col-md-3"> 
                    <div className="form-group">
                      <label className='col-form-label'>Moneda:</label>
                      <Select  placeholder="" type="text"  name="moneda" options={monedas}  value={monedas.filter(option => option.value == movimiento.moneda)} isDisabled={true}  onChange={ (select) => { this.handleSelectChange(select, 'moneda') } } clearable={false} />
                    </div>
                  </div>
                  {parseInt(movimiento.tipo)==1&&

                  <div className="form-group col-12 ">
                    <label  className=" col-form-label">Concepto : </label>
                      <input type="text" className="form-control" 
                        name="concepto"
                        required
                        value={movimiento.concepto}
                        onChange={this.handeInputChange}
                       />
                  </div>}
                            
                        
   


                </Row>
                   <Row>
                                    <div className="col-md-4 d-none"> 
                                <div className="form-group">
                                    <label  className=" col-form-label">Forma de Pago:</label>
                                    <Select
                                        placeholder=""
                                        type="text"
                                        name="forma_pago"
                                        options={this.state.formas}
                                        value={this.state.formas.filter(option => option.value == movimiento.forma_pago)}
                                       
                                       onChange={ (select) => { this.handleSelectChange(select, 'forma_pago') } }
                                        clearable={false}

                                    />
                                </div>
                            </div>
                    <div className="col-12 col-md-5 col-lg-5 d-none"> 
                    <div className="form-group ">
                    <label  className=" col-form-label">Anexo : </label>
                      <input type="text" className="form-control" 
                        name="anexo"
                        
                        value={movimiento.anexo}
                        onChange={this.handeInputChange}
                       />
                  </div>
                  </div>
                  {parseInt(movimiento.tipo)==2&&
                   <div className="col-md-3"> 
                    <div className="form-group">
                      <label className='col-form-label'>Concepto:</label>
                      <Select  placeholder="" type="text"  name="concepto" options={conceptos}  value={conceptos.filter(option => option.value == movimiento.concepto)}   onChange={ (select) => { this.handleSelectChange(select, 'concepto') } } clearable={false} />
                    </div>
                  </div>
                }
                {parseInt(movimiento.tipo)==2&&
                  <div className="col-12 col-md-4 col-lg-3"> 
                    <div className="form-group ">
                    <label  className=" col-form-label">Fecha Vencimiento : </label>
                      <input type="date" className="form-control" 
                        name="fecha_vencimiento"
                        required
                        value={movimiento.fecha_vencimiento}
                        onChange={this.handeInputChange}
                       />
                  </div>
                  </div>
                }
                  <div className="form-group col-12 col-md-3 col-lg-3">
                    <label  className=" col-form-label">Total : </label>
                      <input type="number" className="form-control" 
                        name="total"
                        step='0.01'
                        required
                        value={movimiento.total}
                        onChange={this.handeInputChange}
                       />
                  </div>
                </Row>

                <Row className='p-3'>
                            <Col sm='9' md="2" className='p-0'>
                              <label > Proveedor</label>
                            </Col>
                            <Col sm='2' md="2">
                              <div className="form-group row">
                                <div className="col-12">
                                  <input type="text" className="form-control" 
                                  name="codigo_proveedor_id"
                                  required
                                  value={this.state.codigo_proveedor_id}
                                  onChange={this.ChangeCode} 
                                 
                                  onKeyPress={event => {
                                      
                                      if (event.key === 'Enter') {
                                       event.preventDefault();
                                        this.handleClose(event,"/getproveedor/codigo","proveedor_id")
                                      }
                                    }}
                                   />
                                </div>
                              </div>
                            </Col>
                            <Col sm='2' md="1" className='pt-2'>
                                <Button
                                  className="btn btn_catalogos"
                                  color="secondary"
                                  
                                  onClick={ (button) => { this.toggle_modal(14,"Proveedores","proveedor_id") } }>
                                  +
                                </Button>
                            </Col>
                            <Col md="7" className='p-0 mt-md-1 fondo_label'>
                              <label >{this.state.label_proveedor_id} </label>
                            </Col>


                            <Col sm='9' md="2" className='p-0 d-none'>
                              <label >Compra</label>
                            </Col>
                          
                            <Col sm='2' md="2" className="d-none">
                              <div className="form-group row">
                                <div className="col-12">
                                  <input type="text" className="form-control" 
                                  name="codigo_documento_id"
                                  
                                  value={this.state.codigo_documento_id}
                                  onChange={this.ChangeCode} 
                                  onKeyPress={event => {
                                      
                                      if (event.key === 'Enter') {
                                       event.preventDefault();
                                        this.handleClose(event,"/getcompra/codigo","documento_id")
                                      }
                                    }}
                                   />
                                </div>
                              </div>
                            </Col>
                           
                            <Col sm='2' md="1" className='pt-2 d-none'>
                                <Button
                                  className="btn btn_catalogos"
                                  color="secondary"
                                  onClick={ (button) => { this.toggle_modal(19,"Compras","documento_id") } }>
                                  +
                                </Button>
                            </Col>
                           

                            <Col sm='12' md="7" className=' d-none p-0 mt-md-1 fondo_label'>
                              <label >{this.state.label_documento_id} </label>
                            </Col>
                            


                           
                            
                            

                          </Row>
                       


                </CardBody>
              </Card>
              </Form>
            </Col>
          </Row>

          {this.state.modal&&
            <ModalTablas
                    open={this.state.modal}
                    toggle={this.toggle_modal}                  
                    titulo={this.state.titulo}
                    catalogo={this.state.tabla_modal}
                    retorno={this.retorno}
                    campo={this.state.campo_modal}
                    proveedor_id={this.state.movimiento.proveedor_id}
                    filtro={1}
               
                    />
            }
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        auth : state.auth,
        ca_proveedores:state.ca_proveedores
    }
};

export default connect(mapStateToProps, actions)(Proveedor)

