import {api ,request } from './_request';

export const logout = (params) =>
{   
    return (dispatch) =>
    {   
        //Borrar de la base de datos
        api().post('/logout')
        .then(function(response)
        {
            if(response.status === 200)
            {
                //Borrar de la memoria local
                localStorage.removeItem('session_token_portal');
                dispatch({
                    type: 'DESCONECTADO',
                    payload: 'Desconectado'
                })
            }
        });
    }
}

export const checkToken = (params) =>
{
    return (dispatch) =>
    {
        let token = localStorage.getItem('session_token_portal');

        token = JSON.parse(token);

        if(token !== null)
        {
            let AuthorizationToken = token.token_type + " " + token.access_token;

            request.get('api/user',
            {
                responseType: 'json',
                headers: {'Authorization': AuthorizationToken}
            })
            .then(function(response) 
            {
                if(response.status === 200)
                {
                    dispatch(
                    {
                        type: 'CONECTADO',
                        payload: token
                    });
                }
            })
            .catch(function(error) {
                localStorage.removeItem('session_token_portal');
            });
        }
    }
}

export const login = (params) =>
{   
    return (dispatch) =>
    {		
        request.post('/oauth/token', 
        {
            username        : params.email,
            password        : params.password,
            client_id       : 2,
            client_secret   : '3F28cVJ2vQTr1MQyVtYXC8lutFAT1l1XES9Mcysh',
            grant_type      : 'password'
        })
        .then(function(response)
        {
            if(response.status === 200)
            {
                localStorage.setItem('session_token_portal', JSON.stringify(response.data));
                dispatch(
                {
                    type: 'CONECTADO',
                    payload: response.data
                })
            }
        })
        .catch(function(error)
        {
            dispatch(
            {
                type: 'ERROR',
                payload: 'Datos incorrectos.'
            })
        })
    }
}

export const loginCard = (params) =>
{   
    return (dispatch) =>
    {       
       
        localStorage.setItem('session_token_portal', JSON.stringify(params));
        dispatch(
        {
            type: 'CONECTADO',
            payload: params
        })
            
    }
}

export const whoiam = () =>
{
    return (dispatch) => {

        api().get('/user')
        .then(function(response)
        {
            dispatch(
            {
                type: 'AUTH_WHOIAM_SUCCESS',
                payload: response.data
            });
        });

    }
}

export const actualizar_usuario = (usuario) =>
{
    return (dispatch) => {
        
       
            dispatch(
            {
                type: 'AUTH_WHOIAM_SUCCESS',
                payload: usuario
            });
       

    }
};


export const save = (item, callback) => {
    return (dispatch) => {

        dispatch({ type: 'SAVE' });

        api().post('registerUser', item).then(function(response) {

            if(response.status === 200) {

                dispatch({
                    type: 'SAVE_SUCCESS',
                    payload: response.data
                });

                if(typeof callback === 'function') callback(response);

            }
            else{
                dispatch({ type: 'SAVE_FAILURE' });
            }

        })
        .catch(function(error) {
            dispatch({ type: 'SAVE_FAILURE' });
        });

    }
};

export const update = (registro, callback) => {
    return (dispatch) => {

        
        dispatch({ type: 'SAVE' });

        api().put(`updateUser/${registro.id}`, registro)
        .then(function(response)
        {
            if(response.status === 200)
            {
                dispatch({
                    type: 'UPDATE_SUCCESS',
                    payload: response.data
                });
                // console.log('combos action----->', response.data);

                if(typeof callback === 'function') callback(response);
            }
            else
            {
                dispatch({ type: 'SAVE_FAILURE' });
            }
        })
        .catch(function(error) {
            dispatch({ type: 'SAVE_FAILURE' });
        });

    }
};

