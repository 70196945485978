import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Label,FormGroup, Input,InputGroup, InputGroupAddon,Row,Col,
} from 'reactstrap';



import swal from 'sweetalert2';
import * as actions from '../../actions/auth';
import {api} from '../../actions/_request';

import ReactTable from "react-table";
import "react-table/react-table.css";

const filterCaseInsensitive = (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (row[id] !== null){
                return (
                    row[id] !== undefined ?
                        String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase())
                        :
                        true
                );
            }
        };




class TicketModal extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {

            articulo:{
                precio1:'',
                precio2:'',
                precio3:'',
                precio4:'',
                precio5:'',
                precio6:'',
              },
              submit:false,
        };
	}

    handleSubmit=(evt)=>{
    evt.preventDefault();
    let _self = this;
    let {articulo,action} = this.state;
    let save = true;
    _self.setState({
            submit : true
          })


    api().post('/articulo/actualizarprecios', articulo).then(function(response) {

            if(response.status === 200) {

                swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success");
                _self.setState({
                        submit : false
                      });
                _self.props.recargar();
                _self.props.toggle();
            }
            else{
                
            }

        })
        .catch(function(error) {
            swal.fire({
              icon: 'error',
              title: 'Solicitar ayuda a un Administrador'
            })
            _self.setState({
                        submit : false
                      });
        });





  }

 handeInputChange=(event)=>{
    let {articulo} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    articulo[name] = value;
// console.log('articulo handeInputChange---->',articulo)
    this.setState({
        articulo : articulo
    });
  }




    

    componentDidMount()
    {
       
        let self=this;
        api().get(`/getarticuloid/${this.props.articulo_id}`)
          .then(function(response)
          {  
            let articulo = response.data.articulo;
            console.log(articulo)
            self.setState({
                articulo:{
                    id:articulo.id,
                precio1:articulo.precio1,
                precio2:articulo.precio2,
                precio3:articulo.precio3,
                precio4:articulo.precio4,
                precio5:articulo.precio5,
                precio6:articulo.precio6,
              }
            });
           

          });


    }



	render()
	{
      let {submit,articulo} = this.state;
		
		return(

			<Modal isOpen={this.props.open} toggle={this.props.toggle} style={{marginTop: '35px'}} className="default ">
				<form onSubmit={this.handleSubmit}>
                <ModalHeader toggle={this.props.toggle}>Precios Y Costos</ModalHeader>
                <ModalBody>
                
                <Row className='pl-2'> 
                               
                               
                               <Col md="10" className='mb-1'>
                                 <div className="form-group row m-0">
                                    <label htmlFor="txt_registro" className="col-md-4 col-form-label m-0 p-0">Precios Dolares</label>
                                    <div className="col-md-6">
                                      <input type="number" step='0.01' className="form-control" 
                                        name="precio1"
                                        required
                                        value={articulo.precio1}
                                        onChange={this.handeInputChange} />
                                    </div>
                                  </div>
                               </Col>
                               
                               <Col md="10" className='mb-1'>
                                 <div className="form-group row m-0">
                                    <label htmlFor="txt_registro" className="col-md-4 col-form-label m-0 p-0">Precio Pesos</label>
                                    <div className="col-md-6">
                                      <input type="number" step='0.01' className="form-control" 
                                        name="precio2"
                                        required
                                        value={articulo.precio2}
                                        onChange={this.handeInputChange} />
                                    </div>
                                  </div>
                               </Col>
                               
                               <Col md="10" className='mb-1'>
                                 <div className="form-group row m-0">
                                    <label htmlFor="txt_registro" className="col-md-4 col-form-label m-0 p-0">Precio 3</label>
                                    <div className="col-md-6">
                                      <input type="number" step='0.01' className="form-control" 
                                        name="precio3"
                                        required
                                        value={articulo.precio3}
                                        onChange={this.handeInputChange} />
                                    </div>
                                  </div>
                               </Col>
                               
                               <Col md="10" className='mb-1'>
                                 <div className="form-group row m-0">
                                    <label htmlFor="txt_registro" className="col-md-4 col-form-label m-0 p-0">Precio 4</label>
                                    <div className="col-md-6">
                                      <input type="number" step='0.01' className="form-control" 
                                        name="precio4"
                                        required
                                        value={articulo.precio4}
                                        onChange={this.handeInputChange} />
                                    </div>
                                  </div>
                               </Col>
                               
                               <Col md="10" className='mb-1'>
                                 <div className="form-group row m-0">
                                    <label htmlFor="txt_registro" className="col-md-4 col-form-label m-0 p-0">Costo Dolares</label>
                                    <div className="col-md-6">
                                      <input type="number" step='0.01' className="form-control" 
                                        name="precio5"
                                        required
                                        value={articulo.precio5}
                                        onChange={this.handeInputChange} />
                                    </div>
                                  </div>
                               </Col>
                               
                               <Col md="10" className='mb-1'>
                                 <div className="form-group row m-0">
                                    <label htmlFor="txt_registro" className="col-md-4 col-form-label m-0 p-0">Costo Pesos</label>
                                    <div className="col-md-6">
                                      <input type="number" step='0.01' className="form-control" 
                                      name="precio6"
                                      required
                                      value={articulo.precio6}
                                      onChange={this.handeInputChange} />
                                    </div>
                                  </div>
                               </Col>
                               
                            </Row>

                    
                </ModalBody>
                <ModalFooter>
                    
                    	<div>
                    	<Button
                          className="btn"
                          color="primary"
                          type="submit"
                          disabled={submit}
                        >
                          Guardar
                        </Button>
                    		<Button color="secondary" onClick={this.props.toggle}>
		                    	Cerrar
		                    </Button>
                    	</div>
                   
                </ModalFooter>
                </form>
            </Modal>
		);
	}
}

function mapStateToProps(state, ownProps) {
    return {
         auth : state.auth,
    }
};

export default connect(mapStateToProps, actions)(TicketModal)