import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/auth';
import {api } from '../../actions/_request';
import swal from 'sweetalert2';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

import ModalTablas from '../../components/catalogos/modal_tablas';
  
class User extends Component {
  constructor(props){
    super(props);

    this.state = {
      
      usuario:{
        id : null,
        name:'',
        email:'',
        rol:2,
        rfc:'',
        password:'',
        no_empleado:'',

      },
      action : 'save',
      submit:false,

    }
      this.handeInputChange = this.handeInputChange.bind(this);
      this.handleSelectChange = this.handleSelectChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
  }
  retorno=(objeto,campo,vacio=0,valor="")=>{
        let label="";
        let codigo="";
        let id="";
        let {usuario} = this.state;
        let self =this;
        if(vacio==0){
          label=objeto.label;
          codigo=objeto.folio;
          id=objeto.id;
        }
        console.log('retorno----->',objeto)
        console.log('campo----->',campo)

        self.setState({
            usuario: {
                ...this.state.usuario,
                [campo]: id
            },         
            ['label_'+campo]:label,
            ['codigo_'+campo]:codigo,
            modal:false,         
        })
    }
  toggle_modal=(index=0,catalogo="",campo="")=>{  

        this.setState({
            modal : !this.state.modal,
            titulo:catalogo,
            tabla_modal:index,
            campo_modal:campo,         
        })
    }
  componentDidMount(){
    

    let _self = this;
    let {usuario} = this.state;
    let {params} = this.props.match;
    if(params.id) {
      api().get("/getUserByid/"+params.id)
      .then(function(response){
        if(response.status === 200){
          usuario = response.data;
          _self.setState({
            usuario : usuario,
            action : 'update',
            codigo_folio_id:usuario.data_folio.folio
          })
        }

      });
    }

  }

  handleSubmit(evt){

    evt.preventDefault();
    let _self = this;
    let {usuario,action} = this.state;
    let save = true;
    // console.log('submit------>',usuario);
    var patt = new RegExp("[a-zA-Z0-9]?[a-zA-Z0-9]{11}[a-zA-Z0-9]$", "i");
     _self.setState({
            submit : true
          })

    let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailRegex.test(usuario.email)) {
           swal.fire("Alerta!", 'Correo No valido', "warning");
           _self.setState({
                        submit : false
                      });
        }else{
           if(usuario.rfc.length>11&&usuario.rfc.search(patt)==0){
              this.props[action](usuario, (response) => {
                if(response.status){
                  if(response.data.estado){
                    console.log('actualizar')
                    swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success");
                    if(action=='update'&& parseInt(usuario.id)== parseInt(_self.props.auth.user.id))
                     _self.props.actualizar_usuario(response.data.usuario);
                    _self.props.history.push('/app/usuarios');

                  }else{
                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    _self.setState({
                        submit : false
                      });
                  }
                }else{
                  console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
                }
              });

           }else{
            swal.fire("Oops..!", "Por favor Verificar el RFC ", "warning");
            _self.setState({
                        submit : false
                      });
           }
        }

  }
  handeInputChange(event){
    let {usuario} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    usuario[name] = value;
    if(this.state.action=='save'&&name=='rfc'){
      usuario.password=value
    }
    this.setState({
        usuario : usuario
    });
  }

  handleSelectChange(select, name ) {
      let {usuario} = this.state;
      let value=null;
      value = select === null ? null : select.target.value;
      usuario[name] = value;
      console.log('value---->', value);
      console.log('usuario---->',usuario);
      console.log('name---->',name);
      this.setState({usuario : usuario});
    }

    regresar=(evt)=>
    {
      evt.preventDefault();
      
      this.props.history.push('/app/usuarios');
    }
  render() {
     let {usuario,submit} = this.state;
     console.log('state---->',this.state)
    return (
      
        <div className="content">
          <Row>
          <Col md="2"></Col>
            <Col md="8">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">Perfil</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col className="pr-1" md="8">
                        <FormGroup>
                          <label>Nombre</label>
                          <Input
                            placeholder="Nombre Completo"
                            type="text"
                            name="name"
                            required
                            value={usuario.name}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-1" md="4">
                        <FormGroup>
                          <label># Empleado</label>
                          <Input
                            placeholder="#000"
                            type="number"
                            name="no_empleado"
                            required
                            value={usuario.no_empleado}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">
                            Correo
                          </label>
                          <Input 
                          placeholder="Email" 
                          type="email"
                          name="email"
                          required
                          value={usuario.email}
                          onChange={this.handeInputChange} />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>RFC</label>
                          <Input
                            placeholder="rfc"
                            type="text"
                            name="rfc"
                            required
                            value={usuario.rfc}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Contraseña</label>
                          <Input
                            type="text"
                            name="password"
                            required
                            disabled={this.state.action=='save'?true:false}
                            value={usuario.password}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Rol</label>
                          <select class="form-control" id="exampleFormControlSelect1"
                               value={usuario.rol} 
                               name='rol'
                               onChange={(e) => this.handleSelectChange(e,'rol')}>
                            <option value={1}>Administrador</option>
                            <option value={2}>Empleado</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Telefono</label>
                          <Input
                            placeholder="telefono"
                            type="text"
                            name="telefono"
                            required
                            value={usuario.telefono}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <div className="col-md-4"> 
                            <div className="form-group">
                                <label className="d-block">Folio </label>
                                <Input
                                required
                                placeholder=""
                                type="text"
                                name="codigo_folio_id"
                                value={this.state.codigo_folio_id}
                                onChange={ this.ChangeCode}
                                onBlur={this.handleClose}
                                className="input_button_sm"
                                readOnly
                              
                                />
                                <Button className='m-0' onClick={ (button) => { this.toggle_modal(26,"Folios","folio_id") } }>...</Button>
                            </div>
                        </div>
                    </Row>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          disabled={submit}
                        >
                          Guardar
                        </Button>
                        <Button
                          className="btn-round"
                          color="warning"
                          onClick={this.regresar}>
                          Cancelar
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.modal&&
            <ModalTablas
                    open={this.state.modal}
                    toggle={this.toggle_modal}                  
                    titulo={this.state.titulo}
                    catalogo={this.state.tabla_modal}
                    retorno={this.retorno}
                    campo={this.state.campo_modal}
                />
          }
          
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        auth : state.auth,
    }
};

export default connect(mapStateToProps, actions)(User)

