import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import "react-table/react-table.css";

import {API_URL} from '../../actions/_request';
import {api} from '../../actions/_request';
import _ from "lodash";

import * as actions from '../../actions/user';
import swal from 'sweetalert2';
import moment from 'moment';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,Badge, Table 
} from "reactstrap";

class Users2 extends Component {
  render() {
    return (
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="card-user">
                <CardBody>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    );
  }
}

const requestData = (pageSize, page, sorted, filtered) => {    
    return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        let filteredData ;
       
        var proveedor='';
        var id='';
        var concepto ='';
        var fecha='';
        var tipo=0;
        var take= pageSize;
        var skip=pageSize * page;
        var total=1; 
        var patt = new RegExp("(si|s)", "i"); 
        for (var i = filtered.length - 1; i >= 0; i--) {
          switch(filtered[i].id)
          {

            case "proveedor_id":
              proveedor=filtered[i].value;
              console.log('proveedor--->',filtered[i].value);
            break;
            case "concepto":
              concepto=filtered[i].value;
              console.log('concepto--->',filtered[i].value);
            break;
            case "fecha":
              fecha=filtered[i].value;
              console.log('fecha--->',filtered[i].value);
            break;
            case "id":
              id=filtered[i].value;
              console.log('id--->',filtered[i].value);
            break; 
             case "tipo":
              tipo=filtered[i].value;
              console.log('tipo--->',filtered[i].value);
            break; 
          }
        }
              
              api().get(`/getca_proveedores?tipo=${tipo}&fecha=${fecha}&concepto=${concepto}&proveedor=${proveedor}&id=${id}&take=${take}&skip=${skip}`)
              .then(function(response)
              {
                if(response.status === 200)
                {
                  filteredData= response.data.datos;
                  total= response.data.total; 
                  // You can also use the sorting in your request, but again, you are responsible for applying it.
                  const sortedData = _.orderBy(
                    filteredData,
                    sorted.map(sort => {
                      return row => {
                        if (row[sort.id] === null || row[sort.id] === undefined) {
                          return -Infinity;
                        }
                        return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                      };
                    }),
                    sorted.map(d => (d.desc ? "desc" : "asc"))
                  );
                  const res = {
                    rows: sortedData,
                    pages: Math.ceil(total / pageSize)
                  };
                  // Here we'll simulate a server response with 500ms of delay.
                  //setTimeout(() => resolve(res), 1000);
                  //console.log(res);
                  resolve(res)
                }
              });   
        
    });
};



class Articulos extends Component {

  constructor(props) {

        super(props);

        this.state = {
             pages: null,
             loading: true,
        };

        this.fetchData = this.fetchData.bind(this);
        this.table = React.createRef();
    }


     fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
      // console.log('datos--->',res.rows);
       // this.props.get(res.rows);
    });
  }


  verificar=(id)=>{
let self=this;
      swal.queue([{
        title: 'Verificar Movimiento ',
        text: "Estas seguro ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return api().get('/verificar_mov_proveedor/'+id)
            .then(function(response)
            {    
                // console.log('response-->',response)    
                if(response.status === 200)
                {
                  if(response.data.estado==200){
                      swal.insertQueueStep({
                        type: 'success',
                        title:  response.data.mensaje,
                      })
                      self.fetchData(self.table.current.state);
                  }else{
                    swal.insertQueueStep({
                      type: 'warning',
                      title:  response.data.mensaje,
                    })
                  }
                }
                  
            })
            .catch((error) => {
              console.log('error---->',error)
              swal.insertQueueStep({
                type: 'error',
                title: 'Solicitar ayuda a un Administrador',
              })
            })
        }
      }])
  }

  cancelar=(id)=>{
let self=this;
      swal.queue([{
        title: 'Cancelar Movimiento ',
        text: "Estas seguro ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return api().get('/cancelar_mov_proveedor/'+id)
            .then(function(response)
            {    
                // console.log('response-->',response)    
                if(response.status === 200)
                {
                  if(response.data.estado==200){
                      swal.insertQueueStep({
                        type: 'success',
                        title:  response.data.mensaje,
                      })
                      self.fetchData(self.table.current.state);
                  }else{
                    swal.insertQueueStep({
                      type: 'warning',
                      title:  response.data.mensaje,
                    })
                  }
                }
                  
            })
            .catch((error) => {
              console.log('error---->',error)
              swal.insertQueueStep({
                type: 'error',
                title: 'Solicitar ayuda a un Administrador',
              })
            })
        }
      }])
  }


  render() {
    const {  pages, loading} = this.state;
    return (
      <div className="content">
        <Row>
          <Col >
            <Card>
              <CardHeader>
              <Row>
              <Col xs={6}>
            
              </Col>
              <Col className='text-right' xs={6}>
              <Link to="/app/a_proveedores/nuevo">
                <Button color="primary" className="mb-2" >
                    Nuevo
                </Button>
              </Link>
              </Col>
              </Row>
              </CardHeader>
              <CardBody>
              <ReactTable
                             getTrProps={(state, rowInfo, column) => {
                                 
                                  if(rowInfo === undefined){
                                         
                                          return{

                                          }
                                        }else{
                                           return{
                                            onDoubleClick: (e) => {

                                              if(parseInt(rowInfo.original.verificado)==0){
                                                this.props.history.push('/app/a_proveedores/editar/'+rowInfo.original.id);
                                              }
                                              
                                                  
                                             
                                              
                                            }
                                        } 
                                  
                                    }
                              }}
                              
                              
                              className = "-striped -highlight"
                              columns   = {[
                                {Header: '#', accessor: 'id',maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Tipo', accessor: 'tipo',maxWidth:150,minWidth:150,style: { textAlign: 'center'},

                                  Filter: ({ filter, onChange }) =>
                                    <select onChange={event => onChange(event.target.value)}   value={filter ? filter.value : "0"}>
                                      <option value="0">Todos</option>
                                      <option value="1">Abonos</option>
                                      <option value="2">Cargos</option>
                                      
                                    </select>
                              },
                                {Header: 'Proveedor', accessor: 'proveedor_id'},
                                {Header: 'concepto', accessor: 'concepto'},
                                {Header: 'total', accessor: 'total',maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                               
                               
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                     maxWidth:250,minWidth:250,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-center">
                                              {
                                                  (parseInt(row.original.verificado)==0 )  &&
                                                <Button
                                                 color="success" 
                                                 className="btn-sm" onClick={(evt)=>{this.props.history.push('/app/a_proveedores/editar/'+row.original.id); }}>
                                                    Editar
                                                </Button>
                                              }

                                                {
                                                  (parseInt(row.original.verificado)==0 )  &&
                                                 <Button
                                                 color="info" 
                                                 className="btn-sm"  onClick={(evt)=>{this.verificar(row.original.id) }}>
                                                    Verificar
                                                </Button>
                                              }

                                              {
                                                  (parseInt(row.original.cancelado)==0&&parseInt(row.original.verificado)==1 )  &&
                                                 <Button
                                                 color="danger" 
                                                 className="btn-sm"  onClick={(evt)=>{this.cancelar(row.original.id) }}>
                                                    Cancelar
                                                </Button>
                                              }
                                                
                                            </div>
                                        )
                                    }
                                }
                              ]}
                              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                              data      = {this.state.data}
                              pages={pages} // Display the total number of pages
                              loading={loading} // Display the loading overlay when we need it
                              onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                              filterable
                               ref={this.table}
                              defaultPageSize={25} 
                              
                              
                             
    
                            />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
    return {
        user : state.user,
        auth:state.auth,
    }
};

export default connect(mapStateToProps, actions)(Articulos)
