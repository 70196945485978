import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Label,FormGroup, Input,InputGroup, InputGroupAddon
} from 'reactstrap';
// reactstrap components
import {
  
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
 
  Form,
  
  Row,
  Col,
  CardText
} from "reactstrap";
import classnames from 'classnames';
import _ from "lodash";
import Spinner from 'react-spinkit';

import swal from 'sweetalert2';

import * as actions from '../../actions/categorias';

import Select from 'react-select';

import {api, API_URL} from '../../actions/_request';

import ReactTable from "react-table";
import "react-table/react-table.css";

const tipos = [
    {label: 'Productos', value: 1},
];
const niveles = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
];


const requestData = (pageSize, page, sorted, filtered,tipo,nivel) => {    
    return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        let filteredData ;
       
        var nombre='';
        var id='';
        var take= pageSize;
        var skip=pageSize * page;
        var total=1; 
        
        for (var i = filtered.length - 1; i >= 0; i--) {
          switch(filtered[i].id)
          {
            case "nombre":
              nombre=filtered[i].value;
              console.log('nombre--->',filtered[i].value);
              break;
            case "id":
              id=filtered[i].value;
              console.log('id--->',filtered[i].value);
              break; 
          }
        }
              
              api().get(`/getcategorias?tipo=${tipo}&nivel=${nivel}&nombre=${nombre}&id=${id}&take=${take}&skip=${skip}`)
              .then(function(response)
              {
                if(response.status === 200)
                {
                  filteredData= response.data.datos;
                  total= response.data.total; 
                  // You can also use the sorting in your request, but again, you are responsible for applying it.
                  const sortedData = _.orderBy(
                    filteredData,
                    sorted.map(sort => {
                      return row => {
                        if (row[sort.id] === null || row[sort.id] === undefined) {
                          return -Infinity;
                        }
                        return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                      };
                    }),
                    sorted.map(d => (d.desc ? "desc" : "asc"))
                  );
                  const res = {
                    rows: sortedData,
                    pages: Math.ceil(total / pageSize)
                  };
                  // Here we'll simulate a server response with 500ms of delay.
                  //setTimeout(() => resolve(res), 1000);
                  //console.log(res);
                  resolve(res)
                }
              });   
        
    });
};


class ModalCategorias extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
            tipo:{label: 'Productos', value: 1},
            nivel:{label: '1', value: 1},
            action:'save',
            categoria:{
                tipo:1,
                nivel:1,
                nombre:'',
                id:'',
            },
            submit:false,
            pages: null,
            loading: true,
		
        };
        this.handleSelectChange= this.handleSelectChange.bind(this);
        this.regresar= this.regresar.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.editar= this.editar.bind(this);
        this.handeInputChange= this.handeInputChange.bind(this);
        this.handleSubmit= this.handleSubmit.bind(this);
        this.table = React.createRef();
	}

    handeInputChange(event){
    let {categoria} = this.state;

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    categoria[name] = value;
// console.log('categoria handeInputChange---->',categoria)
    this.setState({
        categoria : categoria
    });
  }


     fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered,
      this.state.tipo.value,
      this.state.nivel.value,
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
      // console.log('datos--->',res.rows);
       // this.props.get(res.rows);
    });
  }

    regresar() {
             this.setState({
                action:'save',
                categoria: {
                    ...this.state.categoria,
                     nombre:'',
                     id:'',
                }
            });
        
    }

    editar(row){

        console.log('Row---->',row)
        this.setState({
                action:'update',
                categoria: row,
            });

    }


    handleSelectChange(select, name) {

        
        const value = select === null ? null : select.value;
        console.log('value---->',value)
        console.log('name---->',name)
             this.setState({
                [name]: select,
                categoria: {
                    ...this.state.categoria,
                    [name]: value
                }
            }, () => {
           this.fetchData(this.table.current.state)
        });
        
    }

     handleSubmit(evt){
    evt.preventDefault();
    let _self = this;
    let {categoria,action} = this.state;
    let save = true;
    _self.setState({
            submit : true
          })

    this.props.saveupdate(categoria, (response) => {
                if(response.status){
                  if(response.data.estado){
                    // console.log('actualizar')
                    swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success");
                    
                     _self.setState({
                      categoria: {
                            ..._self.state.categoria,
                             nombre:'',
                             id:'',
                        },
                      action : 'save',
                      submit : false
                    }, () => {
                           _self.fetchData(_self.table.current.state)
                        });
                    

                  }else{
                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    _self.setState({
                        submit : false
                      });
                  }
                }else{
                  console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
                }
              });



  }


	render()
	{

        console.log('estate Modal----->',this.state);
        let {submit,categoria,pages, loading}= this.state;
		
		return(
            /*charCode   es para poner texto en el boton para cerrar  pero aqui se uso para eliminarlo*/
			<Modal isOpen={this.props.open} toggle={this.props.toggle} charCode="X" className="default modal-lg ">				
                <ModalHeader >Categorias</ModalHeader>
                <ModalBody> 
                <div className="content">
                <Row>
                <div className="col-md-3"> 
                    <div className="form-group">
                        <label >Tipo *:</label>
                        <Select
                            placeholder=""
                            type="text"
                            name="tipo"
                            options={tipos}
                            value={this.state.tipo}
                            onChange={ (select) => { this.handleSelectChange(select, 'tipo') } }
                            clearable={false}

                        />
                    </div>
                </div>
                <div className="col-md-1"> 
                    <div className="form-group">
                        <label >Nivel *:</label>
                        <Select
                            placeholder=""
                            type="text"
                            name="nivel"
                            options={niveles}
                            value={this.state.nivel}
                            onChange={ (select) => { this.handleSelectChange(select, 'nivel') } }
                            clearable={false}

                        />
                    </div>
                </div>
                </Row>
                <Row>
                <div className="col-md-1"> 
                    <div className="form-group">
                        <label >Codigo *:</label>
                        <input type="text" className="form-control" 
                        name="nombre"
                        
                        readOnly 
                        value={categoria.id}
                        />
                    </div>
                </div>
                <div className="col-md-5"> 
                    <div className="form-group">
                        <label >Descripción *:</label>
                        <input type="text" className="form-control" 
                        name="nombre"
                        required
                        value={categoria.nombre}
                        onChange={this.handeInputChange} />
                    
                    </div>
                </div>
                <div className="col-md-5"> 
                    <div className="form-group pt-3">
                     <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          disabled={submit}
                          onClick={this.handleSubmit}>
                          Guardar
                        </Button>
                        {this.state.action =='update'&&

                        <Button
                          className="btn-round"
                          color="warning"
                          onClick={this.regresar}>
                          Cancelar
                        </Button>
                         }
                        
                    
                    </div>
                </div>
                </Row>
                <Row>
                <div className='col-12'>
                <ReactTable
                             getTrProps={(state, rowInfo, column) => {
                                 
                                  if(rowInfo === undefined){
                                         
                                          return{

                                          }
                                        }else{
                                           return{
                                            onDoubleClick: (e) => {
                                                    this.editar(rowInfo.original); 
                                            }
                                        } 
                                  
                                    }
                              }}
                              
                              
                              className = "-striped -highlight"
                              columns   = {[
                                {Header: '#',filterable: false, accessor: 'id',maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Nombre', accessor: 'nombre'},
                               
                               
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                     maxWidth:150,minWidth:150,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-center">
                                                <Button
                                                 color="success" 
                                                 className="btn-sm" onClick={(evt)=>{this.editar(row.original);  }}>
                                                    Editar
                                                </Button>
                                               
                                            </div>
                                        )
                                    }
                                }
                              ]}
                              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                              data      = {this.state.data}
                              pages={pages} // Display the total number of pages
                              loading={loading} // Display the loading overlay when we need it
                              onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                              filterable
                              ref={this.table}
                              defaultPageSize={5} 
                            />
                    
                </div>
                </Row>
                </div>    
                </ModalBody>
               <ModalFooter>
                   
                       
                        <div>                            
                            <Button color="secondary" onClick={this.props.toggle}>
                                Cerrar
                            </Button>
                        </div>
                    
                </ModalFooter>               
            </Modal>
		);
	}
}

function mapStateToProps(state, ownProps) {
    return {
        ticket : state.ticket,
    }
};

export default connect(mapStateToProps, actions)(ModalCategorias)