import { api } from './_request';

 export const get = (data) => {
 	return (dispatch) => {

 		
				dispatch({
				type: 'LIST',
					payload: data
 				});
			 		

 	}
 };

export const save = (item, callback) => {
	return (dispatch) => {

		dispatch({ type: 'SAVE' });

		api().post('folio', item).then(function(response) {

			if(response.status === 200) {

				dispatch({
					type: 'SAVE_SUCCESS',
					payload: response.data
				});

				if(typeof callback === 'function') callback(response.data);

			}
			else{
				dispatch({ type: 'SAVE_FAILURE' });
			}

		})
		.catch(function(error) {
			dispatch({ type: 'SAVE_FAILURE' });
		});

	}
};

export const update = (item, callback) => {
	return (dispatch) => {

        
		dispatch({ type: 'SAVE' });

		api().post(`folio/${item.id}`, item)
		.then(function(response)
		{
			if(response.status === 200)
			{
				dispatch({
					type: 'UPDATE_SUCCESS',
					payload: response.data
				});
				// console.log('folio action----->', response.data);

				if(typeof callback === 'function') callback(response.data);
			}
			else
			{
				dispatch({ type: 'SAVE_FAILURE' });
			}
		})
		.catch(function(error) {
			dispatch({ type: 'SAVE_FAILURE' });
		});

	}
};