import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle , Button} from 'reactstrap';

import ReactTable from "react-table";
import "react-table/react-table.css";


import * as actions from '../../actions/user';
import {api, API_URL} from '../../actions/_request';
import _ from "lodash";

const requestData = (pageSize, page, sorted, filtered,proveedor_id,filtro) => {    
    return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        let filteredData ;
       
        var concepto='';
        var factura='';
        var proveedor='';
        var almacen='';
        var take= pageSize;
        var skip=pageSize * page;
        var total=1; 
        var patt = new RegExp("(si|s)", "i"); 
        for (var i = filtered.length - 1; i >= 0; i--) {
          switch(filtered[i].id)
          {
                       
            case "concepto":
              concepto=filtered[i].value;
              // console.log('concepto--->',filtered[i].value);
              break;
            case "factura":
              factura=filtered[i].value;
              // console.log('factura--->',filtered[i].value);
              break; 
            case "label_proveedor_id":
              proveedor=filtered[i].value;
              // console.log('factura--->',filtered[i].value);
              break;
            case "label_almacen_id":
              almacen=filtered[i].value;
              // console.log('factura--->',filtered[i].value);
              break;
          }
        }
              
              api().get(`/getcompras?concepto=${concepto}&factura=${factura}&almacen=${almacen}&proveedor=${proveedor}&proveedor_id=${proveedor_id}&filtro=${filtro}&take=${take}&skip=${skip}`)
              .then(function(response)
              {
                if(response.status === 200)
                {
                  filteredData= response.data.datos;
                  total= response.data.total; 
                  // You can also use the sorting in your request, but again, you are responsible for applying it.
                  const sortedData = _.orderBy(
                    filteredData,
                    sorted.map(sort => {
                      return row => {
                        if (row[sort.id] === null || row[sort.id] === undefined) {
                          return -Infinity;
                        }
                        return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                      };
                    }),
                    sorted.map(d => (d.desc ? "desc" : "asc"))
                  );
                  const res = {
                    rows: sortedData,
                    pages: Math.ceil(total / pageSize)
                  };
                  // Here we'll simulate a server response with 500ms of delay.
                  //setTimeout(() => resolve(res), 1000);
                  //console.log(res);
                  resolve(res)
                }
              });   
        
    });
};
class ProductosServicios extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          modal       : false,
          prospectoid : null,
          title       : "",
          data:[],
          pages: null,
          loading: true
        };
        this.toggle 			= this.toggle.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.seleccion = this.seleccion.bind(this);
    }
    seleccion(renglon){
       let retorno ={};
       retorno.id=renglon.id;
       retorno.label=renglon.concepto;
       retorno.codigo=renglon.factura;
       this.props.retorno(retorno);

    }
    fetchData(state, instance) {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        requestData(
          state.pageSize,
          state.page,
          state.sorted,
          state.filtered,
           this.state.proveedor_id,
          this.state.filtro
        ).then(res => {
          // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
          this.setState({
            data: res.rows,
            pages: res.pages,
            loading: false
          });
        });
    }
    toggle(evt, prospectoid = null, title = "Agregar Usuario"){
        let {modal} = this.state;
        this.setState({
          modal       : !modal,
          prospectoid : prospectoid,
          title       : title,
        });
    }
    componentDidMount() {
      

      let bandera_filtro=this.props.filtro;
      let proveedor_id=this.props.proveedor_id;
      if (typeof bandera_filtro === 'undefined') {
        bandera_filtro=0;
      }
      if (typeof proveedor_id === 'undefined') {
        proveedor_id='';
      }
      this.setState({
        filtro : bandera_filtro,
        proveedor_id:proveedor_id
        });
      console.log('filtro-*---->',bandera_filtro)
        // let self = this;
        // api().get('/sat/productos_servicios/1')
        // .then(function(response)
        // {
        // if(response.status === 200)
        // {
        // self.setState({
        // data : response.data
        // });
        // }
        // });
    }
    render() {
      const { data, pages, loading } = this.state;
      return(
        <div className="view">            
          <Card>
            <CardBody>                        
              <ReactTable
                getTrProps={(state, rowInfo, column) => {                                  
                    if(rowInfo === undefined){                                          
                      return{}
                    }else{
                      return{onDoubleClick: (evt)=>this.seleccion(rowInfo.original)}                                   
                    }
                  }
                }
                className = "-striped -highlight"
                columns   = {[
                  {Header: '#', accessor: 'factura',maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                  {Header: 'Concepto', accessor: 'concepto'},
                  {Header: 'proveedor', accessor: 'label_proveedor_id'},
     
                ]}
                manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                data={data}
                pages={pages} // Display the total number of pages
                loading={loading} // Display the loading overlay when we need it
                onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                filterable
                pageSizeOptions= {[5]}
                defaultPageSize={5} 
              />
            </CardBody>
          </Card>           
        </div>
      );
    }
};

function mapStateToProps(state, ownProps) {
    return {
        user : state.user,
    }
};

export default connect(mapStateToProps, actions)(ProductosServicios)