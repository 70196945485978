import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle , Button} from 'reactstrap';

import ReactTable from "react-table";
import "react-table/react-table.css";



import * as actions from '../../actions/user';
import {api, API_URL} from '../../actions/_request';

const filterCaseInsensitive = (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (row[id] !== null){
                return (
                    row[id] !== undefined ?
                        String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase())
                        :
                        true
                );
            }
        };
class Monedas extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            modal       : false,
            prospectoid : null,
            title       : "",
            data:[],
        };
        this.toggle 			= this.toggle.bind(this);

    }

   toggle(evt, prospectoid = null, title = "Agregar Usuario")
    {
        let {modal} = this.state;

        this.setState({
            modal       : !modal,
            prospectoid : prospectoid,
            title       : title,
            
        });
    }

    componentDidMount() {
      let self = this;
      api().get('/sat/monedas/1')
      .then(function(response)
      {
        if(response.status === 200)
        {
          self.setState({
            data : response.data
          });
        }
      });
    }

    render() {

        return(
            <div className="view">            
                    <Card>
                        <CardBody>                        
                            <ReactTable
                              getTrProps={(state, rowInfo, column) => {                                  
                                  if(rowInfo === undefined){                                          
                                          return{
                                          }
                                        }else{
                                           return{
                                            onDoubleClick: (evt)=>this.toggle(evt, rowInfo.original.value, 'Editar Usuario')
                                        }                                   
                                    }
                              }}
                              data      = {this.state.data}
                              className = "-striped -highlight"
                              columns   = {[
                               
                                {Header: 'Codigo', accessor: 'codigo',maxWidth:100,minWidth:100},
                                {Header: 'Descripcion', accessor: 'descripcion' ,minWidth:1000},                      
                              
                              ]}
                              filterable 
                             pageSizeOptions= {[5]}  defaultPageSize={5} 
                              
                               defaultFilterMethod={filterCaseInsensitive}
                             
                              
                             
                            />
                        </CardBody>
                    </Card>
               
               
                 

            </div>
        );
    }

};

function mapStateToProps(state, ownProps) {
    return {
        user : state.user,
    }
};

export default connect(mapStateToProps, actions)(Monedas)