import React from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent, TabPane, Nav, NavItem, NavLink, CardText,Label
} from "reactstrap";
import classnames from 'classnames';

import ReactTable from "react-table";
import "react-table/react-table.css";


import * as actions from '../../actions/clientes';
// import Modal from "./Modal";
import _ from "lodash";
import {api} from '../../actions/_request';

import Spinner from 'react-spinkit';

import swal from 'sweetalert2'
import moment from 'moment';
import Select from 'react-select';



import ModalTablas from '../../components/catalogos/modal_tablas';

 const monedas = [
    {label: 'MXN', value: 'MXN'},
    {label: 'USD', value:'USD'},
];
const tipos = [
    {label: 'Abono', value: 1},
    {label: 'Cargo', value:2},
];
// 8410

const SubmitLoad = () => (
    <div className="p-2">
        <Spinner name="ball-clip-rotate-multiple" color="#b5271f" />
    </div>
);



   
class EmbarqueModal extends React.Component
{
     constructor(props)
    {
        super(props)

        this.state = {
            movimiento: {
               
                cliente_id:0,
                movimiento_id:0,
                factura_id:0,
                importe:'0.00',
                descuento:'0.00',
                subtotal:'0.00',
                ieps:'0.00',
                impuestos:'0.00',
                total:'0.00',
                fecha: moment().format('Y-MM-DD'),
                concepto:'',
                referencia:'',
                tipo:1,
                forma_pago:'01',
                rfc_ordenante:'',
                nombre_banco_extranjero:'',
                cuenta_ordenante:'',
                rfc_banco_beneficiario:'',
                cuenta_beneficiario:'',
                tipo_cadena_pago:'',
                correo:'',
                certificado_pago:'',
                cadena_pago:'',
                sello_pago:'',
                tipo_movimiento:2,
                tipo_cambio:0,
                documento_id:0,
                             
            },
            factura:[],
            subtotal_documento:"$0.00",
            impuesto_documento:"$0.00",
            saldo_documento:"0.00",
            saldo_proveedor:"$0.00",
            saldo_original:"0.00",
            action: 'save',
            activeTab: '1',          
            /*variables para el modal de tablas*/
            modal:false,
            titulo:'',
            tabla_modal:0,
            campo_modal:'',
            /*variables para los campos de busqueda de codigos*/
            proveedor_codigo:'',
            proveedor_descripcion:"",
            factura_codigo:'',
            factura_descripcion:"",
            movimiento_codigo:'',
            movimiento_descripcion:"",
            naturaleza:'',
            formas:[],

        
           
        };

     
                 
    }
   
  componentDidMount(){
    let _self = this;
    let {movimiento} = this.state;
    let {params} = this.props.match;
      let self = this;
      api().get(`/formulario_abono`)
        .then(function(response)
        {
            self.setState({
                formas : response.data.formas,
                movimiento: {
                  ...self.state.movimiento,
                  tipo_cambio: response.data.cambio,
                  cuenta_beneficiario:response.data.banco.clave,
                   rfc_banco_beneficiario:response.data.banco.rfc,  
                },
                
            });

            if(params.id) {
              api().get("/get_ca_cliente/"+params.id)
              .then(function(response){
                if(response.status === 200){
                  let data = response.data;
                  _self.setState({
                    codigo_cliente_id:data.cliente.codigo,
                    label_cliente_id:data.cliente.nombre,
                    codigo_documento_id:data.factura.folio,
                    label_documento_id:'',
                    saldo_documento:data.factura.saldo,
                    saldo_original:data.factura.total,
                    subtotal_documento:data.factura.subtotal,
                    impuesto_documento:data.factura.impuesto,
                    movimiento : data.movimientoca,
                    factura:data.factura,
                    action : 'update'
                  })
                }
              });
            }
           
        })
  }

  

  ChangeCode=(event)=> {
     
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
     
        this.setState({
            [name] : value
        });
    }

    toggle_modal=(index=0,catalogo="",campo="")=>{  

        this.setState({
            modal : !this.state.modal,
            titulo:catalogo,
            tabla_modal:index,
            campo_modal:campo,         
        })
    }


    handleClose =(event,tabla,campo)=> {

        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
        let self =this;
        if(value!=""){
            api().get(`${tabla}/${value}`)
            .then(function(response)
            {                  
                if(response.status === 200)
                { 
                    let vaciar=0;
                     if(Object.keys(response.data).length > 0){
                       
                       
                     }  else{
                         vaciar=1
                         swal.fire("Oops..", "No Existe el Codigo");
                     }
                     self.retorno(response.data,campo,vaciar,value);        
                   
                }

            });
        }else{
            self.retorno([],campo,1,value); 
        }
            

   }


    retorno=(objeto,campo,vacio=0,valor="")=>{
        let label="";
        let codigo="";
        let id="";
        let {movimiento} = this.state;
        let self =this;
        if(vacio==0){
          label=objeto.label;
          codigo=objeto.codigo;
          id=objeto.id;
        }




console.log('objeto-->',objeto)

         if(campo=='documento_id'){
          self.setState({
                        saldo_documento:objeto.saldo,
                        saldo_original:objeto.total,
                        subtotal_documento:objeto.subtotal,
                        impuesto_documento:objeto.impuesto,
                        factura:objeto,

            movimiento: {
                ...this.state.movimiento,
                [campo]: objeto.value,
                moneda:objeto.moneda,
                subtotal :objeto.subtotal_numero,
                importe :objeto.subtotal_numero,
                impuestos :objeto.impuesto_numero,
                total :objeto.saldo_numero,
            },         
            ['label_'+campo]:label,
            ['codigo_'+campo]:codigo,
            modal:false,         
        })
        }else{
          self.setState({
            movimiento: {
                ...this.state.movimiento,
                [campo]: id,
                moneda:objeto.factura_moneda,
            },         
            ['label_'+campo]:objeto.cliente,
            ['codigo_'+campo]:codigo,
            modal:false,         
        })
        }






    }


     handleSelectChange(select, name) {
        const value = select === null ? null : select.value;
        console.log('select--->',select)
        this.setState({
            movimiento: {
                ...this.state.movimiento,
                [name]: value
            }
        });
    }

    

   
handleInputChange=(event)=> {
        let {movimiento,factura} = this.state;
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;

        movimiento[name] = value;
        if(name=='total')
        {
          let factor=factura.factor_impuesto?factura.factor_impuesto:0;
          let uno= parseFloat( movimiento.total)/(factor+100);
          

          movimiento.impuestos=parseFloat( Number.parseFloat(uno*factor).toFixed(2));
          movimiento.subtotal=parseFloat( Number.parseFloat(uno*100).toFixed(2));

          // console.log("factor---->", factor )
          // console.log("impuestos---->",parseFloat( movimiento.impuestos ))
          // console.log("subtotal---->",parseFloat( movimiento.subtotal ))
          // console.log("total---->",parseFloat( movimiento.total ))
        }
     
        this.setState({
            movimiento : movimiento
        });
    }
   

   
   
handleSubmit=(evt)=>{
   evt.preventDefault();

        let {movimiento,factura} = this.state;

        let action=this.state.action;
        
        let save=true;
        let mensaje=true;
        let dato="";
        let self = this;        
        
            // let check = ['referencia','concepto','fecha','rfc_ordenante','nombre_banco_extranjero','rfc_banco_beneficiario','cuenta_beneficiario','tipo_cadena_pago','correo',];
            // if(parseInt(factura.tipo_doc)==2){
             let  check = ['referencia','concepto','fecha'];
            // }

            for(let i in check) {

            let key = check[i];

            let value = movimiento[key];
            dato=key;

            if(value == '' || value == null){
                save = false;
                break;
            }
                
            }
            check = [
            'cliente_id',
           
            ];
            if(save)
            for(let i in check) {

            let key = check[i];

            let value = movimiento[key];
            dato=key;

            if(parseInt( value) == 0) {
                save = false;
                break;
            }
                
            }


   if(save   ){

            api().post(`/save_update_ca_clientes`, movimiento)
            .then(function(response)
            {  
                    swal.fire("Mensaje del Sistema", "La Informacion fue Guardada con éxito!", "success").then((result) => {
                        self.props.history.push('/app/a_cliente');
                        });
            });
        }else{
          
            swal.fire("Oops..", "Por favor Verificar el Campo "+dato );

        }

    }

regresar=(evt)=>
  {
    evt.preventDefault();
    this.props.history.push('/app/a_cliente');
  }
    render() {
       let {movimiento,action,submit}=this.state;
         let editar=true;
         if(action=='update')
          editar=false;

        console.log('movimiento-->',movimiento)
 
        return(
          <div className="content">
          <Row>
          
            <Col md="12">
            <Form onSubmit={this.handleSubmit}>
              <Card className="card-user">
                <CardHeader>
                 <Row>
                      <Col className="update  text-right">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          disabled={submit}
                        >
                          Guardar
                        </Button>
                        <Button
                          className="btn-round"
                          color="warning"
                          onClick={this.regresar}>
                          Cancelar
                        </Button>
                      </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                <Row>
                <div className="col-12 col-md-3">
                      <FormGroup>
                          <Label>Referencia </Label>
                          <Input
                          placeholder=""
                          type="text"
                          name="referencia"
                          value={movimiento.referencia}
                          onChange={ this.handleInputChange}
                          />
                      </FormGroup>
                  </div>
                   <div className="col-12 col-md-3">
                      <FormGroup>
                          <Label>Fecha </Label>
                          <Input
                          placeholder=""
                          type="date"
                          name="fecha"
                          value={movimiento.fecha}
                          onChange={ this.handleInputChange}
                          />
                      </FormGroup>
                  </div>
   
                  <div className="col-12 ">
                      <FormGroup>
                          <Label>Concepto </Label>
                          <Input
                          placeholder=""
                          type="text"
                          name="concepto"
                          value={movimiento.concepto}
                          onChange={ this.handleInputChange}
                          />
                      </FormGroup>
                  </div>

                </Row>
                <Row className='p-3'>
                            <Col sm='9' md="2" className='p-0'>
                              <label > Cliente</label>
                            </Col>
                            <Col sm='2' md="2">
                              <div className="form-group row">
                                <div className="col-12">
                                  <input type="text" className="form-control" 
                                  name="codigo_cliente_id"
                                  required
                                  value={this.state.codigo_cliente_id}
                                  onChange={this.ChangeCode} 
                                 
                                  onKeyPress={event => {
                                      
                                      if (event.key === 'Enter') {
                                       event.preventDefault();
                                        this.handleClose(event,"/getclientes/codigo","cliente_id")
                                      }
                                    }}
                                   />
                                </div>
                              </div>
                            </Col>
                            <Col sm='2' md="1" className='pt-2'>
                                <Button
                                  className="btn btn_catalogos"
                                  color="secondary"
                                  
                                  onClick={ (button) => { this.toggle_modal(24,"Clientes","cliente_id") } }>
                                  +
                                </Button>
                            </Col>
                            <Col md="7" className='p-0 mt-md-1 fondo_label'>
                              <label >{this.state.label_cliente_id} </label>
                            </Col>
                          </Row>
                          <Row className='mt-3'>
                          <Col className='col-12 '>
                          <Row>
                              <div className="col-12 col-md-3">
                                <FormGroup>
                                    <Label>Tipo Cambio * </Label>
                                    <Input
                                    placeholder=""
                                    type="number"
                                    name="tipo_cambio"
                                    value={movimiento.tipo_cambio}
                                    onChange={ this.handleInputChange}
                                    />
                                </FormGroup>
                            </div>
                               <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className=''>Moneda:</label>
                                <Select  placeholder="" type="text"  name="moneda" options={monedas}  value={monedas.filter(option => option.value == movimiento.moneda)} isDisabled={true}  onChange={ (select) => { this.handleSelectChange(select, 'moneda') } } clearable={false} />
                              </div>
                            </div>
                     
                            
                           
                           
                               <div className="col-12 col-md-3">
                                <FormGroup>
                                    <Label>Total </Label>
                                    <Input
                                    placeholder=""
                                    type="number"
                                    step='0.01'
                                    name="total"
                                    value={movimiento.total}
                                    onChange={ this.handleInputChange}
                                  />
                                </FormGroup>
                            </div>
                          </Row>
                          </Col>
                          
                            
                          </Row>
                </CardBody>
                </Card>
                </Form>


                </Col>
                </Row>
                 {this.state.modal&&
            <ModalTablas
                    open={this.state.modal}
                    toggle={this.toggle_modal}                  
                    titulo={this.state.titulo}
                    catalogo={this.state.tabla_modal}
                    retorno={this.retorno}
                    campo={this.state.campo_modal}
                    cliente_id={movimiento.cliente_id}
               
                    />
            }
            </div>
                       
        );
    }

};

function mapStateToProps(state, ownProps) {
    return {
        movimientocaclientes : state.movimientocaclientes,
         notificciones:state.notificciones
    }
};

export default connect(mapStateToProps, actions)(EmbarqueModal)