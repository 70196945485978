/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import Notifications from "views/Notifications.jsx";
import Icons from "views/Icons.jsx";
import Typography from "views/Typography.jsx";
import TableList from "views/Tables.jsx";
import Maps from "views/Map.jsx";
import UserPage from "views/User.jsx";
import UpgradeToPro from "views/Upgrade.jsx";
import Subidas from 'views/Subida';

import lista_usuarios from "views/usuarios/lista_usuarios.js";
import formulario_usuario from "views/usuarios/formulario.js";

import lista_articulos from "views/articulos/lista.js";


export const admin = [
  
  {
    path: "/articulos",
    name: "Articulos",
    icon: "nc-icon nc-app",
    component: lista_articulos,
    layout: "/app",
    exact: true,
  },

  {
    path: "/usuarios",
    name: "Usuarios",
    icon: "nc-icon nc-single-02",
    component: lista_usuarios,
    layout: "/app",
    exact: true,
  },
  {
    path: "/proveedores",
    name: "Proveedores",
    icon: "nc-icon nc-circle-10",
  
    layout: "/app",
    exact: true,
  },
  {
    path: "/almacenes",
    name: "Almacenes",
    icon: "nc-icon nc-app",
  
    layout: "/app",
    exact: true,
  },
];

export const empleado = [
  {
    path: "/Dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-single-copy-04",
    component: Dashboard,
    layout: "/app",
    exact: true,
  },
];
