import { combineReducers } from "redux";

import auth from "./auth.js";
import user from "./user.js";
import articulos from "./articulos.js";
import almacenes from "./almacenes.js";
import compra from "./compra.js";
import clientes from "./clientes.js";
import ca_proveedores from "./ca_proveedores.js";
import facturas from "./facturas.js";
import datosfacturacion from "./datosfacturacion.js";
import folios from "./folios.js";
import movimientocaclientes from "./movimientocaclientes.js";

const reducers = combineReducers({
	auth,
	user,
	articulos,
	almacenes,
	compra,
	clientes,
	ca_proveedores,
	facturas,
	datosfacturacion,
	folios,
	movimientocaclientes
});

export default reducers;