import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Label,FormGroup, Input,InputGroup, InputGroupAddon
} from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink    } from 'reactstrap';
// reactstrap components
import {
  
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
 
  Form,
  
  Row,
  Col,
  CardText
} from "reactstrap";
import classnames from 'classnames';
import _ from "lodash";


import swal from 'sweetalert2';

import * as actions from '../../actions/categorias';

import Select from 'react-select';

import {api, API_URL} from '../../actions/_request';
import TipoCambio from './TipoCambio';




class ModalParametros extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
      activeTab:'1',
        };
  
	}

  toggle_tab=(tab)=> {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
      }


	render()
	{

       
        let {loading}= this.state;
		
		return(
            /*charCode   es para poner texto en el boton para cerrar  pero aqui se uso para eliminarlo*/
			<Modal isOpen={this.props.open} toggle={this.props.toggle} charCode="X" className="default ">				
                <ModalHeader >Parametros</ModalHeader>
                <ModalBody> 
                <div className="content">

                <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => { this.toggle_tab('1'); }}
                        >
                          Tipo de Cambio
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                     <TabPane tabId="1">
                     <TipoCambio/>
                        
                     </TabPane>
                    </TabContent>
                </div>    
                </ModalBody>
               <ModalFooter>
                   
                       
                        <div>                            
                            <Button color="secondary" onClick={this.props.toggle}>
                                Cerrar
                            </Button>
                        </div>
                    
                </ModalFooter>               
            </Modal>
		);
	}
}

function mapStateToProps(state, ownProps) {
    return {
        ticket : state.ticket,
    }
};

export default connect(mapStateToProps, actions)(ModalParametros)