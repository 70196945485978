import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle , Button} from 'reactstrap';

import ReactTable from "react-table";
import "react-table/react-table.css";


import * as actions from '../../actions/user';
import {api, API_URL} from '../../actions/_request';
import _ from "lodash";

const requestData = (pageSize, page, sorted, filtered,tipo,nivel) => {    
    return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        let filteredData ;
       
        var nombre='';
        var id='';
        var take= pageSize;
        var skip=pageSize * page;
        var total=1; 
        
        for (var i = filtered.length - 1; i >= 0; i--) {
          switch(filtered[i].id)
          {
            case "nombre":
              nombre=filtered[i].value;
              console.log('nombre--->',filtered[i].value);
              break;
            case "id":
              id=filtered[i].value;
              console.log('id--->',filtered[i].value);
              break; 
          }
        }
              
              api().get(`/getcategorias?tipo=${tipo}&nivel=${nivel}&nombre=${nombre}&id=${id}&take=${take}&skip=${skip}`)
              .then(function(response)
              {
                if(response.status === 200)
                {
                  filteredData= response.data.datos;
                  total= response.data.total; 
                  // You can also use the sorting in your request, but again, you are responsible for applying it.
                  const sortedData = _.orderBy(
                    filteredData,
                    sorted.map(sort => {
                      return row => {
                        if (row[sort.id] === null || row[sort.id] === undefined) {
                          return -Infinity;
                        }
                        return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                      };
                    }),
                    sorted.map(d => (d.desc ? "desc" : "asc"))
                  );
                  const res = {
                    rows: sortedData,
                    pages: Math.ceil(total / pageSize)
                  };
                  // Here we'll simulate a server response with 500ms of delay.
                  //setTimeout(() => resolve(res), 1000);
                  //console.log(res);
                  resolve(res)
                }
              });   
        
    });
};
class ProductosServicios extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          modal       : false,
          prospectoid : null,
          title       : "",
          data:[],
          pages: null,
          loading: true
        };
        this.toggle 			= this.toggle.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.seleccion = this.seleccion.bind(this);
    }
    seleccion(renglon){
      let retorno ={};
       retorno.id=renglon.id;
       retorno.label=renglon.nombre;
       retorno.codigo=renglon.id;
       this.props.retorno(retorno);

    }
    fetchData(state, instance) {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        requestData(
          state.pageSize,
          state.page,
          state.sorted,
          state.filtered,
           this.props.tipo,
           this.props.nivel,
        ).then(res => {
          // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
          this.setState({
            data: res.rows,
            pages: res.pages,
            loading: false
          });
        });
    }
    toggle(evt, prospectoid = null, title = "Agregar Usuario"){
        let {modal} = this.state;
        this.setState({
          modal       : !modal,
          prospectoid : prospectoid,
          title       : title,
        });
    }
    componentDidMount() {
        // let self = this;
        // api().get('/sat/productos_servicios/1')
        // .then(function(response)
        // {
        // if(response.status === 200)
        // {
        // self.setState({
        // data : response.data
        // });
        // }
        // });
    }
    render() {
      const { data, pages, loading } = this.state;
      return(
        <div className="view">            
          <Card>
            <CardBody>                        
              <ReactTable
                getTrProps={(state, rowInfo, column) => {                                  
                    if(rowInfo === undefined){                                          
                      return{}
                    }else{
                      return{onDoubleClick: (evt)=>this.seleccion(rowInfo.original)}                                   
                    }
                  }
                }
                className = "-striped -highlight"
                columns   = {[
                  {Header: '#', accessor: 'id',maxWidth:100,minWidth:100},
                  {Header: 'Descripcion', accessor: 'nombre' ,minWidth:1000},        
                ]}
                manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                data={data}
                pages={pages} // Display the total number of pages
                loading={loading} // Display the loading overlay when we need it
                onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                filterable
                pageSizeOptions= {[5]}
                defaultPageSize={5} 
              />
            </CardBody>
          </Card>           
        </div>
      );
    }
};

function mapStateToProps(state, ownProps) {
    return {
        user : state.user,
    }
};

export default connect(mapStateToProps, actions)(ProductosServicios)