import React from 'react';

import Logins from './login';
import Descargas from './descargas';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,Badge, Table 
  } from "reactstrap";

export default class LogsIndex extends React.Component{
    constructor(props){
        super(props);
        this.state={
            tipo : 1,
        }
    }

    handleInputChange = (event) =>{
		//let {semana} = this.state;
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
	    const name = target.name;
	    
	    this.setState({
	      [name] : value
	    });
	}

    render(){
        let {tipo} = this.state;
        return(
            <div className="content">
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xs="4">
                                Logs
                            </Col>
                            <Col xs="8">
                            <Input type="select" name="tipo" id="exampleSelect" value={tipo} onChange={this.handleInputChange} >
                                <option value={1}>Ingresos al sistema</option>
                                <option value={2}>Visualización y descarga de archivos</option>
                            </Input>
                            </Col>
                        </Row>
                        <CardBody>
                            {
                                tipo == 1 && 
                                <Logins />
                            }
                            {
                                tipo == 2 && 
                                <Descargas />
                            }
                        </CardBody>
                    </CardHeader>
                </Card>
            </div>
        )
    }
}