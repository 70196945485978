import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Label,FormGroup, Input,InputGroup, InputGroupAddon
} from 'reactstrap';
// reactstrap components
import {
  
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
 
  Form,
  
  Row,
  Col,
  CardText
} from "reactstrap";
import classnames from 'classnames';
import _ from "lodash";
import Spinner from 'react-spinkit';

import swal from 'sweetalert2';

import * as actions from '../../actions/categorias';

import Select from 'react-select';

import {api, API_URL} from '../../actions/_request';

import ReactTable from "react-table";
import "react-table/react-table.css";


const requestData = (pageSize, page, sorted, filtered) => {    
    return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        let filteredData ;
        var label="";
        var siglas_facturas="";
        var n_facturas="";
        var folio="";
        var take= pageSize;
        var skip=pageSize * page;
        var total=1;  
        for (var i = filtered.length - 1; i >= 0; i--) {
          switch(filtered[i].id)
          {
            case "label":
              label=filtered[i].value;
              break;
            case "siglas_facturas":
              siglas_facturas=filtered[i].value;
              break;            
            case "folio":
              folio=filtered[i].value;
              break;
            case "n_facturas":
              n_facturas=filtered[i].value;
              break;             
          }
        }
              
              api().get(`/gettc?take=${take}&skip=${skip}`)
              .then(function(response)
              {
                if(response.status === 200)
                {
                  filteredData= response.data.datos;
                  total= response.data.total; 
                  // You can also use the sorting in your request, but again, you are responsible for applying it.
                  const sortedData = _.orderBy(
                    filteredData,
                    sorted.map(sort => {
                      return row => {
                        if (row[sort.id] === null || row[sort.id] === undefined) {
                          return -Infinity;
                        }
                        return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                      };
                    }),
                    sorted.map(d => (d.desc ? "desc" : "asc"))
                  );
                  const res = {
                    rows: sortedData,
                    pages: Math.ceil(total / pageSize)
                  };
                  // Here we'll simulate a server response with 500ms of delay.
                  //setTimeout(() => resolve(res), 1000);
                  //console.log(res);
                  resolve(res)
                }
              });   
        
    });
};




class TipoCambio extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
      info:{
        tc:0,
      },
      pages: null,
      loading: true,
      submit:false,
        };
    this.table = React.createRef();
	}

  handleSubmit=(evt)=>{
    evt.preventDefault();
    let _self = this;
    let {info} = this.state;
    // let save = true;
    _self.setState({
            submit : true
          })


    swal.queue([{
  title: 'Se Actualizara el Tipo de Cambio',
  confirmButtonText: 'Estoy Seguro',
  icon: 'info',
  text:'Estas Seguro ?' ,
  showLoaderOnConfirm: true,
  cancelButtonText: 'Cancelar',
  showCancelButton: true,
  preConfirm: () => {
    return api().post('/actualizartc', info).then(function(response) {

            if(response.status === 200) {

                swal.insertQueueStep("Bien hecho!", "La Informacion fue Guardada con éxito!", "success");
                _self.setState({
                        submit : false
                      });

                _self.fetchData(_self.table.current.state)

            }
            else{
                
            }

        })
        .catch(function(error) {
            swal.insertQueueStep({
              icon: 'error',
              title: 'Solicitar ayuda a un Administrador'
            })
            _self.setState({
                        submit : false
                      });
        });
  }
}])
  }


   fetchData=(state, instance)=>{
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered,
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
      // console.log('datos--->',res.rows);
       // this.props.get(res.rows);
    });
  }

  handleInputChange=(event)=> {
        let {info} = this.state;
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;

        info[name] = value;
     
        this.setState({
            info : info
        });
    }


    componentDidMount(){
    

    let _self = this;
    let {info} = this.state;
   
      api().get("/gettcdia")
      .then(function(response){
        if(response.status === 200){
          info.tc = response.data;
          _self.setState({
            info : info,
          })
        }

      });
    

  }

	render()
	{
let {data,pages, loading,info,submit}= this.state;
       
		
		return(
                
                <div className="content">
                <Row>
                  <div className='col-12'>
                       <div className="row mt-3 form_cliente">
                          <div className="col-md-4">
                              <FormGroup>
                                  <Label>Tipo de Cambio</Label>
                                      <Input
                                      placeholder="Tipo Cambio"
                                      type="number"
                                      step='0.01'
                                      name="tc"
                                      required
                                      value={info.tc}
                                      onChange={ this.handleInputChange}  />
                              </FormGroup>
                          </div> 
                          <div className='col-2 mt-3 '>
                <Button color="primary" type="submit"  disabled={submit}  onClick={this.handleSubmit} >
                                Actualizar
                </Button>{' '}
                </div>             
                        </div>
                  </div>
                </Row>
                <Row>
                <div className='col-12'>

                 <ReactTable
                              className = "-striped -highlight"
                              columns   = {[
                                {Header: 'TC', accessor: 'tc' },
                                {Header: 'Fecha', accessor: 'created_at'},
                              ]}
                              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                              data      = {data}
                              pages={pages} // Display the total number of pages
                              loading={loading} // Display the loading overlay when we need it
                              onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                              filterable
                              ref={this.table}
                              defaultPageSize={5} 
                            />
               
                    
                </div>
                </Row>


                </div> 
                  
                
		);
	}
}

function mapStateToProps(state, ownProps) {
    return {
        ticket : state.ticket,
    }
};

export default connect(mapStateToProps, actions)(TipoCambio)