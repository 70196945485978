import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Label,FormGroup, Input,InputGroup, InputGroupAddon,Card, CardBlock
} from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink,   CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';

import Spinner from 'react-spinkit';

import swal from 'sweetalert2'
import ReactTable from "react-table";
import "react-table/react-table.css";



import * as actions from '../../actions/folios';

import Select from 'react-select';

import {api} from '../../actions/_request';


const SubmitLoad = () => (
    <div className="p-2">
        <Spinner name="ball-clip-rotate-multiple" color="#b5271f" />
    </div>
);



class FolioModal extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            impuesto: {
                id:0,               
                folio:'',
                nombre:'',
                siglas_facturas:'',
                n_facturas:0,
                siglas_remisiones:'',
                n_remisiones:0,
                siglas_cotizaciones:'',
                n_cotizaciones:0,
                siglas_credito:'',
                n_credito:0,
                forma:'',
                forma2:'',             
            },
            action: 'save',
            activeTab: '1',
            modal:false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);      
        this.handleSubmit = this.handleSubmit.bind(this);      
        this.resetModal= this.resetModal.bind(this);            
        this.toggle_tab= this.toggle_tab.bind(this);
        this.handleSelectChange= this.handleSelectChange.bind(this);
                 
    }
   
    handleSelectChange(select, name) {

        
        const value = select === null ? null : select.value;
        this.setState({
            impuesto: {
                ...this.state.impuesto,
                [name]: value
            }
        });
    }

     toggle_tab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
      }

    componentDidMount()
    {
         
        let prospectoid = this.props.prospectoid;
        let self = this;
              
        if(prospectoid > 0 && prospectoid != self.state.impuesto.id)
        {

            api().get(`folio/${prospectoid}`)
            .then(function(response)
            {
                  
                if(response.status === 200)
                {                
                    self.setState({
                        impuesto: response.data,
                        action: 'update'
                    });

                }

            });
        }
        else if(prospectoid == null && self.state.impuesto.id)
        {            
            self.resetModal();
        }
    }

   

    resetModal(){
        this.setState({
            impuesto: {
                id:0,               
                folio:'',
                nombre:'',
                siglas_facturas:'',
                n_facturas:0,
                 siglas_remisiones:'',
                n_remisiones:0,
                siglas_cotizaciones:'',
                n_cotizaciones:0,
                siglas_credito:'',
                n_credito:0,
                forma:'',
                forma2:'',               
            },
            action: 'save',
             activeTab: '1',
        });
        
    }

    



    handleInputChange(event) {
        let {impuesto} = this.state;
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;

        impuesto[name] = value;
     
        this.setState({
            impuesto : impuesto
        });
    }


   
   

    handleSubmit(evt)
    {
        evt.preventDefault();
        let {impuesto} = this.state;
        let action=this.state.action;
        
        let save=true;
        let dato="";
        let self = this;        
        
            let check = ['nombre','folio'];

            for(let i in check) {

            let key = check[i];

            let value = impuesto[key];
            dato=key;

            if(value == '' || value == null){
                save = false;
                break;
            }
                
            }       
             
           
              
        
       



        if(save){

            api().post(`/folio_validar`, impuesto)
            .then(function(response)
            {
                let validacion=response.data.validacion;
                let encontrado=response.data.encontrado[0];

                if(validacion||(parseInt(encontrado.id)===parseInt(impuesto.id) )){
                        self.props[action](impuesto, (data) => {
                        swal.fire({
                            title: 'Folio',
                            text: 'Folio Guardado correctamente!',
                            type: 'success',
                            // showCancelButton: true,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            confirmButtonText: 'Continue!'
                        }).then((result) => {
                            if (result.value) {
                              
                               
                               self.props.recargar_tabla();
                                self.props.toggle();
                                self.resetModal();
                            }
                        })
                    });

                }else{
                    swal.fire("Oops..", "El Codigo ya existe");
                }
            });

           


         

        }else{
            swal.fire("Oops..", "Por favor Verificar el Campo de " +dato);

        }

        


        

      

    }

    render()
    {

        let {impuesto}=this.state;
  
        
        return(

           <Modal isOpen={this.props.open} toggle={this.props.toggle} className="default modal-lg modal-80 modal_cliente">
                <form >
                <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>
                <ModalBody>
                     <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => { this.toggle_tab('1'); }}
                        >
                          Datos
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                          <div className="row mt-3 form_cliente">
                        <div className="col-md-3">
                            <FormGroup>
                                <Label>Codigo</Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    name="folio"
                                    maxLength={10}
                                    value={impuesto.folio}
                                    onChange={ this.handleInputChange}  />

                            </FormGroup>
                        </div>
                        <div className="col-md-8">
                            <FormGroup>
                                <Label>Nombre</Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    name="nombre"
                                    maxLength={99}
                                    value={impuesto.nombre}
                                    onChange={ this.handleInputChange}
                                    />
                            </FormGroup>
                        </div>                        
                        </div>
                         <div className="row mt-3 form_cliente">       
                        <div className="col-md-3">
                            <FormGroup>
                                <Label>Siglas Facturas</Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    name="siglas_facturas"
                                    maxLength={10}
                                    value={impuesto.siglas_facturas}
                                    onChange={ this.handleInputChange}
                                    />
                            </FormGroup>
                        </div>
                        <div className="col-md-3">
                            <FormGroup>
                                <Label># Facturas</Label>
                                    <Input
                                    placeholder=""
                                    type="number"
                                    name="n_facturas"
                                    value={impuesto.n_facturas}
                                    onChange={ this.handleInputChange}
                                    />
                            </FormGroup>
                        </div>   
                        <div className="col-md-3">
                            <FormGroup>
                                <Label>Siglas Remisiones</Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    name="siglas_remisiones"
                                    maxLength={10}
                                    value={impuesto.siglas_remisiones}
                                    onChange={ this.handleInputChange}
                                    />
                            </FormGroup>
                        </div>
                        <div className="col-md-3">
                            <FormGroup>
                                <Label># Remisiones</Label>
                                    <Input
                                    placeholder=""
                                    type="number"
                                    name="n_remisiones"
                                    value={impuesto.n_remisiones}
                                    onChange={ this.handleInputChange}
                                    />
                            </FormGroup>
                        </div>                  
                    </div>
                   
                    <div className="row mt-3 form_cliente">    
                     <div className="col-md-3">
                            <FormGroup>
                                <Label>Siglas Cotizaciones</Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    name="siglas_cotizaciones"
                                    maxLength={10}
                                    value={impuesto.siglas_cotizaciones}
                                    onChange={ this.handleInputChange}
                                    />
                            </FormGroup>
                        </div>
                        <div className="col-md-3">
                            <FormGroup>
                                <Label># Cotizaciones</Label>
                                    <Input
                                    placeholder=""
                                    type="number"
                                    name="n_cotizaciones"
                                    value={impuesto.n_cotizaciones}
                                    onChange={ this.handleInputChange}
                                    />
                            </FormGroup>
                        </div>   
                        <div className="col-md-3">
                            <FormGroup>
                                <Label>Siglas Credito</Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    name="siglas_credito"
                                    maxLength={10}
                                    value={impuesto.siglas_credito}
                                    onChange={ this.handleInputChange}
                                    />
                            </FormGroup>
                        </div>
                        <div className="col-md-3">
                            <FormGroup>
                                <Label># Credito</Label>
                                    <Input
                                    placeholder=""
                                    type="number"
                                    name="n_credito"
                                    value={impuesto.n_credito}
                                    onChange={ this.handleInputChange}
                                    />
                            </FormGroup>
                        </div>   
                                          
                    </div>
                           
                          </Col>
                        </Row>
                      </TabPane>                      
                    </TabContent>
                    
                </ModalBody>
                <ModalFooter>
                    {
                        this.props.folios.saving ?
                        <SubmitLoad /> : 
                        <div>
                            <Button color="primary" type="submit"  onClick={this.handleSubmit} >
                                Guardar
                            </Button>{' '}
                            <Button color="secondary" onClick={this.props.toggle}>
                                Cancel
                            </Button>
                        </div>
                    }
                </ModalFooter>
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        folios : state.folios,
    }
};

export default connect(mapStateToProps, actions)(FolioModal)