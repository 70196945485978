import { api,api_formdata } from './_request';

export const update = (data,id, callback) => {
	return (dispatch) => {

        
		dispatch({ type: 'CLIENTE_SAVE' });

		api_formdata().post(`datos_facturacion/${id}`, data)
		.then(function(response)
		{
			if(response.status === 200)
			{
				dispatch({
					type: 'SAVE_SUCCESS',
					payload: response.data
				});

				if(typeof callback === 'function') callback();
			}
			else
			{
				dispatch({ type: 'SAVE_FAILURE' });
			}
		})
		.catch(function(error) {
			dispatch({ type: 'SAVE_FAILURE' });
		});

	}
};