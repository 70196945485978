import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Label,FormGroup, Input,InputGroup, InputGroupAddon,Card, CardBlock
} from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink,   CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';

import swal from 'sweetalert2'
import ReactTable from "react-table";
import "react-table/react-table.css";

import * as actions from '../../actions/datosfacturacion';
import Select from 'react-select';
import {api} from '../../actions/_request';
import ModalTablas from '../catalogos/modal_tablas';
import Folios from './folios';



const Actualizar = [
    {label: 'Precio', value: 1},
    {label: 'Costo', value: 2},
   
];
const Metodos = [
    {label: 'UEPS', value: 1},
    {label: 'PEPS', value: 2},
   
];


class ParamtrosModal extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            impuesto: {
                id:0,
                f_precios:'999,999,999.99',
                actualizar:1,
                metodo_costeo:1, 
                decimales_cantidades:2,                         
            },
            action: 'save',
            activeTab: '1',
            modal:false,
            titulo:'',
            tabla_modal:0,
            campo_modal:'',
            codigo_regimen:'',
        };

        this.handleInputChange = this.handleInputChange.bind(this);      
        this.handleSubmit = this.handleSubmit.bind(this);               
        this.toggle_tab= this.toggle_tab.bind(this);
        this.handleSelectChange= this.handleSelectChange.bind(this);
        this.toggle_modal= this.toggle_modal.bind(this); 
        this.retorno= this.retorno.bind(this);
        this.ChangeCode= this.ChangeCode.bind(this);
        this.handleClose= this.handleClose.bind(this);          
    }
    handleClose (event) {
        
         
        let {producto} = this.state;
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
        
        let campo="";
        let tabla=""

        
        switch (name) {
            case "codigo_regimen":                 
                  campo = "regimen_id";
                  tabla="/sat/regimen_fiscal_codigo";
                  break;
            case "clave_banco":                 
                  campo = "banco_id";
                  tabla="/sat/banco_codigo";
                  break;        
        }
        let self =this;
        if(value!=""){
            api().get(`${tabla}/${value}`)
            .then(function(response)
            {                  
                if(response.status === 200)
                { 
                    let vaciar=0;
                     if(Object.keys(response.data).length > 0){
                       
                       
                     }  else{
                         vaciar=1
                         swal.fire("Oops..", "No Existe el Codigo");
                     }
                     self.retorno(response.data,campo,vaciar,value);        
                   
                }

            });
        }else{
            self.retorno([],campo,1,value); 
        }
            

   }

    ChangeCode(event) {
     
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
     
        this.setState({
            [name] : value
        });
    }

    toggle_modal(index=0,catalogo="",campo=""){  

        this.setState({
            modal : !this.state.modal,
            titulo:catalogo,
            tabla_modal:index,
            campo_modal:campo,         
        })
    }

     retorno(objeto,campo,vacio=0,valor=""){
        let codigo="";
        let campo_codigo="";
        let rfc_banco="";
        let nombre_banco="";
        console.log('renglon---->',objeto);
        let {impuesto} = this.state;
        let self =this;
        switch (campo) {
            case "regimen_id":
                  codigo=objeto.codigo;
                  campo_codigo="codigo_regimen"; 
                  impuesto[campo] = objeto.id;
                  break;
            case "banco_id":
                  codigo=objeto.clave;
                  campo_codigo="clave_banco"; 
                  impuesto[campo] = objeto.id;
                  rfc_banco=objeto.rfc;
                  nombre_banco=objeto.nombre;
                  break;       
        }  
       if(vacio==1){
        console.log('vaciar')
        codigo=valor;
        impuesto[campo] =0;
        rfc_banco="";
        nombre_banco="";
       }
        // console.log("state---->",impuesto);
       if(campo=="banco_id"){
        self.setState({
            impuesto : impuesto,           
            [campo_codigo]:codigo,
            modal:false, 
            rfc_banco:rfc_banco,
            nombre_banco:nombre_banco,    
        })

       }else{
        self.setState({
            impuesto : impuesto,           
            [campo_codigo]:codigo,
            modal:false,         
        })
       }
    
        


    }
   
    handleSelectChange(select, name) {

        
        const value = select === null ? null : select.value;
        this.setState({
            impuesto: {
                ...this.state.impuesto,
                [name]: value
            }
        });
    }

     toggle_tab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
      }

    componentDidMount()
    {
            let self = this;
            api().get(`datos_facturacion/1`)
            .then(function(response)
            {
                  
                if(response.status === 200)
                {                
                    self.setState({
                        impuesto: response.data.datos,
                        codigo_regimen:response.data.regimen,
                        clave_banco:response.data.banco.clave,
                        rfc_banco:response.data.banco.rfc,
                        nombre_banco:response.data.banco.nombre,
                        action: 'update'
                    });

                }

            });
        
       
    }

    handleInputChange(event) {
        let {impuesto} = this.state;
        const target    = event.target;
        let value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
        if(target.type === 'checkbox'){
            value=0;
            if(target.checked)
                value=1;
        }
        

        impuesto[name] = value;
     
        this.setState({
            impuesto : impuesto
        });
    }

    objeto_mayusculas(objeto){
         for (var key in objeto) {
                if (objeto.hasOwnProperty(key)) {
                    // var valor=String(objeto[key]).toUpperCase();                 
                    // console.log('valor---->',valor)
                     
                         objeto[key] = String(objeto[key]).toUpperCase() ;
                         if( String(objeto[key]).toUpperCase()==='NULL')
                            objeto[key] ='';
                     
                    // console.log(key + " = " + objeto[key]);
                }
              }
              return objeto;
    }

    handleSubmit(evt)
    {
        evt.preventDefault();
        let {impuesto} = this.state;
        let action=this.state.action;
        
        let save=true;
        let dato="";
        let self = this;
       
        console.log("submit---->");
        // console.log("cambio_key---->",cambio_key);
        // impuesto=this.objeto_mayusculas(impuesto);
        let data = new FormData();
           data.append('registro', JSON.stringify(impuesto));
    

        if(save){

            

               
                        this.props[action](data,1, (data) => {
                        swal.fire({
                            title: ' Los Datos de Facturacion',
                            text: 'Los Datos de Facturacion Fueron Guardados correctamente!',
                            type: 'success',
                            // showCancelButton: true,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            confirmButtonText: 'Continiar!'
                        }).then((result) => {
                            if (result.value) {
                              
                               
                               
                                
                                
                            }
                        })
                    });

                
            

           


         

        }else{
            swal.fire("Oops..", "Por favor Verificar el Campo de " +dato);

        }

        


        

      

    }

    render()
    {

        let {impuesto}=this.state;
  
        
        return(

           <Modal isOpen={this.props.open} toggle={this.props.toggle} className="default modal-lg modal-80 modal_cliente">
                
                <ModalHeader toggle={this.props.toggle}>Datos de la Factura Electronica</ModalHeader>
                <ModalBody>
                     <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => { this.toggle_tab('1'); }}
                        >
                          Datos de la Empresa
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.toggle_tab('2'); }}
                        >
                          Folios
                        </NavLink>
                      </NavItem>

                      
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                     <TabPane tabId="2">
                     <Folios/>
                     </TabPane>
                        <TabPane tabId="1">
                        <form onSubmit={this.handleSubmit} >
                            <Row>
                                <Col sm="12">
                                    <div className="row mt-4 mb-4">
                                        <div className="col-md-12 div_titulo mr-2">
                                         <h1><span>Datos del Emisor</span></h1>
                                            <div className="row ">
                                       
                                                <div className="col-md-8">
                                                    <FormGroup>
                                                        <Label>Nombre *</Label>
                                                        <Input
                                                        required
                                                        placeholder=""
                                                        type="text"
                                                        name="nombre_emisor"
                                                        maxLength={199}
                                                        value={impuesto.nombre_emisor}
                                                        onChange={ this.handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-4">
                                                    <FormGroup>
                                                        <Label>RFC *</Label>
                                                            <Input
                                                            required
                                                            placeholder=""
                                                            type="text"
                                                            name="rfc_emisor"
                                                            maxLength={13}
                                                            value={impuesto.rfc_emisor}
                                                            onChange={ this.handleInputChange}
                                                            />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-12">
                                                    <FormGroup>
                                                        <Label>Calle *</Label>
                                                        <Input
                                                        required
                                                        placeholder=""
                                                        type="text"
                                                        name="calle_emisor"
                                                        maxLength={199}
                                                        value={impuesto.calle_emisor}
                                                        onChange={ this.handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-4">
                                                    <FormGroup>
                                                        <Label>Municipio *</Label>
                                                        <Input
                                                        required
                                                        placeholder=""
                                                        type="text"
                                                        name="municipio_emisor"
                                                        maxLength={99}
                                                        value={impuesto.municipio_emisor}
                                                        onChange={ this.handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-4">
                                                    <FormGroup>
                                                        <Label>Estado *</Label>
                                                        <Input
                                                        required
                                                        placeholder=""
                                                        type="text"
                                                        name="estado_emisor"
                                                        maxLength={99}
                                                        value={impuesto.estado_emisor}
                                                        onChange={ this.handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                 <div className="col-md-4">
                                                    <FormGroup>
                                                        <Label>Pais *</Label>
                                                        <Input
                                                        required
                                                        placeholder=""
                                                        type="text"
                                                        name="pais_emisor"
                                                        maxLength={99}
                                                        value={impuesto.pais_emisor}
                                                        onChange={ this.handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                 <div className="col-md-4">
                                                    <FormGroup>
                                                        <Label>C.P</Label>
                                                        <Input
                                                        required
                                                        placeholder=""
                                                        type="text"
                                                        name="cp_emisor"
                                                        maxLength={10}
                                                        value={impuesto.cp_emisor}
                                                        onChange={ this.handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-4">
                                                    <FormGroup>
                                                        <Label>Numero Ext.</Label>
                                                        <Input
                                                        required
                                                        placeholder=""
                                                        type="text"
                                                        name="numero_ext_emisor"
                                                        maxLength={20}
                                                        value={impuesto.numero_ext_emisor}
                                                        onChange={ this.handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                 <div className="col-md-4">
                                                    <FormGroup>
                                                        <Label>Numero Int.</Label>
                                                        <Input
                                                        required
                                                        placeholder=""
                                                        type="text"
                                                        name="numero_int_emisor"
                                                        maxLength={20}
                                                        value={impuesto.numero_int_emisor}
                                                        onChange={ this.handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-4">
                                                    <FormGroup>
                                                        <Label>Colornia</Label>
                                                        <Input
                                                        required
                                                        placeholder=""
                                                        type="text"
                                                        name="colonia_emisor"
                                                        maxLength={99}
                                                        value={impuesto.colonia_emisor}
                                                        onChange={ this.handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                 <div className="col-md-4">
                                                    <FormGroup>
                                                        <Label>Localidad</Label>
                                                        <Input
                                                        required
                                                        placeholder=""
                                                        type="text"
                                                        name="localidad_emisor"
                                                        maxLength={99}
                                                        value={impuesto.localidad_emisor}
                                                        onChange={ this.handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-4"> 
                                                <div className="form-group">
                                                    <label className="d-block">Regimen Fiscal </label>
                                                    <Input
                                                    required
                                                    placeholder=""
                                                    type="number"
                                                    name="codigo_regimen"
                                                    value={this.state.codigo_regimen}
                                                     onChange={ this.ChangeCode}
                                                     onBlur={this.handleClose}
                                                    className="input_button_sm"
                                                  
                                                    />
                                                    <Button onClick={ (button) => { this.toggle_modal(7,"Regimen Fiscal","regimen_id") } }>...</Button>
                                                </div>
                                            </div>


                                            </div>
                                        </div>
                                    </div>                    
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <div className="row mt-4 mb-4">
                                        <div className="col-md-12 div_titulo mr-2">
                                         <h1><span>Datos Bancarios</span></h1>
                                         <div className="row" >
                                          <div className="col-md-4"> 
                                                <div className="form-group">
                                                    <label className="d-block">Clave Banco </label>
                                                    <Input
                                                    required
                                                    placeholder=""
                                                    type="number"
                                                    name="clave_banco"
                                                    value={this.state.clave_banco}
                                                     onChange={ this.ChangeCode}
                                                     onBlur={this.handleClose}
                                                    className="input_button_sm"
                                                  
                                                    />
                                                    <Button onClick={ (button) => { this.toggle_modal(0,"Bancos","banco_id") } }>...</Button>
                                                </div>
                                            </div>
                                         </div>
                                            <div className="row ">
                                       
                                                <div className="col-md-6">
                                                    <FormGroup>
                                                        <Label>Nombre *</Label>
                                                        <Input
                                                        required
                                                        placeholder=""
                                                        type="text"
                                                        name="nombre_emisor"
                                                        
                                                        value={this.state.nombre_banco}
                                                        onChange={ this.handleInputChange}
                                                        readOnly
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-3">
                                                    <FormGroup>
                                                        <Label>RFC *</Label>
                                                            <Input
                                                            required
                                                            placeholder=""
                                                            type="text"
                                                            name="rfc_emisor"
                                                            
                                                            value={this.state.rfc_banco}
                                                            onChange={ this.handleInputChange}
                                                            readOnly
                                                            />
                                                    </FormGroup>
                                                </div>
                                                 <div className="col-md-3">
                                                    <FormGroup>
                                                        <Label>Cuenta *</Label>
                                                            <Input
                                                            required
                                                            placeholder=""
                                                            type="text"
                                                            name="cuenta"
                                                            value={impuesto.cuenta}
                                                            onChange={ this.handleInputChange}
                                                            />
                                                    </FormGroup>
                                                </div>

                                            </div>
                                        </div>
                                    
                                    </div>                    
                                </Col>
                            </Row>
                            
                            <div className='row text-rigth'>
                            <div className='col-10'></div>
                            <div className='col-2'>
                            <Button color="primary"  type="submit"  >
                                Guardar
                            </Button>
                            </div>
                           
                        </div>
                        </form>
















                        </TabPane>
                       
                         
                                                                         
                    </TabContent>
                </ModalBody>
                <ModalFooter>
     
                        
                        <div>
                            <Button color="secondary" onClick={this.props.toggle}>
                                Cerrar
                            </Button>
                        </div>
                    
                </ModalFooter>
                
                <ModalTablas
                    open={this.state.modal}
                    toggle={this.toggle_modal}                  
                    titulo={this.state.titulo}
                    catalogo={this.state.tabla_modal}
                    retorno={this.retorno}
                    campo={this.state.campo_modal}
                />
            </Modal>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        datosfacturacion : state.datosfacturacion,
    }
};

export default connect(mapStateToProps, actions)(ParamtrosModal)