import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Label,FormGroup, Input,InputGroup, InputGroupAddon
} from 'reactstrap';

import Spinner from 'react-spinkit';

import swal from 'sweetalert2'



import * as actions from '../../actions/user';

import Select from 'react-select';

import {api, API_URL} from '../../actions/_request';
import TablaBancos from "./tabla_bancos";
import TablaAduanas from "./tabla_aduanas";
import TablaUnidades from "./tabla_unidades";
import TablaFormasPago from "./tabla_formaspago";
import TablaMetedosPago from "./tabla_metodospago";
import TablaCodigosPostales from "./tabla_codigospostales";
import TablaMonedas from "./tabla_monedas";
import TablaPaises from "./tabla_paises";
import TablaImpuestos from "./tabla_impuestos";
import TablaRegimenFiscal from "./tabla_regimenfiscal";
import TablaUsosCfdi from "./tabla_usoscfdi";
import TablaTasasCuotas from "./tabla_tasascuotas";
import TablaRelaciones from "./tabla_relaciones";
import TablaProductosServicios from "./tabla_productosservicios";
import TablaProveedores from "./tabla_proveedores";
import TablaCategorias from "./tabla_categoria";
import TablaAlmacenes from "./tabla_almacenes";
import TablaArticulos from "./tabla_articulos";
import TablaFacturas from "./tabla_facturas";
import TablaFacturasCliente from "./tabla_facturas_cliente";
import TablaCompras from "./tabla_compras";
import TablaClientes from "./tabla_clientes";
import TablaArticulosFactura from "./tabla_articulosfactura";
import TablaFolios from "./tabla_folios";
import TablaUsuarios from "./tabla_usuarios";



import TablaSeriesAlmacen from "./tabla_seriesalmacen";
import TablaArticulosAlmacen from "./tabla_articulosalmacen";



import TablaArticulosDocumento from "./tabla_articulosdocumento";
import TablaSeriesDocumento from "./tabla_seriesdocumento";



class ModalTablas extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
		
        };

     
        this.seleccion= this.seleccion.bind(this);
        this.seleccionserie= this.seleccionserie.bind(this);
	}
    seleccion(objeto){
        this.props.retorno(objeto,this.props.campo);
    }
    seleccionserie(objeto){
        this.props.retornoserie(objeto);
    }
	render()
	{
		
		return(
            /*charCode   es para poner texto en el boton para cerrar  pero aqui se uso para eliminarlo*/
			<Modal isOpen={this.props.open} toggle={this.props.toggle} charCode="" className="default modal-lg ">				
                <ModalHeader >{this.props.titulo}</ModalHeader>
                <ModalBody> 
                    {parseInt(this.props.catalogo)===0 && <TablaBancos retorno={this.seleccion}/> }
                    {parseInt(this.props.catalogo)===1 && <TablaMetedosPago/> }
                    {parseInt(this.props.catalogo)===2 && <TablaFormasPago/> }
                    {parseInt(this.props.catalogo)===3 && <TablaCodigosPostales/> }
                    {parseInt(this.props.catalogo)===4 && <TablaMonedas/> }
                    {parseInt(this.props.catalogo)===5 && <TablaPaises retorno={this.seleccion}/> }
                    {parseInt(this.props.catalogo)===6 && <TablaImpuestos/> }
                    {parseInt(this.props.catalogo)===7 && <TablaRegimenFiscal retorno={this.seleccion}/> }
                    {parseInt(this.props.catalogo)===8 && <TablaUsosCfdi/> }
                    {parseInt(this.props.catalogo)===9 && <TablaTasasCuotas/> }
                    {parseInt(this.props.catalogo)===10 && <TablaUnidades retorno={this.seleccion}/> }
                    {parseInt(this.props.catalogo)===11 && <TablaRelaciones/> }
                    {parseInt(this.props.catalogo)===12 && <TablaProductosServicios retorno={this.seleccion}/> }
                    {parseInt(this.props.catalogo)===13 && <TablaAduanas/> }
                    {parseInt(this.props.catalogo)===14 && <TablaProveedores retorno={this.seleccion}/> }
                    {parseInt(this.props.catalogo)===15 && <TablaCategorias nivel={this.props.nivel} tipo={this.props.tipo} retorno={this.seleccion}/> }
                    {parseInt(this.props.catalogo)===16 && <TablaAlmacenes retorno={this.seleccion}/> } 
                    {parseInt(this.props.catalogo)===17 && <TablaArticulos retorno={this.seleccion}/> }   
                    {parseInt(this.props.catalogo)===18 && <TablaFacturas retorno={this.seleccion}/> }  
                    {parseInt(this.props.catalogo)===19 && <TablaCompras filtro={this.props.filtro}  proveedor_id={this.props.proveedor_id} retorno={this.seleccion}/> } 




                    {parseInt(this.props.catalogo)===20 && <TablaSeriesAlmacen origen={this.props.origen} articulo={this.props.articulo} documento={this.props.documento} retorno={this.seleccionserie}/> } 
                    {parseInt(this.props.catalogo)===23 && <TablaArticulosAlmacen  origen={this.props.origen} retorno={this.seleccion}/> }





                    {parseInt(this.props.catalogo)===21 && <TablaArticulosDocumento tipo={this.props.tipo} documento={this.props.documento} retorno={this.seleccion}/> }
                    {parseInt(this.props.catalogo)===22 && <TablaSeriesDocumento tipo={this.props.tipo} articulo={this.props.articulo} documento={this.props.documento} retorno={this.seleccionserie}/> } 
                    {parseInt(this.props.catalogo)===24 && <TablaClientes retorno={this.seleccion} factura={this.props.factura}/> }

                    {parseInt(this.props.catalogo)===25 && <TablaArticulosFactura retorno={this.seleccion} origen={this.props.origen} /> } 
                     {parseInt(this.props.catalogo)===26 && <TablaFolios retorno={this.seleccion}  /> } 
                     {parseInt(this.props.catalogo)===27 && <TablaUsuarios retorno={this.seleccion}  /> } 
                      {parseInt(this.props.catalogo)===28 && <TablaFacturasCliente cliente_id={this.props.cliente_id} retorno={this.seleccion}  /> }     
                </ModalBody>
               <ModalFooter>
                   
                       
                        <div>                            
                            <Button color="secondary" onClick={this.props.toggle}>
                                Cancelar
                            </Button>
                        </div>
                    
                </ModalFooter>               
            </Modal>
		);
	}
}

function mapStateToProps(state, ownProps) {
    return {
        ticket : state.ticket,
    }
};

export default connect(mapStateToProps, actions)(ModalTablas)