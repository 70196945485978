import React from 'react';
import { api,API_URL,api_descarga } from 'actions/_request';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,Badge, Table 
  } from "reactstrap";

import ReactTable from "react-table";
import "react-table/react-table.css";
import _ from "lodash";

import moment from 'moment';
import Async from 'react-select/async';
const cols =[
    {Header: 'Usuario', accessor: 'user',style: { textAlign: 'center'}, filterable : true},
    {Header: 'Fecha', accessor: 'datetime'},
];

const promiseOptions = inputValue =>
  new Promise(resolve => {
        if(inputValue != '') {

            api().get('/getUsers?select=1&name='+inputValue ).then(function(response) {
                console.log('response---->',response.data)
                 resolve(response.data.datos);

            });

        }
        else {
            api().get('/getUsers' ).then(function(response) {
                console.log('response nada---->',response.data)
                 resolve(response.data.datos);

            });
        }
      
    
  });

const requestData = (pageSize, page, sorted, filtered,loged,fecha1,fecha2,usuario) => {

	return new Promise((resolve, reject) => {

		// You can retrieve your data however you want, in this case, we will just use some local data.
		let filteredData;

		var take = pageSize;
		var skip = pageSize * page;
		var total = 0;
        var user = '';

        for (var i = filtered.length - 1; i >= 0; i--) {
          switch(filtered[i].id)
          {     
            case "user":
              user=filtered[i].value;
              break;
            
        }
      }

		api().get(`/get_logs?usuario=${usuario}&fecha2=${fecha2}&fecha1=${fecha1}&loged=${loged}&take=${take}&skip=${skip}&nombre=${user}`)
			.then(function (response) {
					filteredData = response.data.rows;
					total = response.data.total;

					const res = {
						rows: filteredData,
						pages: Math.ceil(total / pageSize),
						total: total,
					};
					// Here we'll simulate a server response with 500ms of delay.
					//setTimeout(() => resolve(res), 1000);
					//console.log(res);
					resolve(res)
				
			}).catch((err)=>{
				console.log(err)
			});

	});
};

export default class LogsIndex extends React.Component{
    constructor(props){
        super(props);
        this.state={
            data : [],
            tipo : true,
            pages : 0,
            loading : true,
            fecha1 : moment().format('Y-MM-DD'),
            fecha2 : moment().format('Y-MM-DD'),
            recargar : false,
            defaultOptions:[{value:0,label:'Todos los Usuarios (Escribe para buscar)'}],
            select_usuario:[{value:0,label:'Todos los Usuarios (Escribe para buscar)'}],
            usuario:0
        }

        this.table = React.createRef();
        
    }

    componentDidMount(){
       
    }

    fetchData = (state, instance) => {
		// Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
		// You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
		// Request the data however you want.  Here, we'll use our mocked service we created earlier

		this.setState({ loading: true });
        let _self = this;
		requestData(
			state.pageSize,
			state.page,
			state.sorted,
			state.filtered,
            this.state.tipo,
            this.state.fecha1,
            this.state.fecha2,
            this.state.usuario,
		).then(res => {
			// Now just get the rows of data to your React Table (and update anything else like total pages or loading)
			_self.setState({
				data: res.rows,
				pages: res.pages,
				total : res.total,
				loading: false,

			});
			
		});
		
	}

    handleSelectChange = (select, name,nameSelect) => {
        // const value = select === null ? null : select.value;
        console.log('select---->',select);
        console.log('name---->',name);
        this.setState({
                [name]: select.value,
                [nameSelect]:select,
            
        }, () => {
           this.fetchData(this.table.current.state)
        });
    }

    handleInputChange = (event) =>{
		//let {semana} = this.state;
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
	    const name = target.name;
	    
	    this.setState({
          [name] : value,
	    }, () => {
           this.fetchData(this.table.current.state)
        });
    }

    handleDownload=(evt)=>{
         let {usuario,fecha1,fecha2,tipo}=this.state;
         let separador=' con ';
         if(tipo){
          separador=' sin '   
        }
        let filename=`Log de Ingreso de la fecha ${fecha1} a ${fecha2} ${separador}ingresos`;

       
               api_descarga().get(`/descargar_logs?usuario=${usuario}&fecha2=${fecha2}&fecha1=${fecha1}&loged=${tipo}`).then(function(response) {
                 const url = window.URL.createObjectURL(new Blob([response.data]));
                       const link = document.createElement('a');
                       link.href = url;
                       link.setAttribute('download', `${filename}.xlsx`); //or any other extension
                       document.body.appendChild(link);
                       link.click();
                

              })
              .catch(function(error) {
               
              });
    }
    
    recargar2=()=>{
		this.setState({recargar : false})
		console.log('entro a recargar 2 =====>')
	}

    render(){
        let {tipo,pages,loading,data,recargar,fecha1,fecha2,select_usuario,defaultOptions} = this.state;

        
            
        return(
            <div className="content">
            <Row>
                    <Col md="3">
                        <label>Fecha Inicio</label>
                        <Input
                            name="fecha1"
                            id="fecha1"
                            type="date"
                            onChange={this.handleInputChange}
                            value={fecha1}
                            placeholder=""
                            
                        />
                    </Col>
                    <Col md="3">
                        <label>Fecha Final</label>
                        <Input
                            name="fecha2"
                            id="fecha2"
                            type="date"
                            onChange={this.handleInputChange}
                            value={fecha2}
                            placeholder=""
                            
                        />
                    </Col>
                    <Col md="6" className="pt-4">    
                        <input style={{'margin':'5px'}} type="checkbox" name="tipo" checked={tipo} onChange={this.handleInputChange}/>
                        <label><b>Sin Ingreso</b></label>{' '}
                    </Col>
                </Row>
                <Row>
                <Col xs="9" className="">    
                       <div className="form-group">
                            <label >Usuario:</label>
                            <Async 
                                placeholder=""
                                name="usuario"
                                loadOptions={promiseOptions}
                                defaultOptions={defaultOptions}
                                value={select_usuario}
                                onChange={ (select) => { this.handleSelectChange(select, 'usuario','select_usuario') } }
                                
                            />
                        </div>
                    </Col>
                    <Col md='3' xs="12" className=" text-right">    
                       
                            <Button onClick={this.handleDownload}><i className="nc-icon nc-cloud-download-93"></i>{' '}Descargar</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                       
                                <ReactTable
                                    getTrProps={(state, rowInfo, column) => {
                                            
                                        if(rowInfo === undefined){      
                                            return{}
                                        }else{
                                                return{
                                                onDoubleClick: (e) => {
                                                    this.props.history.push('/app/uploads/edit'+rowInfo.original.id); 
                                                }
                                            } 
                                        
                                        }
                                    }}
                                    className = "-striped -highlight"
                                    data = {this.state.data}
                                    defaultPageSize={25} 
                                    columns={cols}
                                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                    pages={pages} // Display the total number of pages
                                    loading={loading} // Display the loading overlay when we need it
                                    onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                                    ref={this.table}
                                />
                        
                    </Col>
                </Row>
            </div>
        )
    }
}