import React from 'react';
import { api,API_URL,api_descarga } from 'actions/_request';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,Badge, Table 
  } from "reactstrap";

import ReactTable from "react-table";
import "react-table/react-table.css";
import _ from "lodash";

import Select from 'react-select';
import Async from 'react-select/async';


const requestData = (pageSize, page, sorted, filtered,semana,usuario) => {    
    return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        let filteredData ;
       
        var fecha='';
        var archivo='';
        var descargado=3;
        var fecha_descargado='';
       
        var take= pageSize;
        var skip=pageSize * page;
        var total=1; 
        var patt = new RegExp("(si|s)", "i"); 
        for (var i = filtered.length - 1; i >= 0; i--) {
          switch(filtered[i].id)
          {
                       
            case "fecha":
              fecha=filtered[i].value;
              console.log('fecha--->',filtered[i].value);
              break;
            case "archivo":
              archivo=filtered[i].value;
              console.log('archivo--->',filtered[i].value);
              break;
            case "fecha_descargado":
              fecha_descargado=filtered[i].value;
              console.log('fecha_descargado--->',filtered[i].value);
              break;
              case "descargado_estado":
                patt = new RegExp("(si|s)", "i");
                if(filtered[i].value.search(patt)==0){
                    console.log('descargado_estado---> 1');
                    descargado=1;
                }else{
                    patt = new RegExp("(no|n)", "i");
                    if(filtered[i].value.search(patt)==0){
                        console.log('descargado_estado---> 0');
                        descargado=0;
                    }else{
                        console.log('descargado_estado---> 3');
                        descargado=3;
                    }
                }
              break;
            
        }
      }
              
              api().post(`/reporte_descargas?fecha=${fecha}&usuario=${usuario}&semana=${semana}&fecha_descargado=${fecha_descargado}&descargado=${descargado}&archivo=${archivo}&take=${take}&skip=${skip}`)
              .then(function(response)
              {
                if(response.status === 200)
                {
                  filteredData= response.data.datos;
                  total= response.data.total; 
                  // You can also use the sorting in your request, but again, you are responsible for applying it.
                  const sortedData = _.orderBy(
                    filteredData,
                    sorted.map(sort => {
                      return row => {
                        if (row[sort.id] === null || row[sort.id] === undefined) {
                          return -Infinity;
                        }
                        return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                      };
                    }),
                    sorted.map(d => (d.desc ? "desc" : "asc"))
                  );
                  const res = {
                    rows: sortedData,
                    pages: Math.ceil(total / pageSize)
                  };
                  // Here we'll simulate a server response with 500ms of delay.
                  //setTimeout(() => resolve(res), 1000);
                  //console.log(res);
                  resolve(res)
                }
              });   
        
    });
};
const promiseOptionsUsuarios = inputValue =>
  new Promise(resolve => {
        if(inputValue != '') {

            api().get('/getUsers?select=1&name='+inputValue ).then(function(response) {
                console.log('response---->',response.data)
                 resolve(response.data.datos);

            });

        }
        else {
            api().get('/getUsers' ).then(function(response) {
                console.log('response nada---->',response.data)
                 resolve(response.data.datos);

            });
        }
      
    
  });


const promiseOptions = inputValue =>
  new Promise(resolve => {
        if(inputValue != '') {

            api().get('/select/semanas?semana='+inputValue ).then(function(response) {
                console.log('response---->',response.data)
                 resolve(response.data);

            });

        }
        else {
            api().get('/select/semanas' ).then(function(response) {
                console.log('response nada---->',response.data)
                 resolve(response.data);

            });
        }
      
    
  });




export default class LogsIndex extends React.Component{
    constructor(props){
        super(props);
        this.state={
            semana:0,
            defaultOptions:[{value:0,label:'Todas las Semanas (Escribe para buscar)'}],
            pages: null,
            loading: true,
            data:[],
            recargar : false,
            select:{value:0,label:'Todas las Semanas (Escribe para buscar)'},
            defaultOptionsUsuario:[{value:0,label:'Todos los Usuarios (Escribe para buscar)',rfc:''}],
            select_usuario:[{value:0,label:'Todos los Usuarios (Escribe para buscar)',rfc:''}],
            usuario:''

        };

        this.fetchData = this.fetchData.bind(this);
      
        this.table = React.createRef();
        
    }

    handleDownload=(evt)=>{
         let {usuario,semana,select,select_usuario}=this.state;
         let text_semana='';
         let text_usuario='';
         if(usuario!='')
         text_usuario=' de '+select_usuario.label;
       if(parseInt(semana)>0)
         text_semana=' de la Nomina '+select.label;
        let filename=`Log de Descarga ${text_usuario} ${text_semana} `;

       
               api_descarga().get(`/descargar_logs_archivos?usuario=${usuario}&semana=${semana}`).then(function(response) {
                 const url = window.URL.createObjectURL(new Blob([response.data]));
                       const link = document.createElement('a');
                       link.href = url;
                       link.setAttribute('download', `${filename}.xlsx`); //or any other extension
                       document.body.appendChild(link);
                       link.click();
                

              })
              .catch(function(error) {
               
              });
    }

    fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered,
      this.state.semana,
      this.state.usuario,
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
     
    });
  }

    componentDidMount(){
        let _self = this;
        // api().get('/select/semanas?semana=' ).then(function(response) {
        //         console.log('response---->',response.data)
        //           var datos=response.data;
        //           var select =[];
        //           var semana ='';
        //         if(datos.length>0){
        //           select=datos[0];
        //           semana=datos[0].value;
        //         }
                  



        //          _self.setState({
        //         defaultOptions: response.data,
        //         select:select,
        //         semana:semana,
        //         });

        //     });
    }

    handleSelectChange = (select, name,nameSelect) => {
        // const value = select === null ? null : select.value;
        console.log('select---->',select.rfc);
        console.log('name---->',name);
        this.setState({
                [name]:nameSelect=='select'? select.value:select.rfc,
                [nameSelect]:select,
            
        }, () => {
           this.fetchData(this.table.current.state)
        });
    }

    
    

    render(){
        let {semana,defaultOptions,pages, loading,recargar,select_usuario,defaultOptionsUsuario} = this.state;

       
        
            
        return(
            <div className="content">
                <Row>
                    
                    <Col md="4" className="">    
                       <div className="form-group">
                            <label >Semana:</label>
                            <Async 
                                placeholder=""
                                name="semana"
                                loadOptions={promiseOptions}
                                defaultOptions={defaultOptions}
                                value={this.state.select}
                                onChange={ (select) => { this.handleSelectChange(select, 'semana','select') } }
                                
                            />
                        </div>
                    </Col>
                    <Col md="6" className=""></Col>
                     <Col xs="8" className="">    
                       <div className="form-group">
                            <label >Usuario:</label>
                            <Async 
                                placeholder=""
                                name="usuario"
                                loadOptions={promiseOptionsUsuarios}
                                defaultOptions={defaultOptionsUsuario}
                                value={select_usuario}
                                onChange={ (select) => { this.handleSelectChange(select, 'usuario','select_usuario') } }
                                
                            />
                        </div>
                    </Col> 
                    <Col md='4' xs="12" className=" text-right">    
                       
                            <Button onClick={this.handleDownload}><i className="nc-icon nc-cloud-download-93"></i>{' '}Descargar</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    
                        
                         <ReactTable
                                  className = "-striped -highlight"
                                  columns   = {[
                                    {Header: 'Semana', accessor: 'fecha',style: { textAlign: 'center'}},
                                    {Header: 'Archivo', accessor: 'archivo',style: { textAlign: 'center'}},
                                    {Header: 'Descargado', accessor: 'descargado_estado',style: { textAlign: 'center'}},
                                    {Header: 'Fecha', accessor: 'fecha_descargado',style: { textAlign: 'center'},
                                      Filter: ({ filter, onChange }) =>
                                      <input
                                        type="date" 
                                        name="fecha_descargado"
                                        onChange={event => onChange(event.target.value)}
                                       value={filter ? filter.value : ""}
                                    />},
                                  ]}
                                  manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                  data      = {this.state.data}
                                  pages={pages} // Display the total number of pages
                                  loading={loading} // Display the loading overlay when we need it
                                  onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                                  filterable
                                  ref={this.table}
                                  defaultPageSize={25} 
                                />
                    
                   
                    </Col>
                </Row>
            </div>
        )
    }
}