import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/compra';
import {api } from '../../actions/_request';
import swal from 'sweetalert2';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent, TabPane, Nav, NavItem, NavLink, CardText
} from "reactstrap";
import classnames from 'classnames';
import ModalTablas from '../../components/catalogos/modal_tablas';
import { debounce } from 'lodash';
import ReactTable from "react-table";
import "react-table/react-table.css";
import ModalProductos from './ModalProductos';
import Select from 'react-select';

const filterCaseInsensitive = (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (row[id] !== null){
                return (
                    row[id] !== undefined ?
                        String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase())
                        :
                        true
                );
            }
        };
const monedas = [
    {label: 'MXN', value: 'MXN'},
    {label: 'USD', value:'USD'},
];

class Proveedor extends Component {
  constructor(props){
    super(props);
    this.state = {
      articulo:{
        'fecha':'',
        'factura':'',
        'tc':'',
        'concepto':'',
        'almacen_id':0,
        'proveedor_id':0,
        'pedimento':'',
        'pedimento_fecha':'',
        'pedimento_tc':'',
        'pedimento_clave':'',
        'moneda':'MXN'
      },
      productos:[],
      action : 'save',
      submit:false,
      activeTab: '2',
      modal:false,
      tabla_modal:0,
      titulo:'',
      campo_modal:'',
      modal_productos:false,
    }
     this.handleSubmit = this.handleSubmit.bind(this);
     this.handeInputChange = this.handeInputChange.bind(this);
     this.toggle_modal= this.toggle_modal.bind(this); 
     this.retorno= this.retorno.bind(this);   
     this.ChangeCode= this.ChangeCode.bind(this);  
     this.handleClose= this.handleClose.bind(this);
     this.toggle_tab= this.toggle_tab.bind(this);
     this.toogle_productos= this.toogle_productos.bind(this);
     this.retornoProducto = this.retornoProducto.bind(this);
      this.delete = this.delete.bind(this);
  }
  delete(evt,index){
      let {productos} = this.state;   
      
      let submit=true;
        /*peticion para agregar*/
        let self = this;

         swal.queue([{
        title: 'Favor de Confirmar ',
        text: " Se eliminar El producto: ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
                productos.splice(index,1);
        self.setState({
          
           productos:productos,
          });
        }
      }])


         
    }
    handleSelectChange=(select, name)=> {
        const value = select === null ? null : select.value;
        console.log('select--->',select)
        this.setState({
            articulo: {
                ...this.state.articulo,
                [name]: value
            }
        });
    }

  retornoProducto(obj){
       let {productos} = this.state;
       var indiceproducto= this.state.indiceproducto;
       console.log('retorno producto---->',obj);
       // console.log('indice--->',indiceproducto)
       if(indiceproducto==null){
        productos.push(obj);
       }else{
        productos[indiceproducto]=obj;
       }

       this.setState({
            modal_productos : !this.state.modal_productos,       
            indiceproducto : null,  
            productos:productos,
        })


  }
  toogle_productos(evt,indice=null){  
        this.setState({
            modal_productos : !this.state.modal_productos,       
            indiceproducto : indice,  
        })
  }
  toggle_tab(tab) {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab
        });
      }
  }
  toggle_modal(index=0,catalogo="",campo="",tipo=0,nivel=0){  
        this.setState({
            modal : !this.state.modal,
            titulo:catalogo,
            tabla_modal:index,
            campo_modal:campo,
            tipo_modal:tipo,
            nivel_modal:nivel,         
        })
  } 
  retorno(objeto,campo,vacio=0,valor=""){
        let label="";
        let codigo="";
        let id="";
        let {articulo} = this.state;
        let self =this;
        if(vacio==0){
          label=objeto.label;
          codigo=objeto.codigo;
          id=objeto.id;
        }
        console.log('objeto--->',objeto)

        if(campo=='proveedor_id'){
          self.setState({
            articulo: {
                ...this.state.articulo,
                [campo]: id,
                moneda:objeto.moneda,
            },         
            ['label_'+campo]:label,
            ['codigo_'+campo]:codigo,
            modal:false,         
        })
        }else{
          self.setState({
            articulo: {
                ...this.state.articulo,
                [campo]: id
            },         
            ['label_'+campo]:label,
            ['codigo_'+campo]:codigo,
            modal:false,         
        })
        }
  }
  ChangeCode(event) {
      const target    = event.target;
      const value     = target.type === 'checkbox' ? target.checked : target.value;
      const name      = target.name;
      // console.log('name---->',name);
      // console.log('value---->',value);
      this.setState({
          [name] : value
      });
  }
  handleClose (event,tabla,campo) {
      const target    = event.target;
      const value     = target.type === 'checkbox' ? target.checked : target.value;
      const name      = target.name;
      let self =this;
      if(value!=""){
          api().get(`${tabla}/${value}`)
          .then(function(response)
          {                  
              if(response.status === 200)
              { 
                  let vaciar=0;
                   if(Object.keys(response.data).length > 0){
                     
                     
                   }  else{
                       vaciar=1
                       swal.fire("Oops..", "No Existe el Codigo");
                   }
                   self.retorno(response.data,campo,vaciar,value);        
                 
              }

          });
      }else{
          self.retorno([],campo,1,value); 
      }
  }
  componentDidMount(){
    let _self = this;
    let {articulo} = this.state;
    let {params} = this.props.match;
  let tc=0;
    api().get(`/gettcdia`)
        .then(function(response)
        {
          tc =response.data;
          if(params.id) {
      api().get("/getcompra/"+params.id)
      .then(function(response){
        if(response.status === 200){
          articulo = response.data.compra;
          
          _self.setState({
            articulo : articulo,
            productos : response.data.productos,
            codigo_proveedor_id:response.data.compra.codigo_proveedor_id,
            label_proveedor_id:response.data.compra.label_proveedor_id,
            // codigo_almacen_id:response.data.compra.codigo_almacen_id,
            // label_almacen_id:response.data.compra.label_almacen_id,


            action : 'update'
          })
        }

      });
    }else{
      _self.setState({
          
                 articulo: {
                ..._self.state.articulo,
                tc: tc,
               
            },
        
                
            });
    }

           
        })

    




  }
  handeInputChange(event){
    let {articulo} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    articulo[name] = value;
    // console.log('articulo handeInputChange---->',articulo)
    this.setState({
        articulo : articulo
    });
  }

  handleSubmit(evt){
    evt.preventDefault();
    let _self = this;
    let {articulo,action,productos} = this.state;
    let save = true;
    // _self.setState({
    //         submit : true
    //       })

    var pedimento = new RegExp("[0-9]{2}  [0-9]{2}  [0-9]{4}  [0-9]{7}$");
      articulo.pedimento=articulo.pedimento.trim();





    articulo.productos=productos;
    console.log('compra------->',articulo)
    let bandera_pedimento=false;
      if(productos.length == 0)
        {
         
        swal.fire("Alerta!", 'Favor de Agregar Productos', "warning");
        save=false;
        _self.setState({
            submit : false
          })
        return;
      }
    /*validamos si existen productos que necesiten pedimento*/
    for (var i = productos.length - 1; i >= 0; i--) {
      if( parseInt(productos[i].pedimento)==1){
        bandera_pedimento=true;
        break;
      }
    }
    if(bandera_pedimento){
      if(articulo.pedimento.trim().length==0||articulo.pedimento_fecha.trim().length==0||articulo.pedimento_tc.trim().length==0){
        swal.fire("Alerta!", 'Se encontraron articulos que necesitan la informacion del pedimento favor de agregarla', "warning");
        save=false;
        _self.setState({
            submit : false
          })
        return;
      }else{
        if(!pedimento.test(articulo.pedimento)){
             swal.fire("Alerta!", 'El Formato de la clave del pedimento no es correcta   el Formato correcto es YY[esp][esp]AA[esp][esp]PPPP[esp][esp]NCCCCCC ', "warning");
        }
      }
    }
//console.log('validacion de proveedor')
if(parseInt(articulo.proveedor_id)==0){
     
        swal.fire("Alerta!", 'Asigne un proveedor', "warning");
        save=false;
        _self.setState({
            submit : false
          })
        return;
     
    }
    // if(parseInt(articulo.almacen_id)==0){
     
    //     swal.fire("Alerta!", 'Asigne un almacen', "warning");
    //     save=false;
    //     _self.setState({
    //         submit : false
    //       })
    //     return;
     
    // }

//console.log('validacion de proveedor')






    this.props.save(articulo, (response) => {
                if(response.status){
                  if(response.data.estado){
                    //console.log('actualizar')
                    swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success").then((result) => {
                        _self.props.history.push('/app/compras');
                        });
                    
                     _self.setState({
                      articulo : response.data.data,
                      action : 'update',
                      submit : false
                    })
                    

                  }else{
                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    _self.setState({
                        submit : false
                      });
                  }
                }else{
                  //console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
                }
              });
  }
  regresar=(evt)=>
  {
    evt.preventDefault();
    this.props.history.push('/app/compras');
  }

  render() {
     let {submit,articulo} = this.state;
     // //console.log('Proveedor---->',articulo)



    return (
        <div className="content ">
          <Row>
            <Col md="12">
            <Form onSubmit={this.handleSubmit}>
              <Card className="card-user">
                <CardHeader>
                 <Row>
                      <Col className="update  text-right">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          disabled={submit}
                        >
                          Guardar
                        </Button>
                        <Button
                          className="btn-round"
                          color="warning"
                          onClick={this.regresar}>
                          Cancelar
                        </Button>
                      </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                <Row>
                <Col md="12">
                  <div className='row'>
                  <div className="form-group col-12 col-md-4 col-lg-3">
                    <label  className=" col-form-label">fecha : </label>
                      <input type="date" className="form-control" 
                        name="fecha"
                        required
                        value={articulo.fecha}
                        onChange={this.handeInputChange}
                       />
                  </div>
                  <div className="form-group col-12 col-md-3">
                    <label  className=" col-form-label">Factura : </label>
                      <input type="text" className="form-control" 
                        name="factura"
                        required
                        value={articulo.factura}
                        onChange={this.handeInputChange}
                       />
                  </div>
                  <div className="col-md-3"> 
                    <div className="form-group">
                      <label className='col-form-label'>Moneda:</label>
                      <Select  placeholder="" type="text"  name="moneda" options={monedas}  value={monedas.filter(option => option.value == articulo.moneda)} isDisabled={true}  onChange={ (select) => { this.handleSelectChange(select, 'moneda') } } clearable={false} />
                    </div>
                  </div>
                  <div className="form-group col-12 col-md-3">
                    <label  className=" col-form-label">Tipo Cambio : </label>
                      <input type="number" className="form-control" 
                        name="tc"
                        required
                        value={articulo.tc}
                        onChange={this.handeInputChange}
                       />
                  </div>
                  </div>
                   <div className='row'>
                  <div className="form-group col-12 ">
                    <label  className=" col-form-label">Concepto : </label>
                      <input type="text" className="form-control" 
                        name="concepto"
                        required
                        value={articulo.concepto}
                        onChange={this.handeInputChange}
                       />
                  </div>
                  </div>
                  <Row className='pl-3 pr-3'>
                    <Nav className='col-12' tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => { this.toggle_tab('1'); }}
                        >
                          Datos Generales
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.toggle_tab('2'); }}
                        >
                          Productos
                        </NavLink>
                      </NavItem>

                    </Nav>
                    </Row>
                    <Row className='pl-3 pr-3'>
                     <TabContent className='col-12' activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        <Row className=''>
                          <Col md="8">
                          
                          <Row>
                            <Col md="12" className='col_titulo'>
                              <label > General</label>
                            </Col>


                            <Col sm='9' md="2" className='p-0'>
                              <label > Proveedor</label>
                            </Col>
                            <Col sm='2' md="2">
                              <div className="form-group row">
                                <div className="col-12">
                                  <input type="text" className="form-control" 
                                  name="codigo_proveedor_id"
                                  required
                                  value={this.state.codigo_proveedor_id}
                                  onChange={this.ChangeCode} 
                                  onKeyPress={event => {
                                      
                                      if (event.key === 'Enter') {
                                       event.preventDefault();
                                        this.handleClose(event,"/getproveedor/codigo","proveedor_id")
                                      }
                                    }}
                                   />
                                </div>
                              </div>
                            </Col>
                            <Col sm='2' md="1" className='pt-2'>
                                <Button
                                  className="btn btn_catalogos"
                                  color="secondary"
                                  onClick={ (button) => { this.toggle_modal(14,"Proveedores","proveedor_id") } }>
                                  +
                                </Button>
                            </Col>
                            <Col md="7" className='p-0 mt-md-1 fondo_label'>
                              <label >{this.state.label_proveedor_id} </label>
                            </Col>


                           
                            
                            

                          </Row>
                          </Col>
                          <Col md="4" >
                            <Row className='pl-2'> 
                               <Col md="12" className='col_titulo'>
                                <label > Pedimento</label>
                               </Col>
                               <div className="form-group col-12 ">
                                <label  className=" col-form-label">Numero : </label>
                                  <input type="text" className="form-control" 
                                    name="pedimento_clave"
                                    
                                    value={articulo.pedimento_clave}
                                    onChange={this.handeInputChange}
                                   />
                              </div>
                               <div className="form-group col-12 ">
                                <label  className=" col-form-label">Clave Sat : </label>
                                  <input type="text" className="form-control" 
                                  placeholder='xx__xx__xxxx__xxxxxxx'
                                    name="pedimento"
                                    
                                    value={articulo.pedimento}
                                    onChange={this.handeInputChange}
                                   />
                              </div>
                              <div className="form-group col-12 ">
                                <label  className=" col-form-label">Fecha : </label>
                                  <input type="date" className="form-control" 
                                    name="pedimento_fecha"
                                    
                                    value={articulo.pedimento_fecha}
                                    onChange={this.handeInputChange}
                                   />
                              </div>
                              <div className="form-group col-12 ">
                                <label  className=" col-form-label">Tipo Cambio : </label>
                                  <input type="number" className="form-control" 
                                    name="pedimento_tc"
                                    
                                    value={articulo.pedimento_tc}
                                    onChange={this.handeInputChange}
                                   />
                              </div>
                            </Row>
                          </Col>

                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                      <Row>
                      <Col className="update my-3  text-right">
                        <Button
                          className="btn-round"
                          color="primary"
                          onClick={(evt)=>{evt.preventDefault(); this.toogle_productos(evt,null); }}
                        >
                          Agregar
                        </Button>
                       
                      </Col>
                    </Row>
                       <ReactTable
                             getTrProps={(state, rowInfo, column) => {
                                 
                                  if(rowInfo === undefined){
                                         
                                          return{

                                          }
                                        }else{
                                           return{
                                            onDoubleClick: (e) => {
                                                  e.preventDefault(); this.toogle_productos(e,rowInfo.index);
                                              
                                             
                                              
                                            }
                                        } 
                                  
                                    }
                              }}
                              className = "-striped -highlight"
                              columns   = {[
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                    maxWidth:300,minWidth:300,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-center">
                                                 <Button
                                                 color="danger" 
                                                 className=' btn-sm mr-1  '
                                                 
                                                 onClick={(evt)=>this.delete(evt, row.index)}>
                                                    Eliminar
                                                </Button> 

                                                <Button
                                                 color="success" 
                                                 className="btn-sm" onClick={(e)=>{e.preventDefault(); this.toogle_productos(e,row.index); }}>
                                                    Editar
                                                </Button>
                                               
                                            </div>
                                        )
                                    }
                                },
                                {Header: 'Descripción', accessor: 'descripcion'},
                                {Header: 'Cantidad', accessor: 'cantidad',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Costo', accessor: 'costo',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Subtotal', accessor: 'subtotal',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                              ]}
                              data      = {this.state.productos} 
                              filterable
                              defaultPageSize={5}
                              defaultFilterMethod={filterCaseInsensitive}
                            />
                      </TabPane>
                     
                      </TabContent>

                  </Row>
                  
                </Col>
                </Row>
                </CardBody>
              </Card>
              </Form>
            </Col>
          </Row>

          {
            this.state.modal&&
            <ModalTablas
                  open={this.state.modal}
                  toggle={this.toggle_modal}                  
                  titulo={this.state.titulo}
                  catalogo={this.state.tabla_modal}
                  retorno={this.retorno}
                  campo={this.state.campo_modal}
                  nivel={this.state.nivel_modal}
                  tipo={this.state.tipo_modal}
                  
                  
              />
          }

          { this.state.modal_productos&&
                <ModalProductos
                    open={this.state.modal_productos}
                    toggle={this.toogle_productos}  
                    renglon={this.state.indiceproducto!=null?this.state.productos[this.state.indiceproducto]:[]} 
                    retorno={this.retornoProducto}
                    indice={this.state.indiceproducto}
                />
            }
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        auth : state.auth,
        compra:state.compra
    }
};

export default connect(mapStateToProps, actions)(Proveedor)

