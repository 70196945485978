import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/proveedores';
import {api } from '../../actions/_request';
import swal from 'sweetalert2';
import ReactTable from "react-table";
import "react-table/react-table.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent, TabPane, Nav, NavItem, NavLink, CardText,Label
} from "reactstrap";
import classnames from 'classnames';
import ModalTablas from '../../components/catalogos/modal_tablas';
import Select from 'react-select';
const monedas = [
    {label: 'MXN', value: 'MXN'},
    {label: 'USD', value:'USD'},
];
const filterCaseInsensitive = (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (row[id] !== null){
                return (
                    row[id] !== undefined ?
                        String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase())
                        :
                        true
                );
            }
        };
  
class Proveedor extends Component {
  constructor(props){
    super(props);
    this.state = {
      articulo:{
        nombre:'',
        moneda:'MXN',
        dias_credito:0
      },
      action : 'save',
      submit:false,
      activeTab: '1',
      cc:[],
      cc_text:'',

      contactos:[],
       contacto_text:'',
       telefono_text:'',
       index_contacto:null,

       cuentas:[],
       cuenta_text:'',
       index_cuenta:null,
       clave_banco:'',
       nombre_banco:'',
       rfc_banco:'',
      
    }
     this.handleSubmit = this.handleSubmit.bind(this);
    
     this.handeInputChange = this.handeInputChange.bind(this);
     
  }

      handleSelectChange=(select, name)=> {
        const value = select === null ? null : select.value;
        console.log('select--->',select)
        this.setState({
            articulo: {
                ...this.state.articulo,
                [name]: value
            }
        });
    }
  retorno=(objeto,campo,vacio=0,valor="")=>{
        let codigo="";
        let campo_codigo="";
        console.log('renglon---->',objeto);
        let {articulo} = this.state;
        let self =this;
         let rfc_banco="";
        let nombre_banco="";
        switch (campo) {
            case "banco_id":
                  codigo=objeto.clave;
                  campo_codigo="clave_banco"; 
                  articulo[campo] = objeto.id;
                  rfc_banco=objeto.rfc;
                  nombre_banco=objeto.nombre;
                  break;           
        }  
       if(vacio==1){
        console.log('vaciar')
        codigo=valor;
        articulo[campo] =0;
          rfc_banco="";
         nombre_banco="";
       }
       if(campo=="banco_id"){
        self.setState({
            articulo : articulo,           
            [campo_codigo]:codigo,
            modal:false, 
            rfc_banco:rfc_banco,
            nombre_banco:nombre_banco,    
        })

       }else{
        self.setState({
            articulo : articulo,           
            [campo_codigo]:codigo,
            modal:false,         
        })
       }
       
    



    }  

  handleClose =(event)=> {
         
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
        
        let campo="";
        let tabla=""

        
        switch (name) {
   
            case "clave_banco":                 
                  campo = "banco_id";
                  tabla="/sat/banco_codigo";
                  break; 
 
        }
        let self =this;
        if(value!=""){
            api().get(`${tabla}/${value}`)
            .then(function(response)
            {                  
                if(response.status === 200)
                { 
                    let vaciar=0;
                     if(Object.keys(response.data).length > 0){
                       
                       
                     }  else{
                         vaciar=1
                         swal.fire("Oops..", "No Existe el Codigo");
                     }
                     self.retorno(response.data,campo,vaciar,value);        
                   
                }

            });
        }else{
            self.retorno([],campo,1,value); 
        }
            

   }
  ChangeCode=(event)=> {
     
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
     
        this.setState({
            [name] : value
        });
    }
  toggle_modal=(index=0,catalogo="",campo="")=>{  

        this.setState({
            modal : !this.state.modal,
            titulo:catalogo,
            tabla_modal:index,
            campo_modal:campo,         
        })
    }

  push=()=>{
    let {cc,cc_text} = this.state;
    if(cc_text.length>0){
        // 
        let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        //Se muestra un texto a modo de ejemplo, luego va a ser un icono
        if (emailRegex.test(cc_text)) {
          cc.unshift({
            correo : cc_text,
          });
          this.setState({
            cc : cc,
            cc_text:'',
          });
          swal.fire("Exito", "Se Guardo Con Exito ");
        } else {
         swal.fire("Oops..", "Correo Invalido ");
       }
     }
   }
  delete=(evt,index)=>{
    let {cc} = this.state;  

    cc.splice(index,1);
    this.setState({
      cc : cc,
    });
  }

handleInputChangecorreo=(event) =>{
        let {impuesto} = this.state;
        const target    = event.target;
        let value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;

        this.setState({
           [name] : value,
        });
    }

  toggle_tab=(tab)=> {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  editar_contacto=(evt,index)=>{
     this.setState({
      contacto_text:this.state.contactos[index].contacto,
      telefono_text:this.state.contactos[index].telefono,
      index_contacto : index,
    });


  }
  
  push_contacto=()=>{
       let {contactos,contacto_text,telefono_text,index_contacto} = this.state;
       var patt = new RegExp("[a-zA-Z0-9]+", "i");
       console.log('state--->',this.state)
        //Se muestra un texto a modo de ejemplo, luego va a ser un icono
        if (patt.test(contacto_text)&&patt.test(telefono_text)) {
            if(index_contacto!=null){
              contactos[index_contacto]={
                contacto : contacto_text,
                telefono : telefono_text,
              };
            }else{
              contactos.unshift({
                contacto : contacto_text,
                telefono : telefono_text,
              });
            }
          this.setState({
            contactos : contactos,
            contacto_text: '',
            telefono_text:'',
            index_contacto:null,
          });

           swal.fire("Exito", "Se Guardo Con Exito ");
        } else {
           swal.fire("Oops..", "Informacion de Contacto invalida ");
        }
     
   }


  delete_contacto=(evt,index)=>{
    let {contactos} = this.state;  

    contactos.splice(index,1);
    this.setState({
      contactos : contactos,
    });
  }



  editar_cuenta=(evt,index)=>{
     this.setState({
      clave_banco:this.state.cuentas[index].clave_banco,
      rfc_banco:this.state.cuentas[index].rfc_banco,
      nombre_banco:this.state.cuentas[index].nombre_banco,
      cuenta_text:this.state.cuentas[index].cuenta,
      articulo: {
                ...this.state.articulo,
                banco_id: this.state.cuentas[index].banco_id,
            },
      index_cuenta : index,
    });


  }

  delete_cuenta=(evt,index)=>{
    let {cuentas} = this.state;  
    cuentas.splice(index,1);
    this.setState({
      cuentas : cuentas,
    });
  }

  push_cuentas = ()=>{
       let {cuentas,cuenta_text,clave_banco,rfc_banco,nombre_banco,index_cuenta,articulo} = this.state;
       var patt = new RegExp("[a-zA-Z0-9]+", "i");
       console.log('state--->',this.state)
        // Se muestra un texto a modo de ejemplo, luego va a ser un icono
        if (patt.test(clave_banco)&&patt.test(cuenta_text)&&parseInt(articulo.banco_id)>0) {
            if(index_cuenta!=null){
              cuentas[index_cuenta]={
                clave_banco : clave_banco,
                nombre_banco : nombre_banco,
                rfc_banco : rfc_banco,
                cuenta : cuenta_text,
                banco_id : articulo.banco_id,
              };
            }else{
              cuentas.unshift({
                 clave_banco : clave_banco,
                nombre_banco : nombre_banco,
                rfc_banco : rfc_banco,
                cuenta : cuenta_text,
                banco_id : articulo.banco_id,
              });
            }
          this.setState({
            cuentas : cuentas,
            clave_banco: '',
            nombre_banco:'',
            rfc_banco:'',
            cuenta_text:'',
            articulo: {
                ...this.state.articulo,
                banco_id: 0,
            },
            index_cuenta:null,
          });

           swal.fire("Exito", "Se Guardo Con Exito ");
        } else {
           swal.fire("Oops..", "Informacion de la Cuenta invalida ");
        }
     
   }

  componentDidMount(){
    let _self = this;
    let {articulo} = this.state;
    let {params} = this.props.match;
    if(params.id) {
      api().get("/getproveedor/"+params.id)
      .then(function(response){
        if(response.status === 200){
          let data = response.data;
          _self.setState({
           
             articulo : data.proveedor,
            cc:data.cc,
            cuentas:data.bancos,
            contactos:data.contactos,
            action : 'update'
          })
        }

      });
    }

  }


  handeInputChange(event){
    let {articulo} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    articulo[name] = value;
    this.setState({
        articulo : articulo
    });
  }

  handleSubmit(evt){
    evt.preventDefault();
    let _self = this;
    let {articulo,action,cc,cuentas,contactos} = this.state;
    let save = true;
    _self.setState({
            submit : true
          })

    articulo.cc=cc;
    articulo.cuentas=cuentas;
    articulo.contactos=contactos;

    this.props.save(articulo, (response) => {
                if(response.status){
                  if(response.data.estado){
                    // console.log('actualizar')
                    swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success");
                    
                     _self.setState({
                      articulo : response.data.proveedor,
                      action : 'update',
                      submit : false
                    })
                    

                  }else{
                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    _self.setState({
                        submit : false
                      });
                  }
                }else{
                  console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
                }
              });



  }

  regresar=(evt)=>
  {
    evt.preventDefault();
    this.props.history.push('/app/proveedores');
  }

  render() {
     let {submit,articulo} = this.state;
     console.log('Proveedor---->',articulo)
    return (
      
        <div className="content">
          <Row>
            <Col md="12">
            <Form onSubmit={this.handleSubmit}>
              <Card className="card-user">
                <CardHeader>
                 <Row>
                      <Col className="update  text-right">
                        <Button className="btn-round" color="primary" type="submit" disabled={submit} >
                          Guardar
                        </Button>
                        <Button className="btn-round" color="warning" onClick={this.regresar}>
                          Cancelar
                        </Button>
                      </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                <Row>
                <Col md="12">
                  <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => { this.toggle_tab('1'); }}
                        >
                          Datos Generales
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.toggle_tab('2'); }}
                        >
                          Correos
                        </NavLink>
                      </NavItem>
                       <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => { this.toggle_tab('3'); }}
                        >
                          Contactos
                        </NavLink>
                      </NavItem>
                       <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '4' })}
                          onClick={() => { this.toggle_tab('4'); }}
                        >
                          Cuentas
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>

                     <TabPane tabId="4">
                     <div className="row mt-3" >
                        <div className="col-md-4"> 
                              <div className="form-group">
                                  <label className="d-block">Clave Banco </label>
                                  <Input
                                  placeholder=""
                                  type="number"
                                  name="clave_banco"
                                  value={this.state.clave_banco}
                                   onChange={ this.ChangeCode}
                                   onBlur={this.handleClose}
                                   onKeyPress={event => {
                    
                                      if (event.key === 'Enter') {
                                       event.preventDefault();
                                        this.handleClose(event)
                                      }
                                    }}
                                  className="input_button_sm"
                                
                                  />
                                  <Button onClick={ (button) => { this.toggle_modal(0,"Bancos","banco_id") } }>...</Button>
                              </div>
                          </div>
                          <div className="col-md-3">
                                                    <FormGroup>
                                                        <Label>Cuenta *</Label>
                                                            <Input
                                                            placeholder=""
                                                            type="text"
                                                            name="cuenta_text"
                                                            value={this.state.cuenta_text}
                                                            onChange={ this.handleInputChangecorreo}
                                                            />
                                                    </FormGroup>
                                                </div>

                                                <div className="col-md-2">
                                <div className="form-group">
                                    <Button color="primary" className="" 
                                    style={{marginTop: '2rem'}} onClick={this.push_cuentas}>
                                        Agregar
                                    </Button>
                                </div>
                            </div>
                       </div>
                        <Row>
                          
                           <div className="col-md-6">
                                                    <FormGroup>
                                                        <Label>Nombre Banco *</Label>
                                                        <Input
                                                        placeholder=""
                                                        type="text"
                                                        name="nombre_emisor"
                                                        
                                                        value={this.state.nombre_banco}
                                                        onChange={ this.handleInputChange}
                                                        readOnly
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-3">
                                                    <FormGroup>
                                                        <Label>RFC Banco *</Label>
                                                            <Input
                                                            placeholder=""
                                                            type="text"
                                                            name="rfc_emisor"
                                                            
                                                            value={this.state.rfc_banco}
                                                            onChange={ this.handleInputChange}
                                                            readOnly
                                                            />
                                                    </FormGroup>
                                                </div>
                                                 
                            

                   
                        </Row>

                        <Row>
                          
                        <div className="col-md-12"> 
                            <ReactTable
                              data      = {this.state.cuentas}
                              className = "-striped -highlight"
                              columns   = {[
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                    maxWidth:160,minWidth:160,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-right">
                                                <Button
                                                 color="danger" 
                                                 onClick={(evt)=>this.delete_cuenta(evt, row.index)}>
                                                    X
                                                </Button> 
                                                <Button
                                                 color="primary" 
                                                 onClick={(evt)=>this.editar_cuenta(evt, row.index)}>
                                                    editar
                                                </Button> 
                                                
                                            </div>
                                        )
                                    }
                                },
                                {Header: 'Banco', accessor: 'nombre_banco'},
                                {Header: 'Cuenta', accessor: 'cuenta'},
                                                     
                              
                              ]}
                              filterable 
                             pageSizeOptions= {[5]}  defaultPageSize={5} 
                               defaultFilterMethod={filterCaseInsensitive}
                            />        
                        </div>
                        </Row>
                      </TabPane>

                      <TabPane tabId="2">
                        <Row>
                          
                           <div className="col-md-4"> 
                                <div className="form-group">
                                    <Label>Correo </Label>
                                    <Input
                                    className='texto_normal'
                                    placeholder=""
                                    type="text"
                                    name="cc_text"
                                    maxLength={70}
                                    value={this.state.cc_text}
                                    onChange={ this.handleInputChangecorreo}
                                    />
                                </div>
                              </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <Button color="primary" className="" 
                                    style={{marginTop: '2rem'}} onClick={this.push}>
                                        Agregar
                                    </Button>
                                </div>
                            </div>

                   
                        </Row>

                        <Row>
                          
                        <div className="col-md-12"> 
                            <ReactTable
                              data      = {this.state.cc}
                              className = "-striped -highlight"
                              columns   = {[
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                    maxWidth:100,minWidth:100,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-right">
                                                <Button
                                                 color="danger" 
                                                 onClick={(evt)=>this.delete(evt, row.index)}>
                                                    X
                                                </Button> 
                                            </div>
                                        )
                                    }
                                },
                                {Header: 'Correo', accessor: 'correo'},
                                                     
                              
                              ]}
                              filterable 
                             pageSizeOptions= {[5]}  defaultPageSize={5} 
                               defaultFilterMethod={filterCaseInsensitive}
                            />        
                        </div>
                        </Row>
                      </TabPane>

                      <TabPane tabId="3">
                        <Row>
                          
                           <div className="col-md-4"> 
                                <div className="form-group">
                                    <Label>Contacto </Label>
                                    <Input
                                    className='texto_normal'
                                    placeholder=""
                                    type="text"
                                    name="contacto_text"
                                    maxLength={70}
                                    value={this.state.contacto_text}
                                    onChange={ this.handleInputChangecorreo}
                                    />
                                </div>
                              </div>
                               <div className="col-md-4"> 
                                <div className="form-group">
                                    <Label>Telefono </Label>
                                    <Input
                                    className='texto_normal'
                                    placeholder=""
                                    type="text"
                                    name="telefono_text"
                                    maxLength={70}
                                    value={this.state.telefono_text}
                                    onChange={ this.handleInputChangecorreo}
                                    />
                                </div>
                              </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <Button color="primary" className="" 
                                    style={{marginTop: '2rem'}} onClick={this.push_contacto}>
                                        Agregar
                                    </Button>
                                </div>
                            </div>

                   
                        </Row>

                        <Row>
                          
                        <div className="col-md-12"> 
                            <ReactTable
                              data      = {this.state.contactos}
                              className = "-striped -highlight"
                              columns   = {[
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                    maxWidth:150,minWidth:150,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-right">
                                                <Button
                                                 color="danger" 
                                                 onClick={(evt)=>this.delete_contacto(evt, row.index)}>
                                                    X
                                                </Button> 
                                                <Button
                                                 color="primary" 
                                                 onClick={(evt)=>this.editar_contacto(evt, row.index)}>
                                                    editar
                                                </Button> 
                                            </div>
                                        )
                                    }
                                },
                                {Header: 'Contacto', accessor: 'contacto'},
                                {Header: 'Telefono', accessor: 'telefono'},
                                                     
                              
                              ]}
                              filterable 
                             pageSizeOptions= {[5]}  defaultPageSize={5} 
                               defaultFilterMethod={filterCaseInsensitive}
                            />        
                        </div>
                        </Row>
                      </TabPane>
                      <TabPane tabId="1">
                <Row>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label>Codigo *</Label>
                        <Input  placeholder="" type="text" name="codigo" value={articulo.codigo} onChange={ this.handeInputChange} required  />
                      </FormGroup>
                    </div>
                    <div className="col-md-3"> 
                    <div className="form-group">
                      <label className=''>Moneda:</label>
                      <Select  placeholder="" type="text"  name="moneda" options={monedas}  value={monedas.filter(option => option.value == articulo.moneda)}  onChange={ (select) => { this.handleSelectChange(select, 'moneda') } } clearable={false} />
                    </div>
                  </div>
                  <div className="col-md-3">
                      <FormGroup>
                        <Label>Dias Credito *</Label>
                        <Input placeholder="" type="number" step='1' name="dias_credito" value={articulo.dias_credito}  onChange={ this.handeInputChange}  required />
                      </FormGroup>
                    </div>
                    
                    <div className="col-md-8">
                      <FormGroup>
                        <Label>Nombre *</Label>
                        <Input placeholder="" type="text"  name="nombre" value={articulo.nombre}  onChange={ this.handeInputChange} required />
                      </FormGroup>
                    </div>
                    <div className="col-md-12">
                      <FormGroup>
                        <Label>Direccion *</Label>
                        <Input placeholder="" type="text" name="direccion" value={articulo.direccion}  onChange={ this.handeInputChange} required />
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label>Ciudad *</Label>
                        <Input placeholder="" type="text" name="ciudad"  value={articulo.ciudad}  onChange={ this.handeInputChange}  required />
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label>Codigo Postal *</Label>
                        <Input placeholder="" type="text"  name="cp"  value={articulo.cp}  onChange={ this.handeInputChange}  required />
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label>RFC *</Label>
                        <Input placeholder="" type="text" name="rfc" value={articulo.rfc}  onChange={ this.handeInputChange}  required />
                      </FormGroup>
                    </div>
                  </Row>
                  </TabPane>
                      </TabContent>








                    </Col>
                    </Row>















                </CardBody>
              </Card>
              </Form>
            </Col>
          </Row>
          {this.state.modal&&
            <ModalTablas
                    open={this.state.modal}
                    toggle={this.toggle_modal}                  
                    titulo={this.state.titulo}
                    catalogo={this.state.tabla_modal}
                    retorno={this.retorno}
                    campo={this.state.campo_modal}
               
                    />
            }
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        auth : state.auth,
        articulos:state.articulos
    }
};

export default connect(mapStateToProps, actions)(Proveedor)

