/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch ,Redirect} from "react-router-dom";

import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import routes from "routes.js";

import * as navigation from '../nav.js';
import * as actions from '../actions/auth';
import PassModal from '../components/changePassword/modalPass';
import { connect } from 'react-redux';
var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      passModal   : false,

    };
    this.mainPanel = React.createRef();
    this.toggleChangePass = this.toggleChangePass.bind(this);
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  signOut(e) {
    e.preventDefault();
    let self =this;
    this.props.logout((data) => {
                         self.props.history.push('/login');
                    });
   

  }

  toggleChangePass = (e) =>{
    console.log('modal_pass');
        this.setState({
            passModal: !this.state.passModal,
        });
    }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  render() {
    let {authenticated, user} = this.props.auth;
    let menu=[];
    switch(parseInt(user.rol,10))
    {
      case 1: menu = navigation.admin; break;
      case 2: menu = navigation.empleado; break;

      default:
    }
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={menu}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <DemoNavbar  modal_pass={this.toggleChangePass} onLogout={e=>this.signOut(e)} user={user} {...this.props} />
         <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.layout + route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                          <route.component {...props} />
                        )} />
                    ) : (null);
                  })}
                  <Redirect from="/" to="/app/Dashboard" />
                </Switch>
          <Footer fluid />
        </div>
        <PassModal
          open2={this.state.passModal}
          toggleE={this.toggleChangePass}
        />
        
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        auth : state.auth
    }
}

export default connect(mapStateToProps, actions)(Dashboard);
/*

 <Switch>
            {routes.map((prop, key) => {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            })}
          </Switch>*/